import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  referral: null,
  referralId: null,
};

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
    setReferralLink: (state, action) => {
      state.referral = action.payload;
    },
    setReferral: (state, action) => {
      state.referralId = action.payload;
    },
  },
});

export const { setReferralLink, setReferral } = referralSlice.actions;

export default referralSlice.reducer;
