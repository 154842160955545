import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollPositionSaver = () => {
  const location = useLocation();

  useEffect(() => {
    const saveScrollPosition = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    };

    saveScrollPosition(); // Save scroll position on route change
  }, [location]);

  return null; // This component does not render anything
};

export default ScrollPositionSaver;
