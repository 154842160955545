import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import tokensByChainId from "../utils/tokensByChainId";
import debounce from "lodash.debounce";
const ethers = require("ethers");

const useBalanceData = ({
  isConnected,
  walletProvider,
  chainId,
  selectedCurrency,
  address,
  fetchPriceForCurrency,
}) => {
  const [balance, setBalance] = useState(null);
  const [balanceInDollar, setBalanceInDollar] = useState(null);
  const balanceRefreshTrigger = useSelector(
    (state) => state.balance.balanceRefreshTrigger
  );

  const fetchBalanceAndPrice = useCallback(async () => {
    if (!isConnected) {
      setBalance(null);
      setBalanceInDollar(null);
      return;
    }

    try {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const userAddress = await signer.getAddress();

      let newBalance;
      let formattedBalance;

      if (["ETH", "BNB", "POL", "AVAX"].includes(selectedCurrency)) {
        newBalance = await ethersProvider.getBalance(userAddress);
        let rawFormatted = ethers.utils.formatEther(newBalance);
        formattedBalance = truncateNumber(rawFormatted, 4);
      } else {
        const tokenInfo = tokensByChainId[chainId]?.[selectedCurrency];
        if (tokenInfo && tokenInfo.address) {
          const contract = new ethers.Contract(
            tokenInfo.address,
            ["function balanceOf(address owner) view returns (uint256)"],
            signer
          );
          newBalance = await contract.balanceOf(userAddress);
          formattedBalance = truncateNumber(
            newBalance / 10 ** tokenInfo.decimals,
            4
          );
        } else {
          return;
        }
      }

      setBalance(formattedBalance);

      const currencyPrice = await fetchPriceForCurrency(
        selectedCurrency,
        chainId
      );

      if (formattedBalance !== null && currencyPrice !== null) {
        const newBalanceInDollar = currencyPrice * formattedBalance;
        setBalanceInDollar(newBalanceInDollar);
      } else {
        setBalanceInDollar(0);
      }
    } catch (error) {
      console.error("Error fetching balance and price:", error);
    }
  }, [
    isConnected,
    walletProvider,
    chainId,
    selectedCurrency,
    address,
    fetchPriceForCurrency,
  ]);

  const debouncedFetchBalanceAndPrice = useCallback(
    debounce(fetchBalanceAndPrice, 200, { leading: true, trailing: false }),
    [fetchBalanceAndPrice]
  );

  useEffect(() => {
    debouncedFetchBalanceAndPrice();
  }, [debouncedFetchBalanceAndPrice, balanceRefreshTrigger]);

  useEffect(() => {
    if (isConnected && walletProvider) {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);

      const handleNewBlock = () => {
        debouncedFetchBalanceAndPrice();
      };

      ethersProvider.on("block", handleNewBlock);

      return () => {
        ethersProvider.off("block", handleNewBlock);
      };
    }
  }, [isConnected, walletProvider, debouncedFetchBalanceAndPrice]);

  function truncateNumber(number, decimalPlaces) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.floor(number * factor) / factor;
  }

  return { balance, balanceInDollar, fetchBalanceAndPrice };
};

export default useBalanceData;
