// src/reducers/dollarCardSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dollarAmount: 0,
};

const dollarCardSlice = createSlice({
  name: "dollarCard",
  initialState,
  reducers: {
    setDollarAmount: (state, action) => {
      state.dollarAmount = action.payload;
    },
  },
});

export const { setDollarAmount } = dollarCardSlice.actions;

export default dollarCardSlice.reducer;
