import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

const useScrollRestoration = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (navigationType === "reload") {
      const scrollPosition = sessionStorage.getItem("scrollPosition");
      if (scrollPosition) {
        setTimeout(() => {
          window.scrollTo(0, parseInt(scrollPosition, 10));
        }, 100);
      }
    } else if (navigationType === "PUSH" || navigationType === "REPLACE") {
      window.scrollTo(0, 0);
    } else if (navigationType === "POP") {
      const scrollPosition = sessionStorage.getItem("scrollPosition");
      if (scrollPosition) {
        setTimeout(() => {
          window.scrollTo(0, parseInt(scrollPosition, 10));
        }, 100);
      }
    }
  }, [location, navigationType]);
};

export default useScrollRestoration;
