import UsdC from "../assets/img/usdc-symbol.webp";
import Tether from "../assets/img/tether-icon.webp";
import EthSymbol from "../assets/img/eth-symbol.webp";
import BnbSymbol from "../assets/img/bnb-symbol.webp";
import MaticSymbol from "../assets/img/matic-symbol.webp";
import AvaxSymbol from "../assets/img/avax-symbol.webp";
import DaiSymbol from "../assets/img/dai-symbol.webp";
import ArbSymbol from "../assets/img/arbitrum-symbol.svg";
import OpSymbol from "../assets/img/optimism-symbol.svg";
import ZKsyncSymbol from "../assets/img/zkSync-updated-logo.svg";
import { USDIcon } from "../components/Home/Banner/Icons/USDIcon";
import { GBPIcon } from "../components/Home/Banner/Icons/GBPIcon";
import { EURIcon } from "../components/Home/Banner/Icons/EURIcon";
import blockchainPriceFeeds from "./priceFeedsByChainId";

export const currencyIcons = {
  ETH: EthSymbol,
  BNB: BnbSymbol,
  POL: MaticSymbol,
  AVAX: AvaxSymbol,
  ARB: ArbSymbol,
  OP: OpSymbol,
  ZK: ZKsyncSymbol,
  USDT: Tether,
  USDC: UsdC,
  USDCE: UsdC,
  DAI: DaiSymbol,
  USD: USDIcon,
  GBP: GBPIcon,
  EUR: EURIcon,
};

export const specialCurrencyLabels = {
  USDCE: "USDC.e",
};

export const getCurrencyData = (chainId, isConnected, lastConnectedChainId) => {
  const effectiveChainId = isConnected ? chainId : lastConnectedChainId;
  return blockchainPriceFeeds[effectiveChainId] || {};
};

export const getDefaultCurrencies = () => ["ETH", "USDT", "USDC", "DAI"];
