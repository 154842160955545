import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalReferralRewardsInDollars: null,
  pendingReferralRewardsInDollars: null,
};

const totalReferralRewardsSlice = createSlice({
  name: "totalReferralRewards",
  initialState,
  reducers: {
    setTotalReferralRewards(state, action) {
      state.totalReferralRewardsInDollars = action.payload;
    },
    setPendingReferralRewardsInDollars(state, action) {
      // Add the new action
      state.pendingReferralRewardsInDollars = action.payload;
    },
  },
});

export const { setTotalReferralRewards, setPendingReferralRewardsInDollars } =
  totalReferralRewardsSlice.actions; // Export the new action
export default totalReferralRewardsSlice.reducer;
