import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isVisible: true,
};

const topBannerSlice = createSlice({
  name: "topBanner",
  initialState,
  reducers: {
    setTopBannerVisibility(state, action) {
      state.isVisible = action.payload;
    },
  },
});

export const { setTopBannerVisibility } = topBannerSlice.actions;
export default topBannerSlice.reducer;
