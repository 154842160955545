// UserListShimmerPlaceholder.js
import React from "react";
import "./shimmer.css"; // Ensure you have the shimmer styles imported

const UserListShimmerPlaceholder = () => {
  return (
    <ul className="shimmer-list">
      {Array.from({ length: 15 }).map((_, index) => (
        <li
          key={index}
          className="flex flex-wrap gap-[24px] items-center p-[20px] border-solid border-b border-[#57534E]"
        >
          <span className="shimmer shimmer-text flex-[0_0_34px]" />

          <div className="flex items-center gap-[8px]">
            <div className="shimmer shimmer-icon w-[32px] h-[32px] rounded-full" />
            <span className="shimmer shimmer-text" style={{ width: "80px" }} />
          </div>

          <span
            className="shimmer shimmer-text ml-auto"
            style={{ width: "60px" }}
          />
        </li>
      ))}
    </ul>
  );
};

export default UserListShimmerPlaceholder;
