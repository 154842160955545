const formatReferralLink = (url) => {
  try {
    const { hostname, searchParams } = new URL(url);
    const refId = searchParams.get("ref");
    if (refId) {
      const shortRefId = refId.slice(-8); // Take last 8 characters for the short version
      return `${hostname}/...${shortRefId}`;
    }
    return url;
  } catch (error) {
    console.error("Invalid URL:", url, error);
    return url;
  }
};

export default formatReferralLink;
