import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalNovaPoints: null,
};

const totalNovaPointsSlice = createSlice({
  name: "totalNovaPoints",
  initialState,
  reducers: {
    setTotalNovaPoints(state, action) {
      state.totalNovaPoints = action.payload;
    },
  },
});

export const { setTotalNovaPoints } = totalNovaPointsSlice.actions;
export default totalNovaPointsSlice.reducer;
