import { createSlice } from "@reduxjs/toolkit";

const userRankingByRewardsSlice = createSlice({
  name: "userRankingByRewards",
  initialState: {
    ranking: null,
  },
  reducers: {
    setUserRankingByRewards: (state, action) => {
      state.ranking = action.payload;
    },
  },
});

export const { setUserRankingByRewards } = userRankingByRewardsSlice.actions;

export default userRankingByRewardsSlice.reducer;
