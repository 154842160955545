export const CoinsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_214_18535)">
      <path
        d="M50 29.67V28H48V27H46V26H45V25H42V24H40V23H36V22H16V23H12V24H10V25H7V26H6V27H3V28H2V29.67H0V31.58H51.75V29.67H50Z"
        fill="white"
      />
      <path
        d="M0 33.5V35.42H1.92V37.33H49.83V35.42H51.75V33.5V31.58H0V33.5Z"
        fill="white"
      />
      <path
        d="M5.75 39.25H7.67V41.17H14V42H18V43H33V42H36V41.17H46V39.25H47.92V37.33H5.75V39.25Z"
        fill="white"
      />
      <path
        d="M59.4201 1.92H56.0001V1H51.0001V0H35.0001V1H31.0001V1.92H26.8301V3.83H59.4201V1.92Z"
        fill="white"
      />
      <path
        d="M67.08 7.66996V5.74996H63.25V3.82996H23V5.74996H19.17V7.66996H17.25V9.57996H69V7.66996H67.08Z"
        fill="white"
      />
      <path
        d="M17.25 11.5V13.42H19.17V15.33H22V17H25V19H29V20H33V21H56V20H57V19H63V17H65V15.33H67.08V13.42H69V11.5V9.57996H17.25V11.5Z"
        fill="white"
      />
      <path
        d="M66 21V22H65H64H63V23H62H61V24H60H58V25H56H54V34H56H58V33H60H61V32H62H63V31H64H65V30H66V29H67V28H68V21H67H66Z"
        fill="white"
      />
      <path d="M69 20H68V27H69V20Z" fill="white" />
      <path
        d="M68 34V35H67H66V36H65H64H63V37H62H61V38H60H58V39H56H54V48H56H58V47H60H61V46H62H63V45H64H65V44H66V43H67V42H68V41H69V34H68Z"
        fill="white"
      />
      <path
        d="M51 41H50V42H49H48V43H47H46H45V44H44H43V45H42H40V46H38H36V47H32H24H16V46H12V45H11H10H9H8V44H7H6V43H5H4H3V42H2V41H1H0V48H1V49H2V50H3V51H4V52H5H6V53H7H8V54H9H10H11V55H12H16V56H24H32H36V55H38H40V54H42H43V53H44H45V52H46H47V51H48V50H49V49H50V48H51V47H52V41H51Z"
        fill="white"
      />
      <path
        d="M51 54H50V55H49H48V56H47H46H45V57H44H43V58H42H40V59H38H36V60H32H24H16V59H12V58H11H10H9H8V57H7H6V56H5H4H3V55H2V54H1H0V61H1V62H2V63H3V64H4V65H5H6V66H7H8V67H9H10H11V68H12H16V69H24H32H36V68H38H40V67H42H43V66H44H45V65H46H47V64H48V63H49V62H50V61H51V60H52V54H51Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_214_18535">
        <rect width="69" height="69" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
