// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App";
import "./components/i18";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import ReactGA from "react-ga4";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      {/* Wrap your app with PersistGate */}
      <PersistGate loading={null} persistor={persistor}>
        <React.Suspense fallback="loading...">
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </React.Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
