// src/utils/networkMapping.js
import EthereumLogo from "../assets/img/eth-icon.svg";
import OptimismLogo from "../assets/img/optimism-icon.svg";
import BscLogo from "../assets/img/bsc-icon.svg";
import PolygonLogo from "../assets/img/plg-icon.svg";
import ZksyncLogo from "../assets/img/zksync.svg";
import BaseLogo from "../assets/img/base-icon.svg";
import ArbitrumLogo from "../assets/img/arbitrum-icon.svg";
import AvalancheLogo from "../assets/img/avax-icon.svg";

const networkMapping = {
  1: {
    name: "Ethereum",
    logo: EthereumLogo,
    explorer: "https://etherscan.io/tx/",
  },
  10: {
    name: "Optimism",
    logo: OptimismLogo,
    explorer: "https://optimistic.etherscan.io/tx/",
  },
  56: {
    name: "BSC",
    logo: BscLogo,
    explorer: "https://bscscan.com/tx/",
  },
  137: {
    name: "Polygon",
    logo: PolygonLogo,
    explorer: "https://polygonscan.com/tx/",
  },
  324: {
    name: "ZKsync",
    logo: ZksyncLogo,
    explorer: "https://explorer.zksync.io/tx/",
  },
  8453: {
    name: "Base",
    logo: BaseLogo,
    explorer: "https://basescan.org/tx/",
  },
  42161: {
    name: "Arbitrum",
    logo: ArbitrumLogo,
    explorer: "https://arbiscan.io/tx/",
  },
  43114: {
    name: "Avalanche",
    logo: AvalancheLogo,
    explorer: "https://snowtrace.io/tx/",
  },
};

export default networkMapping;
