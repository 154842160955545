import React, { useState, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import "./Roadmap.css";

import roadmapCheck from "../../../assets/img/roadmap-check-white.svg";
import roadmapArrow from "../../../assets/img/roadmap-arrow.svg";
import { useTranslation } from "react-i18next";
const Roadmap = () => {
  const { t } = useTranslation();
  // const ref = useRef();
  // const { events } = useDraggable(ref, { safeDisplacement: 11 });
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  const scrollContainerRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDown(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
    setScrolling(true);
    // Change cursor to grabbing
    scrollContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseLeave = () => {
    if (isDown) {
      setIsDown(false);
      setScrolling(false);
      // Reset cursor
      scrollContainerRef.current.style.cursor = "grab";
    }
  };

  const handleMouseUp = () => {
    if (isDown) {
      setIsDown(false);
      setScrolling(false);
      // Reset cursor
      scrollContainerRef.current.style.cursor = "grab";
    }
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust this value for faster or slower scrolling
    const newScrollLeft = scrollLeft - walk;
    scrollContainerRef.current.scrollLeft = newScrollLeft;
  };

  const handleTransitionEnd = () => {
    setScrolling(false);
  };
  return (
    <div className="AppContainer_AppContainer__zMOpX RoadmapSection_RoadmapSection__jVegv max-width  scrollX hide-scrollbar  swx">
      <div className="SectionHeaderV2_SectionHeaderV2__UELE4 RoadmapSection_sectionHeader__9fYuR">
        <div className="SectionHeaderV2_sectionHeaderSubtitle__eUmZ_">
          {t("homePage.roadmap.title")}
        </div>
      </div>
      <div
        className="RoadmapSection_roadmapItemGrid__UEPtU  grab"
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="RoadmapSection_roadmapItem__x63r0 grab">
          <div className="RoadmapSection_itemHeader__1_gyO">
            <div className="RoadmapSection_year__kPhPy">
              {" "}
              {t("homePage.roadmap.items.0.year")}
            </div>
            <div className="RoadmapSection_timeline__0qVRw" />
          </div>
          <div className="RoadmapSection_itemContent__mAhFQ">
            <div className="RoadmapSection_itemTitle__wxXIz">
              {t("homePage.roadmap.items.0.quarter")}
            </div>
            <div className="RoadmapSection_itemList__jZGEe">
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img
                    src={roadmapCheck}
                    className="roadmap-check"
                    alt="Roadmap milestone 2024 Q2"
                  />
                </div>
                {t("homePage.roadmap.items.0.milestones.milestone1")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img
                    src={roadmapCheck}
                    className="roadmap-check"
                    alt="Roadmap milestone 2024 Q2"
                  />
                </div>
                {t("homePage.roadmap.items.0.milestones.milestone2")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img
                    src={roadmapCheck}
                    className="roadmap-check"
                    alt="Roadmap milestone 2024 Q2"
                  />
                </div>
                {t("homePage.roadmap.items.0.milestones.milestone3")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img
                    src={roadmapCheck}
                    className="roadmap-check"
                    alt="Roadmap milestone 2024 Q2"
                  />
                </div>
                {t("homePage.roadmap.items.0.milestones.milestone4")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img
                    src={roadmapCheck}
                    className="roadmap-check"
                    alt="Roadmap milestone 2024 Q2"
                  />
                </div>

                {t("homePage.roadmap.items.0.milestones.milestone5")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img
                    src={roadmapCheck}
                    className="roadmap-check"
                    alt="Roadmap milestone 2024 Q2"
                  />
                </div>

                {t("homePage.roadmap.items.0.milestones.milestone6")}
              </div>
            </div>
          </div>
        </div>
        <div className="RoadmapSection_roadmapItem__x63r0 ">
          <div className="RoadmapSection_itemHeader__1_gyO">
            <div className="RoadmapSection_year__kPhPy">
              {t("homePage.roadmap.items.1.year")}
            </div>
            <div className="RoadmapSection_timeline__0qVRw" />
          </div>
          <div className="RoadmapSection_itemContent__mAhFQ">
            <div className="RoadmapSection_itemTitle__wxXIz">
              {t("homePage.roadmap.items.1.quarter")}
            </div>
            <div className="RoadmapSection_itemList__jZGEe">
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img
                    src={roadmapCheck}
                    className="roadmap-check"
                    alt="Roadmap milestone 2024 Q3-Q4"
                  />
                </div>
                {t("homePage.roadmap.items.1.milestones.milestone1")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img
                    src={roadmapCheck}
                    className="roadmap-check"
                    alt="Roadmap milestone 2024 Q3-Q4"
                  />
                </div>
                {t("homePage.roadmap.items.1.milestones.milestone2")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2024 Q3-Q4" />
                </div>

                {t("homePage.roadmap.items.1.milestones.milestone4")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2024 Q3-Q4" />
                </div>

                {t("homePage.roadmap.items.1.milestones.milestone5")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2024 Q3-Q4" />
                </div>

                {t("homePage.roadmap.items.1.milestones.milestone4")}
              </div>
            </div>
          </div>
        </div>
        <div className="RoadmapSection_roadmapItem__x63r0">
          <div className="RoadmapSection_itemHeader__1_gyO">
            <div className="RoadmapSection_year__kPhPy">
              {t("homePage.roadmap.items.2.year")}
            </div>
            <div className="RoadmapSection_timeline__0qVRw" />
          </div>
          <div className="RoadmapSection_itemContent__mAhFQ">
            <div className="RoadmapSection_itemTitle__wxXIz">
              {t("homePage.roadmap.items.2.quarter")}
            </div>
            <div className="RoadmapSection_itemList__jZGEe">
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q1-Q2" />
                </div>
                {t("homePage.roadmap.items.2.milestones.milestone1")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q1-Q2" />
                </div>

                {t("homePage.roadmap.items.2.milestones.milestone2")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q1-Q2" />
                </div>
                {t("homePage.roadmap.items.2.milestones.milestone3")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q1-Q2" />
                </div>

                {t("homePage.roadmap.items.2.milestones.milestone4")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q1-Q2" />
                </div>

                {t("homePage.roadmap.items.2.milestones.milestone5")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q1-Q2" />
                </div>

                {t("homePage.roadmap.items.2.milestones.milestone6")}
              </div>
            </div>
          </div>
        </div>
        <div className="RoadmapSection_roadmapItem__x63r0">
          <div className="RoadmapSection_itemHeader__1_gyO">
            <div className="RoadmapSection_year__kPhPy RoadmapSection_active__NgDsQ">
              {t("homePage.roadmap.items.3.year")}
            </div>
            <div className="RoadmapSection_timeline__0qVRw" />
          </div>
          <div className="RoadmapSection_itemContent__mAhFQ">
            <div className="RoadmapSection_itemTitle__wxXIz">
              {t("homePage.roadmap.items.3.quarter")}
            </div>
            <div className="RoadmapSection_itemList__jZGEe">
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q3" />
                </div>

                {t("homePage.roadmap.items.3.milestones.milestone1")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q3" />
                </div>

                {t("homePage.roadmap.items.3.milestones.milestone2")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q3" />
                </div>

                {t("homePage.roadmap.items.3.milestones.milestone3")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q3" />
                </div>

                {t("homePage.roadmap.items.3.milestones.milestone4")}
              </div>
            </div>
          </div>
        </div>
        <div className="RoadmapSection_roadmapItem__x63r0">
          <div className="RoadmapSection_itemHeader__1_gyO">
            <div className="RoadmapSection_year__kPhPy RoadmapSection_active__NgDsQ">
              {t("homePage.roadmap.items.4.year")}
            </div>
            <div className="RoadmapSection_timeline__0qVRw" />
          </div>
          <div className="RoadmapSection_itemContent__mAhFQ">
            <div className="RoadmapSection_itemTitle__wxXIz">
              {t("homePage.roadmap.items.4.quarter")}
            </div>
            <div className="RoadmapSection_itemList__jZGEe">
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q4" />
                </div>

                {t("homePage.roadmap.items.4.milestones.milestone1")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q4" />
                </div>

                {t("homePage.roadmap.items.4.milestones.milestone2")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone 2025 Q4" />
                </div>

                {t("homePage.roadmap.items.4.milestones.milestone3")}
              </div>
            </div>
          </div>
        </div>
        <div className="RoadmapSection_roadmapItem__x63r0">
          <div className="RoadmapSection_itemHeader__1_gyO">
            <div className="RoadmapSection_year__kPhPy RoadmapSection_active__NgDsQ">
              {t("homePage.roadmap.items.5.year")}
            </div>
            <div className="RoadmapSection_timeline__0qVRw" />
          </div>
          <div className="RoadmapSection_itemContent__mAhFQ">
            <div className="RoadmapSection_itemTitle__wxXIz">
              {" "}
              {t("homePage.roadmap.items.5.quarter")}
            </div>
            <div className="RoadmapSection_itemList__jZGEe">
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone beyond" />
                </div>

                {t("homePage.roadmap.items.5.milestones.milestone1")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone beyond" />
                </div>
                {t("homePage.roadmap.items.5.milestones.milestone2")}
              </div>
              <div className="RoadmapSection_milestone__uMKMP">
                <div className="RoadmapSection_icon__yqw_C">
                  <img src={roadmapArrow} alt="Roadmap milestone beyond" />
                </div>
                {t("homePage.roadmap.items.5.milestones.milestone3")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
