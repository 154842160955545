async function authenticateUser(walletAddress) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ walletAddress }), // Send the wallet address to the server
      credentials: "include", // This ensures that the HttpOnly cookie is sent with the request
    });

    if (response.ok) {
      console.log("Authenticated successfully, JWT set in HttpOnly cookie");
    } else {
      const error = await response.json();
      console.error("Authentication failed:", error.message);
    }
  } catch (error) {
    console.error("An error occurred during authentication:", error);
  }
}

export default authenticateUser;
