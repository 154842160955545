import React from "react";
import "./Background.css";
const Background = () => {
  return (
    <div>
      {/* <div className="shadow-bottom  absolute inset-[0] max-h-[100vh] flex overflow-hidden">
        <img
          className="h-full flex-[0_0_50%] opacity-[0.2] object-contain"
          src={left_bg_referral}
          alt="[]"
        />
        <img
          className="h-full flex-[0_0_50%] opacity-[0.2] object-contain hidden laptop:block"
          src={right_bg_referral}
          alt="[]"
        />
      </div> */}
      <div className="sc-faab9d36-0 sc-faab9d36-11 joSyap gPuNdC">
        <div className="sc-271c003-0 bfugXH sc-faab9d36-5 jWroVr">
          <div className="sc-271c003-1 hBaNbX" />
          <div className="sc-faab9d36-2 sc-faab9d36-3 hFbZhE gfuaku" />
          <div className="sc-faab9d36-2 sc-faab9d36-4 hFbZhE icrZgZ" />
          <div className="sc-faab9d36-1 limpYk" />
          <div className="sc-faab9d36-6 dHUIdR">
            <div className="sc-faab9d36-7 jLlnFM">
              <div className="sc-faab9d36-8 iFdytO" />
              <div className="sc-faab9d36-8 iFdytO">
                <div className="sc-faab9d36-9 jaUUUT" />
              </div>
              <div className="sc-faab9d36-8 iFdytO" />
              <div className="sc-faab9d36-8 iFdytO">
                <div className="sc-faab9d36-10 fEwUDy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Background;
