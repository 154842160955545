import React from "react";
import "./shimmer.css";

const ShimmerPlaceholder = () => (
  <div className="rt-Box sc-eDLKkx jmXpMk" style={{ marginBottom: 0 }}>
    <div className="rt-Flex rt-r-display-flex rt-r-fd-column rt-r-jc-start rt-r-gap-5">
      <div className="rt-Grid rt-r-gap-4 gats">
        <div className="rt-Flex rt-r-display-flex rt-r-fd-column rt-r-jc-start rt-r-gap-2">
          <div className="shimmerr shimmerr-large-text" />
          <div className="rt-Flex rt-r-display-flex rt-r-jc-start rt-r-gap-1">
            <div className="shimmerr shimmerr-text" />
            <div
              className="rt-Flex rt-r-display-flex rt-r-ai-center rt-r-jc-start rt-r-pb-1"
              style={{ alignSelf: "flex-end" }}
            >
              {/* <div className="shimmer shimmer-text" /> */}
            </div>
          </div>
        </div>
        <div className="rt-Flex rt-r-display-flex rt-r-fd-column rt-r-jc-start rt-r-gap-2">
          <div className="shimmerr shimmerr-large-text" />
          <div className="rt-Flex rt-r-display-flex rt-r-jc-start rt-r-gap-1">
            <div className="shimmerr shimmerr-text" />
            <div
              className="rt-Flex rt-r-display-flex rt-r-ai-center rt-r-jc-start rt-r-pb-1"
              style={{ alignSelf: "flex-end", paddingTop: 32 }}
            >
              {/* <div className="shimmer shimmer-text" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ShimmerPlaceholder;
