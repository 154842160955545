import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./Header.css";

import close from "../../assets/img/close.52bbb0c0.svg";
import snovaCoin from "../../assets/img/logo-icon-dark.png";
import wallet from "../../assets/img/connect-wallet.svg";
import score__1 from "../../assets/img/score (1).png";

import { ReactComponent as TrustwalletLogoMobile } from "../../assets/img/trustwallet-icon-mobile.svg";

import stacknovaLogo from "../../assets/img/stacknova-full.png";

import ukFlag from "../../assets/img/uk-flag.svg";
import chinaFlag from "../../assets/img/china-flag.svg";
import japanFlag from "../../assets/img/japan-flag.svg";
import koreaFlag from "../../assets/img/korea-flag.svg";
import russiaFlag from "../../assets/img/russia-flag.svg";
import germanyFlag from "../../assets/img/germany-flag.svg";
import spainFlag from "../../assets/img/spain-flag.svg";
import franceFlag from "../../assets/img/france-flag.svg";
import turkeyFlag from "../../assets/img/turkey-flag.svg";
import vietnamFlag from "../../assets/img/vietnam-flag.svg";
import indiaFlag from "../../assets/img/india-flag.svg";
import portugalFlag from "../../assets/img/portugal-flag.svg";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { setModal4 } from "../../reducers/modalSlice";
import { setTopBannerVisibility } from "../../reducers/topBannerSlice";

import chartUser from "../../assets/img/chart-user.png";
import chooseLanguage from "../../assets/img/choose-language-icon.svg";
import ConnectButton from "../../components/Home/Banner/subcomponents/ConnectButton";
import DashboardButton1 from "./headerSelectors/DashboardButton1";
import DashboardButton2 from "./headerSelectors/DashboardButton2";

import { useWalletConnection } from "../../hooks/useMetamaskConnection";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const languages = [
  { code: "en", lang: "English", flag: ukFlag },
  { code: "zh", lang: "Chinese", flag: chinaFlag },
  { code: "ja", lang: "Japanese", flag: japanFlag },
  { code: "ko", lang: "Korean", flag: koreaFlag },
  { code: "ru", lang: "Russian", flag: russiaFlag },
  { code: "es", lang: "Spanish", flag: spainFlag },
  { code: "de", lang: "German", flag: germanyFlag },
  { code: "pt", lang: "Portuguese", flag: portugalFlag },
  { code: "fr", lang: "French", flag: franceFlag },
  { code: "tr", lang: "Turkish", flag: turkeyFlag },
  { code: "hi", lang: "Hindi", flag: indiaFlag },
  { code: "vi", lang: "Vietnam", flag: vietnamFlag },
];

const Header = () => {
  const { wallets, getWalletLogo } = useWalletConnection();
  const { address, isConnected } = useWeb3ModalAccount();
  const additionalProps = {
    style: { display: "block !important" },
    className: "wallet-icon noad",
  };

  const renderWalletLogo = () => {
    if (isConnected) {
      if (wallets.isPhantom) {
        return React.cloneElement(getWalletLogo("isPhantom"), {
          className: "wallet-icon noad",
        });
      }
      if (wallets.isMetaMask) {
        return React.cloneElement(getWalletLogo("isMetaMask"), {
          className: "wallet-icon noad",
        });
      }
      if (wallets.isCoinbaseWallet) {
        return React.cloneElement(getWalletLogo("isCoinbaseWallet"), {
          className: "wallet-icon noad",
        });
      }
      if (wallets.isTrustWallet) {
        return (
          <TrustwalletLogoMobile
            key="trustwallet-mobile"
            {...additionalProps}
          />
        );
      }
      if (wallets.isWalletConnect) {
        return React.cloneElement(getWalletLogo("isWalletConnect"), {
          className: "wallet-icon noad",
        });
      }

      return null;
    } else {
      return null;
    }
  };

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const modal4 = useSelector((state) => state.modals.modal4);
  const Topbanner = useSelector((state) => state.topBanner.isVisible);

  const [modal, setModal] = useState(false);
  const [stickyNav, setStickyNav] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [sticky2, setSticky2] = useState(false);
  const [sticky3, setSticky3] = useState(false);
  const [nav, setNav] = useState(false);
  const [drop, setDrop] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
    dispatch(setModal4({ isOpen: false, data: null }));
  };

  const hideIt = () => {
    dispatch(setTopBannerVisibility(false));
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleModalClose = () => {
    setModal(false);
  };

  const isHomePage = window.location.pathname === "/";

  const toggleDropFunc = () => {
    setDrop((prevDrop) => !prevDrop);
  };

  const navToggle = () => {
    setNav((prevNav) => !prevNav);
  };

  // Combined Scroll Event Listener
  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = Topbanner ? 132 : 64;
      const isScrolled = window.scrollY > scrollThreshold;
      setStickyNav(isScrolled);
      setSticky2(isScrolled);
      setSticky3(!isScrolled && !Topbanner);

      // Set modal to false whenever scroll position changes
      setModal(false);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [Topbanner]);

  // Handle Scroll Position (if needed elsewhere)
  useEffect(() => {
    const handleScrollPosition = () => {
      const currentPosition = window.pageYOffset;
      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScrollPosition, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScrollPosition);
    };
  }, []);

  // Remember Selected Language on Component Mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage && savedLanguage !== i18n.language) {
      setTimeout(() => {
        i18n.changeLanguage(savedLanguage);
      }, 100);
    }
  }, [i18n]);

  // Set Document Direction Based on Language
  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const renderSvg = renderWalletLogo();

  return (
    <>
      {/* Overlay for Modal */}
      {modal && <div onClick={toggleModal} className="overlaybanner"></div>}

      {/* Top Banner */}
      {Topbanner && (
        <div className={`styles_banner__tQbE5 ${stickyNav ? "mblac" : ""}`}>
          <div className="max-width top-banner">
            <div className="styles_content__n3uvo" style={{ color: "white" }}>
              <img
                alt="flame"
                loading="lazy"
                className="presale-notification"
                decoding="async"
                data-nimg={1}
                style={{ color: "transparent" }}
                src={snovaCoin}
              />
              {/* Stacknova Token Sale is Now LIVE! */}
              {t("Header.presaleBanner")}
            </div>
            <div className="cross-ic2">
              <svg
                width={12}
                height={12}
                viewBox="0 0 12 12"
                onClick={hideIt}
                fill="none"
                className="styles_close__hZeAJ"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
              </svg>
            </div>
          </div>
        </div>
      )}

      {/* Desktop Header */}
      <div className="desk-only alla">
        <div className="new-header">
          <nav
            className={`${
              stickyNav
                ? "nav-are border-b-[1px] border-[#3a3a3a] pl-8 sican"
                : "nav-are border-b-[1px] border-[#3a3a3a] pl-8"
            } ${Topbanner ? "" : "topZero"}`}
          >
            <div className="max-width h-[85px] flex items-center justify-between">
              {isHomePage ? (
                <HashLink
                  smooth
                  to="/#"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  className="w-full"
                >
                  <img
                    src={stacknovaLogo}
                    className="stacknova-logo"
                    alt="Stacknova Logo"
                  />
                </HashLink>
              ) : (
                <Link to="/" className="w-full">
                  <img
                    src={stacknovaLogo}
                    className="stacknova-logo"
                    alt="Stacknova Logo"
                  />
                </Link>
              )}
              <div className="flex w-full justify-ends">
                <div className="flex flex-row">
                  <nav
                    aria-label="Main"
                    data-orientation="horizontal"
                    dir="ltr"
                    className="flex"
                  >
                    <div style={{ position: "relative" }}>
                      <ul
                        data-orientation="horizontal"
                        className="flex gap-6"
                        dir="ltr"
                      >
                        <li className="relative px-4">
                          <a
                            href="https://docs.stacknova.ai/stacknova-ai"
                            target="_blank"
                            className="font-pp flex items-center gap-1 nav-link"
                            rel="noopener noreferrer"
                          >
                            Whitepaper
                          </a>
                        </li>
                        <li className="relative px-4">
                          {isHomePage ? (
                            <HashLink
                              smooth
                              to="#tokenomics"
                              className="font-pp flex items-center gap-1 font-bold"
                            >
                              Tokenomics
                            </HashLink>
                          ) : (
                            <HashLink
                              smooth
                              to="/#tokenomics"
                              className="font-pp flex items-center gap-1 font-bold"
                            >
                              Tokenomics
                            </HashLink>
                          )}
                        </li>
                        <li className="relative px-4">
                          <Link
                            to="/referral"
                            id="radix-:Rj6m:-trigger-radix-:R9j6m:"
                            data-state="closed"
                            aria-expanded="false"
                            aria-controls="radix-:Rj6m:-content-radix-:R9j6m:"
                            className="font-pp flex items-center gap-1 nav-link"
                            data-radix-collection-item
                          >
                            <span className="primary-color flex items-center gap16">
                              <div className="liveDot"></div>Referral
                            </span>
                          </Link>
                        </li>
                        <li className="relative px-4">
                          {isHomePage ? (
                            <HashLink
                              smooth
                              to="#faq"
                              className="font-pp flex items-center gap-1 nav-link"
                            >
                              FAQs
                            </HashLink>
                          ) : (
                            <HashLink
                              smooth
                              to="/#faq"
                              className="font-pp flex items-center gap-1 nav-link"
                            >
                              FAQs
                            </HashLink>
                          )}
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <DashboardButton1 />
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* Modal4 Overlay */}
      {modal4.isOpen && (
        <div
          onClick={() => dispatch(setModal4({ isOpen: false, data: null }))}
          className="modla-over"
        ></div>
      )}

      {/* Modal4 Content */}
      {modal4.isOpen && (
        <div className="heo">
          <div className="mya border-border bg-bg fixed left-1/2 top-1/2 z-[999] flex w-[370px] max-w-full flex-col gap-6 rounded border p-8">
            <h2 id="radix-:R1r6mH1:">
              <b>{t("Header.chooseLanguage")}</b>
            </h2>
            <div className="grid grid-cols-2 gap-4 text-center">
              {languages.map((lng) => (
                <button
                  key={lng.code}
                  onClick={() => changeLanguage(lng.code)}
                  type="button"
                  className={`${
                    lng.code === i18n.language ? "btn-active" : ""
                  } bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-text-[26px]/none bl-text-primary secondary-transform btn-he`}
                  aria-label="Choose Language"
                >
                  <img
                    alt={lng.code}
                    loading="lazy"
                    width="20"
                    height="24"
                    decoding="async"
                    src={lng.flag}
                    className="language-flag"
                  />
                  {lng.code.toUpperCase()}
                  <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                  <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                  <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                  <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                </button>
              ))}
            </div>
            <div className="text-text-tip text-2xs">
              {t("Header.languageDesc")}
            </div>
          </div>
        </div>
      )}

      {/* Mobile Header */}
      <div className={`mbl-only`}>
        <div
          className={
            sticky2
              ? "border-b border-border border-mbl sican2"
              : "border-b border-border border-mbl"
          }
        >
          <div className="h-16 asa max-width gap-4 items-center justify-between flex-shrink-0 flex flex-row">
            <div className="flex flex-row h-full items-center gap-6 lg:gap-8 mbl-logo">
              <Link to="/">
                <img src={stacknovaLogo} alt="Stacknova Logo" />
              </Link>
            </div>
            <div className="flex align-center">
              <div className="flex flex-row gap-6 items-center">
                <button
                  onClick={() =>
                    dispatch(setModal4({ isOpen: !modal4.isOpen, data: null }))
                  }
                  className="connect-lan bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-text-primary secondary-transform"
                  aria-label="Choose Language"
                >
                  <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                  <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                  <img
                    src={chooseLanguage}
                    className="choose-language-icon"
                    alt="Choose Language Button"
                  />
                  <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                  <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                </button>
                <DashboardButton2 />
                <div className="flex flex-row border-border-landing h-full w-[230px] items-center justify-center border-l-[1px] pr-1 secondary-transform">
                  <ConnectButton
                    className="connect-mbl connect-lan bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-text-primary"
                    aria-label="Connect Wallet"
                  >
                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                    {renderSvg ? (
                      renderSvg
                    ) : (
                      <svg
                        width="23"
                        className="wallet-icon noad"
                        viewBox="0 0 16 15"
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
                        />
                      </svg>
                    )}
                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                  </ConnectButton>

                  {/* Uncomment and use if needed
                  <button
                    className="primary-bg primary-mbl flex items-center justify-center duration-100 transition-colors disabled:opacity-50 active:opacity-75 disabled:cursor-not-allowed border border-gradient-image hover:text-text-brand active:text-text-brand disabled:text-text-secondary text-sm px-2 h-10 rounded"
                    type="button"
                  >
                    <div className="flex items-center">
                      <img src={wallet} className="wallet-icon-mobile" />
                      <span className="mx-1"> {t("Header.connectWallet")}</span>
                    </div>
                  </button>
                  */}
                </div>
              </div>
              {modal ? (
                <svg
                  onClick={toggleModal}
                  width={20}
                  height={20}
                  viewBox="0 0 15 15"
                  fill="none"
                  className="block cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(1.5, 1.5)">
                    <path
                      d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  onClick={toggleModal}
                  width={20}
                  height={20}
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="block cursor-pointer"
                >
                  <path
                    d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </div>
          </div>

          {/* Mobile Navigation Modal */}
          {modal && (
            <div
              role="dialog"
              id="radix-:R2imm:"
              aria-describedby="radix-:R2immH2:"
              aria-labelledby="radix-:R2immH1:"
              data-state="open"
              className={`animate-menu-show mgls bg-bg data-[state=closed]:animate-fade-out fixed top-0 z-[1000] max-h-[80%] w-full overflow-auto ${
                stickyNav ? "stick" : ""
              } ${Topbanner ? "" : "topZero"}`}
              tabIndex={-1}
              style={{ pointerEvents: "auto" }}
            >
              <div
                className="flex flex-col gap-2 p-4 pt-1"
                style={{
                  fontWeight: "bold",
                }}
              >
                <div className="flex flex-col cursor-pointer gap-2">
                  <div className="flex flex-row items-center justify-between px-1 py-2">
                    <a
                      href="https://docs.stacknova.ai/stacknova-ai"
                      target="_blank"
                      className="nav-link"
                      rel="noopener noreferrer"
                    >
                      Whitepaper
                    </a>
                  </div>
                </div>

                {/* Tokenomics Link */}
                {isHomePage ? (
                  <HashLink smooth to="#tokenomics" className="nav-link px-1">
                    <span>Tokenomics</span>
                  </HashLink>
                ) : (
                  <HashLink smooth to="/#tokenomics" className="nav-link px-1">
                    Tokenomics
                  </HashLink>
                )}

                <div className="flex flex-col cursor-pointer gap-2">
                  <div className="flex flex-row items-center justify-between px-1 py-2 text-theme ">
                    <Link to="/referral" onClick={toggleModalClose}>
                      <span className="flex items-center gap16">
                        <div className="liveDot"></div> Referral
                      </span>
                    </Link>
                  </div>
                </div>

                {/* FAQs Link */}
                <div className="flex flex-col cursor-pointer gap-2">
                  <div className="flex flex-row items-center justify-between px-1 py-2">
                    {isHomePage ? (
                      <HashLink smooth to="#faq" className="nav-link">
                        FAQs
                      </HashLink>
                    ) : (
                      <HashLink smooth to="/#faq" className="nav-link">
                        FAQs
                      </HashLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Spacer to Prevent Content Jump */}
        </div>{" "}
        <div className={sticky3 ? "min-hah" : ""}></div>
        <div className="header-spacer"></div>
      </div>
    </>
  );
};

export default Header;
