import { useState, useEffect, useRef } from "react";

export function useDebouncedValue(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const handler = useRef(null);

  useEffect(() => {
    if (handler.current) {
      clearTimeout(handler.current);
    }

    handler.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      if (handler.current) {
        clearTimeout(handler.current);
      }
    };
  }, [value, delay]);

  return debouncedValue;
}

export function truncateToDecimalPlace(num, decimalPlaces) {
  const regex = new RegExp(`^(.*\\..{${decimalPlaces}})|.*$`);
  return num.toString().match(regex)[1] || num.toString();
}

export const formatValue = (value, decimals) => {
  if (isNaN(value) || value === undefined) return "...";
  return Number(truncateToDecimalPlace(value, decimals)).toFixed(decimals);
};

export function validateAndFormatInput(
  inputValue,
  previousInput,
  decimalPlaces
) {
  const decimalOnlyInputs = ["0.", "0,", ".", ","];
  let validInput = inputValue.replace(/[^0-9.,]+/g, "").replace(/,/g, ".");

  let parts = validInput.split(".");

  if (parts.length > 2) {
    validInput = `${parts[0]}.${parts.slice(1).join("")}`;
  }

  if (validInput.startsWith(".")) {
    validInput = "0" + validInput;
  }

  parts = validInput.split(".");

  if (parts.length > 1 && parts[1].length > decimalPlaces) {
    parts[1] = parts[1].substring(0, decimalPlaces);
    validInput = parts.join(".");
  }

  if (decimalOnlyInputs.includes(previousInput) && validInput === "0") {
    validInput = "";
  }

  return validInput;
}

export const getChainIdFromSelectedCoin = (coin) => {
  const coinToChainIdMap = {
    eth: 1,
    bnb: 56,
    plg: 137,
    arbi: 42161,
    avax: 43114,
    base: 8453,
    opt: 10,
    zksync: 324,
  };
  return coinToChainIdMap[coin];
};

export const formatRanking = (ranking) => {
  return ranking > 0 ? `#${ranking}` : "#";
};
