import React from "react";
import "./WhyBtcoin.css";
import Unmatched from "../../../assets/img/unmatched-security.webp";
import Decentralized from "../../../assets/img/decentralized-foundation.webp";
import Legacy from "../../../assets/img/legacy-of-trust.webp";

import { useTranslation } from "react-i18next";
import Innovative from "../../../assets/img/innovative-integration.webp";
const WhyBtcoin = () => {
  const { t } = useTranslation();
  return (
    <div className="section-padding whyus">
      <div className="SectionHeaderV2_SectionHeaderV2__UELE4 home_sectionHeader__suL52 max-width">
        <div className="SectionHeaderV2_sectionHeaderSubtitle__eUmZ_ mb34 text-center">
          {t("homePage.whyBitcoin.sectionTitle")}
        </div>
      </div>
      <div className="max-width whybtc relative z-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <div className="dark:bg-pgray-800 border border-pgray-200 dark:border-pgray-600 rounded-xl h-[400px] md:h-[500px] overflow-hidden relative sm:col-span-2 card-bgs">
            <div className="shiny_effect z-[25]" />
            <div className="img-tag ">
              <img src={Unmatched} alt="" />
            </div>
            <div className="text-center absolute w-full bottom-0 z-[12] p-10">
              <div className="text-3xl font-medium text-pgray-800 dark:text-pwhite mb-2">
                {t("homePage.whyBitcoin.unmatchedSecurity.title")}
              </div>
              <div className="text-xl font-medium text-pgray-600/70 dark:text-pwhite/70">
                {t("homePage.whyBitcoin.unmatchedSecurity.description")}
              </div>
            </div>
          </div>
          <div className="dark:bg-pgray-800 border border-pgray-200 dark:border-pgray-600 rounded-xl h-[400px] md:h-[500px] overflow-hidden relative card-bgs">
            <div className="shiny_effect z-[25]" />
            <div className="img-tag smal-blk">
              <img src={Legacy} alt="" />
            </div>
            <div className="text-center absolute w-full bottom-0 z-[12] p-10">
              <div className="text-3xl font-medium text-pgray-800 dark:text-pwhite mb-2">
                {t("homePage.whyBitcoin.legacyTrust.title")}
              </div>
              <div className="text-xl font-medium text-pgray-600/70 dark:text-pwhite/70">
                {t("homePage.whyBitcoin.legacyTrust.description")}
              </div>
            </div>
          </div>
          <div className="dark:bg-pgray-800 border border-pgray-200 dark:border-pgray-600 rounded-xl h-[400px] md:h-[500px] overflow-hidden relative card-bgs">
            <div className="shiny_effect z-[25]" />
            <div className="img-tag smal-blk">
              <img src={Decentralized} alt="" />
            </div>
            <div className="text-center absolute w-full bottom-0 z-[12] p-10">
              <div className="text-3xl font-medium text-pgray-800 dark:text-pwhite mb-2">
                {t("homePage.whyBitcoin.decentralizedFoundation.title")}
              </div>
              <div className="text-xl font-medium text-pgray-600/70 dark:text-pwhite/70">
                {t("homePage.whyBitcoin.decentralizedFoundation.description")}
              </div>
            </div>
          </div>
          <div className="dark:bg-pgray-800 border border-pgray-200 dark:border-pgray-600 rounded-xl h-[400px] md:h-[500px] overflow-hidden relative sm:col-span-2 card-bgs">
            <div className="shiny_effect z-[25]" />
            <div className="img-tag">
              <img src={Innovative} alt="" />
            </div>
            <div className="text-center absolute w-full bottom-0 z-[12] p-10">
              <div className="text-3xl font-medium text-pgray-800 dark:text-pwhite mb-2">
                {t("homePage.whyBitcoin.innovativeIntegration.title")}
              </div>
              <div className="text-xl font-medium text-pgray-600/70 dark:text-pwhite/70">
                {t("homePage.whyBitcoin.innovativeIntegration.description")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyBtcoin;
