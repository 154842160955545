// src/components/Home/Banner/bannerSelectors/DashboardButton1.js
import React from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import chartUser from "../../../assets/img/chart-user.png";
import chooseLanguage from "../../../assets/img/choose-language-icon.svg";
import wallet from "../../../assets/img/connect-wallet.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setModal4 } from "../../../reducers/modalSlice";
import ukFlag from "../../../assets/img/uk-flag.svg";
import chinaFlag from "../../../assets/img/china-flag.svg";
import japanFlag from "../../../assets/img/japan-flag.svg";
import koreaFlag from "../../../assets/img/korea-flag.svg";
import russiaFlag from "../../../assets/img/russia-flag.svg";
import germanyFlag from "../../../assets/img/germany-flag.svg";
import spainFlag from "../../../assets/img/spain-flag.svg";
import franceFlag from "../../../assets/img/france-flag.svg";
import turkeyFlag from "../../../assets/img/turkey-flag.svg";
import portugalFlag from "../../../assets/img/portugal-flag.svg";
import ConnectButton from "../../../components/Home/Banner/subcomponents/ConnectButton";
import { useWalletConnection } from "../../../hooks/useMetamaskConnection";
import "./../Header.css";

const languages = [
  { code: "en", lang: "English", flag: ukFlag },
  { code: "cn", lang: "Chinese", flag: chinaFlag },
  { code: "jp", lang: "Japanese", flag: japanFlag },
  { code: "kr", lang: "Korean", flag: koreaFlag },
  { code: "ru", lang: "Russian", flag: russiaFlag },
  { code: "de", lang: "German", flag: germanyFlag },
  { code: "es", lang: "Spanish", flag: spainFlag },
  { code: "fr", lang: "French", flag: franceFlag },
  { code: "tr", lang: "Turkish", flag: turkeyFlag },
  { code: "pt", lang: "Portuguese", flag: portugalFlag },
];

const DashboardButton1 = () => {
  const { t, i18n } = useTranslation();
  const { isConnected } = useWeb3ModalAccount();
  const { wallets, getWalletLogo } = useWalletConnection();
  const dispatch = useDispatch();
  const modal4 = useSelector((state) => state.modals.modal4);

  const additionalProps = {
    width: 23,
    className: "wallet-icon",
  };

  const renderWalletLogo = () => {
    if (isConnected) {
      if (wallets.isPhantom) {
        return React.cloneElement(getWalletLogo("isPhantom"), additionalProps);
      }
      if (wallets.isWalletConnect) {
        return React.cloneElement(
          getWalletLogo("isWalletConnect"),
          additionalProps
        );
      }
      if (wallets.isMetaMask) {
        return React.cloneElement(getWalletLogo("isMetaMask"), additionalProps);
      }
      if (wallets.isCoinbaseWallet) {
        return React.cloneElement(
          getWalletLogo("isCoinbaseWallet"),
          additionalProps
        );
      }
      if (wallets.isTrustWallet) {
        return React.cloneElement(
          getWalletLogo("isTrustWallet"),
          additionalProps
        );
      }

      return React.cloneElement(
        getWalletLogo("isWalletConnect"),
        additionalProps
      );
    } else {
      return null;
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(setModal4({ isOpen: false, data: null }));
  };

  if (!isConnected) {
    return (
      <div className="flex flex-row gap-6 items-center ml-20">
        <button
          onClick={() =>
            dispatch(setModal4({ isOpen: !modal4.isOpen, data: null }))
          }
          className="connect-lan  bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none   bl-text-primary secondary-transform"
          alt="Choose language"
          aria-hidden="true"
        >
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
          <img src={chooseLanguage} className="choose-language-icon" />
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
        </button>
        <div className="flex flex-row border-border-landing h-full center-tj w-[230px] items-center justify-center border-l-[1px] pr-1">
          <ConnectButton
            className="new-connect connect-lanas bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none   bl-text-primary secondary-transform"
            buttonText={t("Header.connectWallet")}
          >
            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
            {/* <img src={wallet} className="wallet-icon" /> */}
            <svg
              width="23"
              className="wallet-icon"
              viewBox="0 0 16 15"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
              />
            </svg>

            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
          </ConnectButton>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row gap-6 items-center ml-20">
      <button
        onClick={() =>
          dispatch(setModal4({ isOpen: !modal4.isOpen, data: null }))
        }
        className="connect-lan  bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none   bl-text-primary secondary-transform"
        alt="Choose language"
        aria-hidden="true"
      >
        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
        <img src={chooseLanguage} className="choose-language-icon" />
        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
      </button>
      <div className="center-tj tj-ch border-border-landing   border-l-[1px] pr-1">
        <Link to="/dashboard">
          <button
            className="connect-lan  bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none   bl-text-primary secondary-transform"
            alt="Choose language"
            aria-hidden="true"
          >
            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
            <img src={chartUser} className="choose-language-icon" />
            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
          </button>
        </Link>{" "}
      </div>
      <div className="flex flex-row  hfy h-full  items-center justify-center">
        <ConnectButton
          className="new-connect connect-lanas bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none   bl-text-primary secondary-transform"
          buttonText={t("Header.connectWallet")}
        >
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
          {/* <img src={wallet} className="wallet-icon" /> */}
          {renderWalletLogo /*renderWalletLogo()*/ ? (
            renderWalletLogo()
          ) : (
            // <svg
            //   width={23}
            //   className="wallet-icon noad"
            //   viewBox="0 0 35 33"
            //   xmlns="http://www.w3.org/2000/svg"
            //   fill="none"
            //   height="33"
            // >
            //   <g strokeLinecap="round" strokeLinejoin="round" strokeWidth=".25">
            //     <path
            //       d="m32.9582 1-13.1341 9.7183 2.4424-5.72731z"
            //       fill="#e17726"
            //       stroke="#e17726"
            //     />
            //     <g fill="#e27625" stroke="#e27625">
            //       <path d="m2.66296 1 13.01714 9.809-2.3254-5.81802z" />
            //       <path d="m28.2295 23.5335-3.4947 5.3386 7.4829 2.0603 2.1436-7.2823z" />
            //       <path d="m1.27281 23.6501 2.13055 7.2823 7.46994-2.0603-3.48166-5.3386z" />
            //       <path d="m10.4706 14.5149-2.0786 3.1358 7.405.3369-.2469-7.969z" />
            //       <path d="m25.1505 14.5149-5.1575-4.58704-.1688 8.05974 7.4049-.3369z" />
            //       <path d="m10.8733 28.8721 4.4819-2.1639-3.8583-3.0062z" />
            //       <path d="m20.2659 26.7082 4.4689 2.1639-.6105-5.1701z" />
            //     </g>
            //     <path
            //       d="m24.7348 28.8721-4.469-2.1639.3638 2.9025-.039 1.231z"
            //       fill="#d5bfb2"
            //       stroke="#d5bfb2"
            //     />
            //     <path
            //       d="m10.8732 28.8721 4.1572 1.9696-.026-1.231.3508-2.9025z"
            //       fill="#d5bfb2"
            //       stroke="#d5bfb2"
            //     />
            //     <path
            //       d="m15.1084 21.7842-3.7155-1.0884 2.6243-1.2051z"
            //       fill="#233447"
            //       stroke="#233447"
            //     />
            //     <path
            //       d="m20.5126 21.7842 1.0913-2.2935 2.6372 1.2051z"
            //       fill="#233447"
            //       stroke="#233447"
            //     />
            //     <path
            //       d="m10.8733 28.8721.6495-5.3386-4.13117.1167z"
            //       fill="#cc6228"
            //       stroke="#cc6228"
            //     />
            //     <path
            //       d="m24.0982 23.5335.6366 5.3386 3.4946-5.2219z"
            //       fill="#cc6228"
            //       stroke="#cc6228"
            //     />
            //     <path
            //       d="m27.2291 17.6507-7.405.3369.6885 3.7966 1.0913-2.2935 2.6372 1.2051z"
            //       fill="#cc6228"
            //       stroke="#cc6228"
            //     />
            //     <path
            //       d="m11.3929 20.6958 2.6242-1.2051 1.0913 2.2935.6885-3.7966-7.40495-.3369z"
            //       fill="#cc6228"
            //       stroke="#cc6228"
            //     />
            //     <path
            //       d="m8.392 17.6507 3.1049 6.0513-.1039-3.0062z"
            //       fill="#e27525"
            //       stroke="#e27525"
            //     />
            //     <path
            //       d="m24.2412 20.6958-.1169 3.0062 3.1049-6.0513z"
            //       fill="#e27525"
            //       stroke="#e27525"
            //     />
            //     <path
            //       d="m15.797 17.9876-.6886 3.7967.8704 4.4833.1949-5.9087z"
            //       fill="#e27525"
            //       stroke="#e27525"
            //     />
            //     <path
            //       d="m19.8242 17.9876-.3638 2.3584.1819 5.9216.8704-4.4833z"
            //       fill="#e27525"
            //       stroke="#e27525"
            //     />
            //     <path
            //       d="m20.5127 21.7842-.8704 4.4834.6236.4406 3.8584-3.0062.1169-3.0062z"
            //       fill="#f5841f"
            //       stroke="#f5841f"
            //     />
            //     <path
            //       d="m11.3929 20.6958.104 3.0062 3.8583 3.0062.6236-.4406-.8704-4.4834z"
            //       fill="#f5841f"
            //       stroke="#f5841f"
            //     />
            //     <path
            //       d="m20.5906 30.8417.039-1.231-.3378-.2851h-4.9626l-.3248.2851.026 1.231-4.1572-1.9696 1.4551 1.1921 2.9489 2.0344h5.0536l2.962-2.0344 1.442-1.1921z"
            //       fill="#c0ac9d"
            //       stroke="#c0ac9d"
            //     />
            //     <path
            //       d="m20.2659 26.7082-.6236-.4406h-3.6635l-.6236.4406-.3508 2.9025.3248-.2851h4.9626l.3378.2851z"
            //       fill="#161616"
            //       stroke="#161616"
            //     />
            //     <path
            //       d="m33.5168 11.3532 1.1043-5.36447-1.6629-4.98873-12.6923 9.3944 4.8846 4.1205 6.8983 2.0085 1.52-1.7752-.6626-.4795 1.0523-.9588-.8054-.622 1.0523-.8034z"
            //       fill="#763e1a"
            //       stroke="#763e1a"
            //     />
            //     <path
            //       d="m1 5.98873 1.11724 5.36447-.71451.5313 1.06527.8034-.80545.622 1.05228.9588-.66255.4795 1.51997 1.7752 6.89835-2.0085 4.8846-4.1205-12.69233-9.3944z"
            //       fill="#763e1a"
            //       stroke="#763e1a"
            //     />
            //     <path
            //       d="m32.0489 16.5234-6.8983-2.0085 2.0786 3.1358-3.1049 6.0513 4.1052-.0519h6.1318z"
            //       fill="#f5841f"
            //       stroke="#f5841f"
            //     />
            //     <path
            //       d="m10.4705 14.5149-6.89828 2.0085-2.29944 7.1267h6.11883l4.10519.0519-3.10487-6.0513z"
            //       fill="#f5841f"
            //       stroke="#f5841f"
            //     />
            //     <path
            //       d="m19.8241 17.9876.4417-7.5932 2.0007-5.4034h-8.9119l2.0006 5.4034.4417 7.5932.1689 2.3842.013 5.8958h3.6635l.013-5.8958z"
            //       fill="#f5841f"
            //       stroke="#f5841f"
            //     />
            //   </g>
            // </svg>
            <svg
              width="23"
              className="wallet-icon"
              viewBox="0 0 16 15"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
              />
            </svg>
          )}

          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
        </ConnectButton>
        {/* <button
        className="flex items-center justify-center duration-100 connect-wallet transition-colors disabled:opacity-50 active:opacity-75 disabled:cursor-not-allowed border hover:text-text-brand active:text-text-brand disabled:text-text-secondary text-sm px-2 h-10 rounded"
        type="button"
      >
        <div className="flex items-center">
          <span className="mx1">
            <img src={wallet} className="wallet-icon" />
              {t("Header.connectWallet")}
          </span>
        </div>
      </button> */}
        {modal4.isOpen ? (
          <div
            onClick={() => dispatch(setModal4({ isOpen: false, data: null }))}
            className="modla-over"
          ></div>
        ) : (
          ""
        )}
        {modal4.isOpen ? (
          <>
            <div className="heo">
              {" "}
              <div className="mya border-border bg-bg fixed left-1/2 top-1/2 z-[999] flex w-[370px] max-w-full flex-col gap-6 rounded border p-8">
                <h2 id="radix-:R1r6mH1:">
                  <b>{t("Header.chooseLanguage")}</b>
                </h2>
                <div className="grid grid-cols-2 gap-4 text-center  ">
                  {languages.map((lng) => (
                    <button
                      key={lng.code}
                      onClick={() => changeLanguage(lng.code)}
                      type="button"
                      className={`${
                        lng.code === i18n.language ? " btn-active" : ""
                      } bl-inline-flex    bl-items-center bl-justify-center bl-transition-colors bl-relative    bl-text-[26px]/none   bl-text-primary secondary-transform btn-he`}
                    >
                      <img
                        alt={lng.code}
                        loading="lazy"
                        width="20"
                        height="24"
                        decoding="async"
                        src={lng.flag}
                        className="language-flag"
                      />
                      {lng.code.toUpperCase()}
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                    </button>
                  ))}
                </div>
                <div className="text-text-tip text-2xs">
                  {t("Header.languageDesc")}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {/* {sticky ? (
        <div
          style={{ height: "65px", position: "relative", zIndex: "-99" }}
          className="space"
        ></div>
      ) : (
        ""
      )} */}
      </div>
    </div>
  );
};

export default DashboardButton1;
