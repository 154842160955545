import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  normalizedPrice: null,
  prices: {},
  allPrices: {}, // Initial state for allPrices
};

const pricesSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {
    setNormalizedPrice(state, action) {
      state.normalizedPrice = action.payload;
    },
    setPrices(state, action) {
      state.prices = action.payload;
    },
    setAllPrices(state, action) {
      state.allPrices = action.payload;
    },
  },
});

export const { setNormalizedPrice, setPrices, setAllPrices } =
  pricesSlice.actions;

export default pricesSlice.reducer;
