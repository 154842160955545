import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dollarAmount: 0,
};

const dollarSlice = createSlice({
  name: "dollar",
  initialState,
  reducers: {
    setDollarAmount(state, action) {
      state.dollarAmount = action.payload;
    },
  },
});

export const { setDollarAmount } = dollarSlice.actions;
export default dollarSlice.reducer;
