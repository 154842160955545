import tokenomics from "../../../assets/img/tokenomics.webp";
import tokenomicstr from "../../../assets/img/tokenomics-tr.webp";
import tokenomicscn from "../../../assets/img/tokenomics-zh.webp";
import tokenomicsjp from "../../../assets/img/tokenomics-ja.webp";
import tokenomicsfr from "../../../assets/img/tokenomics-fr.webp";
import tokenomicspt from "../../../assets/img/tokenomics-pt.webp";
import tokenomicsde from "../../../assets/img/tokenomics-de.webp";
import tokenomicses from "../../../assets/img/tokenomics-es.webp";
import tokenomicsru from "../../../assets/img/tokenomics-ru.webp";
import tokenomicskr from "../../../assets/img/tokenomics-ko.webp";
import tokenomicshi from "../../../assets/img/tokenomics-hi.webp";
import tokenomicsvi from "../../../assets/img/tokenomics-vi.webp";
import arrowIcon from "../../../assets/img/arrow-icon.svg";
import { useTranslation } from "react-i18next";

import blur from "../../../assets/img/tokenomics-blur.webp";
import React, { useEffect } from "react";
import "./Tokenomics.css";
const Tokenomics = () => {
  const { t, i18n } = useTranslation();
  const getImagePath = () => {
    switch (i18n.language) {
      case "en":
        return tokenomics;
      case "de":
        return tokenomicsde;
      case "de":
        return tokenomicsde;
      case "tr":
        return tokenomicstr;
      case "zh":
        return tokenomicscn;
      case "ja":
        return tokenomicsjp;
      case "fr":
        return tokenomicsfr;
      case "pt":
        return tokenomicspt;
      case "es":
        return tokenomicses;
      case "ru":
        return tokenomicsru;
      case "ko":
        return tokenomicskr;
      case "hi":
        return tokenomicshi;
      case "vi":
        return tokenomicsvi;
      default:
        return tokenomics;
    }
  };
  useEffect(() => {
    // Function to scroll to FAQ section
    const scrollToFAQ = () => {
      const faqSection = document.getElementById("tokenomics");
      if (faqSection) {
        faqSection.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (window.location.hash === "#tokenomics") {
      scrollToFAQ();
    }
  }, []);

  return (
    <div>
      <section id="tokenomics" className="max-width tokenomics section-padding">
        <div className="zD">
          <div className="token-distribution-section">
            {/*<h2>{t("homePage.tokenomics.title")}</h2>*/}
            <h2>
              <span className="primary-color">SNOVA</span>{" "}
              <span className="token-distribution-heading">
                {t("homePage.tokenomics.tokenDistribution")}
              </span>
            </h2>
            <h3 className="total-supply-heading">
              {t("homePage.tokenomics.totalSupply")}{" "}
              <span className="primary-color">
                <b>500,000,000</b>
              </span>
            </h3>
            <br />
            <p className="zE">{t("homePage.tokenomics.description")}</p>
            <div>
              <a
                href="https://docs.stacknova.ai/stacknova-ai/token-overview/tokenomics"
                target="_blank"
                className="flat-toggle box-text bx-2 box-btn corner-box "
              >
                <span className=" toggle-title  flex items-center">
                  {t("homePage.tokenomics.checkTokenomics")}
                  <img
                    src={arrowIcon}
                    style={{ paddingLeft: "7.5px" }}
                    alt="Arrow icon"
                  />
                </span>
              </a>
            </div>
            {/* <a
              className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary "
              href="/token"
            >
              <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
              <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
              Check Tokenomics{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="1.8"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fill="none"
                  d="M3 11.938h15.813M12.875 6l6.906 5.755a.238.238 0 0 1 0 .365l-6.906 5.755"
                />
              </svg>
              <div class="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
              <div class="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
            </a> */}
          </div>
          <picture className="zF">
            <img
              alt="Tokenomics graph"
              loading="lazy"
              width={880}
              height={552}
              decoding="async"
              data-nimg={1}
              style={{ color: "transparent" }}
              src={getImagePath()}
            />
          </picture>
          {/*
          <img
            alt="Blurred light"
            loading="lazy"
            width={1621}
            height={1556}
            decoding="async"
            data-nimg={1}
            className="zG"
            style={{ color: "transparent" }}
            src={blur}
          />
          */}
        </div>
      </section>
    </div>
  );
};

export default Tokenomics;
