// src/reducers/totalSnovaTokensSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalSnovaTokens: null,
};

const totalSnovaTokensSlice = createSlice({
  name: "totalSnovaTokens",
  initialState,
  reducers: {
    setTotalSnovaTokens(state, action) {
      state.totalSnovaTokens = action.payload;
    },
  },
});

export const { setTotalSnovaTokens } = totalSnovaTokensSlice.actions;
export default totalSnovaTokensSlice.reducer;
