import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Hardcoding the environment for now
const isDevelopment = true; // Change this to `process.env.NODE_ENV !== 'production'` later

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["topBanner"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: isDevelopment,
});

const persistor = persistStore(store);

export { store, persistor };
