import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
};

const jwtSlice = createSlice({
  name: "jwt",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    clearToken(state) {
      state.token = null;
    },
  },
});

export const { setToken, clearToken } = jwtSlice.actions;

export default jwtSlice.reducer;
