import { useMemo } from "react";

const novaPointsBrackets = [
  { min: 50, max: 249, multiplier: 0.06 },
  { min: 250, max: 499, multiplier: 0.07 },
  { min: 500, max: 999, multiplier: 0.08 },
  { min: 1000, max: 4999, multiplier: 0.09 },
  { min: 5000, max: 9999, multiplier: 0.1 },
  { min: 10000, max: 14999, multiplier: 0.13 },
  { min: 15000, max: 19999, multiplier: 0.16 },
  { min: 20000, max: Infinity, multiplier: 0.2 },
];

const calculateNovaPoints = (investment) => {
  const { multiplier } = novaPointsBrackets.find(
    ({ min, max }) => investment >= min && investment <= max
  ) || { multiplier: 0 };
  return Math.round(investment * multiplier * 100);
};

export default function useNovaPoints(investment) {
  return useMemo(() => calculateNovaPoints(investment), [investment]);
}

export { calculateNovaPoints };
