import { useState, useEffect } from "react";
import { useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ReactComponent as MetaMaskLogo } from "../assets/img/metamask-icon.svg";
import { ReactComponent as CoinbaseWalletLogo } from "../assets/img/coinbase-icon.svg";
import { ReactComponent as TrustWalletLogo } from "../assets/img/trustwallet-icon.svg";
import { ReactComponent as WalletConnectLogo } from "../assets/img/walletconnect-icon.svg";
import { ReactComponent as PhantomLogo } from "../assets/img/phantom-icon.svg";

const walletLogos = {
  isMetaMask: MetaMaskLogo,
  isCoinbaseWallet: CoinbaseWalletLogo,
  isTrustWallet: TrustWalletLogo,
  isPhantom: PhantomLogo,
  isWalletConnect: WalletConnectLogo,
};

const knownWallets = [
  { name: "metamask wallet", flag: "isMetaMask" },
  { name: "trust wallet", flag: "isTrustWallet" },
  { name: "coinbase wallet", flag: "isCoinbaseWallet" },
  { name: "phantom wallet", flag: "isPhantom" },
];

const checkWalletNamesDirectly = (walletProvider) => {
  if (!walletProvider) return new Set();

  const walletsFound = new Set();
  const stack = [walletProvider];
  const visited = new Set();

  while (stack.length > 0) {
    const current = stack.pop();

    if (typeof current === "string") {
      const lowerCaseStr = current.toLowerCase();
      knownWallets.forEach((wallet) => {
        if (lowerCaseStr.includes(wallet.name)) {
          walletsFound.add(wallet.flag);
        }
      });
    } else if (typeof current === "object" && current !== null) {
      if (visited.has(current)) continue;
      visited.add(current);

      for (const key in current) {
        if (Object.prototype.hasOwnProperty.call(current, key)) {
          stack.push(current[key]);
        }
      }
    }
  }

  return walletsFound;
};

export const useWalletConnection = () => {
  const { walletProvider } = useWeb3ModalProvider();

  const [wallets, setWallets] = useState({
    isMetaMask: false,
    isCoinbaseWallet: false,
    isTrustWallet: false,
    isPhantom: false,
    isWalletConnect: false,
  });

  useEffect(() => {
    const checkWalletConnection = () => {
      const walletsFound = checkWalletNamesDirectly(walletProvider);
      setWallets({
        isMetaMask:
          walletProvider?.isMetaMask || walletsFound.has("isMetaMask") || false,
        isCoinbaseWallet:
          walletProvider?.isCoinbaseWallet ||
          walletsFound.has("isCoinbaseWallet") ||
          false,
        isTrustWallet:
          walletProvider?.isTrustWallet ||
          walletsFound.has("isTrustWallet") ||
          false,
        isPhantom:
          walletProvider?.isPhantom || walletsFound.has("isPhantom") || false,
        isWalletConnect:
          walletProvider?.isWalletConnect ||
          walletsFound.has("isWalletConnect") ||
          false,
      });
    };

    checkWalletConnection();
  }, [walletProvider]);

  const getWalletLogo = (walletName) => {
    const WalletLogo = walletLogos[walletName];
    return WalletLogo ? <WalletLogo /> : null;
  };

  return { wallets, getWalletLogo };
};
