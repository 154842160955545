import React from "react";
import "./shimmer.css";

const ShimmerPlaceholder = () => (
  <div className="ant-card-body">
    <div className="sc-kiTBBF fXrYYN ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-align-center ant-flex-justify-space-between">
      <div
        className="ant-space css-dev-only-do-not-override-1q8nizr ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small"
        style={{ marginBottom: 0 }}
      >
        <div className="ant-space-item">
          <div className="shimmerr shimmerr-icon-home" />
        </div>
        <div className="ant-space-item">
          <div className="shimmerr shimmerr-title" />
        </div>
      </div>
      <div
        style={{
          fontSize: 16,
          textAlign: "end",
          textWrap: "balance",
        }}
        className="minus-space"
      >
        <div className="shimmerr shimmerr-large-text" />
      </div>
    </div>
    <div
      className="ant-divider css-dev-only-do-not-override-1q8nizr ant-divider-horizontal"
      role="separator"
    />
    <div className="ant-row css-dev-only-do-not-override-1q8nizr">
      <div
        className="ant-col ant-col-12 css-dev-only-do-not-override-1q8nizr"
        style={{ marginBottom: 0 }}
      >
        <div className="shimmerr shimmerr-title" />
        <div
          className="ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-align-end"
          style={{ gap: 5 }}
        >
          <div className="shimmerr shimmerr-text" />
          <div className="shimmerr shimmerr-text" />
        </div>
      </div>
      <div className="ant-col ant-col-12 css-dev-only-do-not-override-1q8nizr">
        <div className="shimmerr shimmerr-title" />
        <div
          className="ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-align-end ant-flex-justify-flex-start"
          style={{ gap: 5 }}
        >
          <div className="shimmerr shimmerr-text" />
          <div className="shimmerr shimmerr-text" />
        </div>
      </div>
    </div>
    <div
      className="ant-space css-dev-only-do-not-override-1q8nizr ant-space-vertical ant-space-gap-row-small ant-space-gap-col-small"
      style={{ width: "100%", paddingTop: 24 }}
    >
      <div className="ant-space-item">
        <div className="ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-justify-space-between">
          <div className="shimmerr shimmerr-text" />
          <div className="shimmerr shimmerr-text" />
        </div>
      </div>
      <div className="ant-space-item">
        <div className="shimmerr shimmerr-progress"></div>
      </div>
    </div>
  </div>
);

export default ShimmerPlaceholder;
