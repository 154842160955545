import { createSlice } from "@reduxjs/toolkit";

const userRankingByNovaPointsSlice = createSlice({
  name: "userRankingByNovaPoints",
  initialState: {
    ranking: null,
  },
  reducers: {
    setUserRankingByNovaPoints: (state, action) => {
      state.ranking = action.payload;
    },
  },
});

export const { setUserRankingByNovaPoints } =
  userRankingByNovaPointsSlice.actions;

export default userRankingByNovaPointsSlice.reducer;
