import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const approvalSlice = createSlice({
  name: "approval",
  initialState,
  reducers: {
    setApprovedCurrency(state, action) {
      const { chainId, currency, isApproved } = action.payload;
      if (!state[chainId]) {
        state[chainId] = { approvedCurrencies: {}, approvedAmount: "0" };
      }
      state[chainId].approvedCurrencies[currency] = isApproved;
    },
    setApprovedAmount(state, action) {
      const { chainId, amount } = action.payload;
      if (!state[chainId]) {
        state[chainId] = { approvedCurrencies: {}, approvedAmount: "0" };
      }
      state[chainId].approvedAmount = amount.toString();
    },
  },
});

export const { setApprovedCurrency, setApprovedAmount } = approvalSlice.actions;

export default approvalSlice.reducer;
