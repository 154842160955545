import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isBlurred: false,
};

const blurSlice = createSlice({
  name: "blur",
  initialState,
  reducers: {
    setBlur(state, action) {
      state.isBlurred = action.payload;
    },
    setBlur2(state, action) {
      state.isBlurred2 = action.payload;
    },
  },
});

export const { setBlur, setBlur2 } = blurSlice.actions;
export default blurSlice.reducer;
