import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import snovaCoin from "../../../../assets/img/snova-coin-white.svg";
import Coin from "../../../../assets/img/coin.svg";
import { useTranslation } from "react-i18next";
import { setSnovaData } from "../../../../reducers/snovaDataSlice";
import ShimmerPlaceholder from "./shimmer/ShimmerPlaceholderProgress";

const fetchSnovaData = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/getSnovaData`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.json();
};

const ProgressBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    currentPrice = 0,
    nextStagePrice = 0,
    currentStage = 0,
    totalRaised = 0,
    targetTotalRaised = 0,
    tokensSold = 0,
    targetTokensSold = 0,
    currentRoundSold = 0,
    totalTargetForRoundInDollars = 0,
  } = useSelector((state) => state.snovaData || {});

  const { data, error, isLoading } = useQuery({
    queryKey: ["snovaData"],
    queryFn: fetchSnovaData,
    onSuccess: (data) => {
      dispatch(setSnovaData(data));
    },
  });

  const progressPercentage = totalTargetForRoundInDollars
    ? (currentRoundSold / totalTargetForRoundInDollars) * 100
    : 0;

  if (isLoading) return <ShimmerPlaceholder />;
  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div className="ant-card-body">
      <div className="sc-kiTBBF fXrYYN ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-align-center ant-flex-justify-space-between">
        <div
          className="ant-space css-dev-only-do-not-override-1q8nizr ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small"
          style={{ marginBottom: 0 }}
        >
          <div className="ant-space-item">
            <img src={snovaCoin} alt="SNOVA" width={30} />
          </div>
          <div className="ant-space-item">
            <h5
              className="ant-typography minus-space css-dev-only-do-not-override-1q8nizr"
              style={{
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              {`1 SNOVA = `}
              <span
                className="ant-typography ant-typography-secondary css-dev-only-do-not-override-1q8nizr"
                style={{ fontSize: 18 }}
              >
                {`$${currentPrice.toFixed(3)}`}
              </span>
            </h5>
          </div>
        </div>
        <div
          style={{
            fontSize: 16,
            textAlign: "end",
            textWrap: "balance",
          }}
          className="minus-space"
        >
          {t("homePage.banner.nextStagePrice")} ={" "}
          <span
            className="ant-typography  ant-typography-secondary css-dev-only-do-not-override-1q8nizr"
            style={{ fontSize: 18 }}
          >
            {`$${nextStagePrice.toFixed(3)}`}
          </span>
        </div>
      </div>
      <div
        className="ant-divider css-dev-only-do-not-override-1q8nizr ant-divider-horizontal"
        role="separator"
      />
      <div className="ant-row css-dev-only-do-not-override-1q8nizr">
        <div
          className="ant-col ant-col-12 css-dev-only-do-not-override-1q8nizr"
          style={{ marginBottom: 0 }}
        >
          <h5
            className="ant-typography css-dev-only-do-not-override-1q8nizr"
            style={{
              fontWeight: 700,
              color: "rgba(255, 255, 255, 0.7)",
              marginBottom: 10,
            }}
          >
            {t("homePage.banner.totalRaised")}
          </h5>
          <div
            className="ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-align-end"
            style={{ gap: 5 }}
          >
            <span className="sc-dQmiwx gauvWo">
              {`$${totalRaised.toLocaleString()}`}
            </span>
            <span className="sc-bCvmQg ilRECC">
              <span className="inal">/</span>{" "}
              {`$${targetTotalRaised.toLocaleString()}`}
            </span>
          </div>
        </div>
        <div className="ant-col ant-col-12 css-dev-only-do-not-override-1q8nizr">
          <h5
            className="ant-typography css-dev-only-do-not-override-1q8nizr"
            style={{
              fontWeight: 700,
              color: "rgba(255, 255, 255, 0.7)",
              marginBottom: 10,
            }}
          >
            {t("homePage.banner.tokenSold")}
          </h5>
          <div
            className="ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-align-end ant-flex-justify-flex-start"
            style={{ gap: 5 }}
          >
            <span className="sc-dQmiwx igeQtI">
              {`${tokensSold.toLocaleString()}`}
            </span>
            <span className="sc-bCvmQg ilRECC">
              <span className="inal">/</span>{" "}
              {`${targetTokensSold.toLocaleString()}`}
            </span>
          </div>
        </div>
      </div>
      <div
        className="ant-space css-dev-only-do-not-override-1q8nizr ant-space-vertical ant-space-gap-row-small ant-space-gap-col-small"
        style={{ width: "100%", paddingTop: 24 }}
      >
        <div className="ant-space-item">
          <div className="ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-justify-space-between">
            <span className="sc-cPtzlb bsexPo">
              {`${t(
                "homePage.banner.stage1Progress"
              )} ${currentStage}: ${progressPercentage.toFixed(2)}%`}
            </span>
            <span className="sc-BCDEK gPcpgG">
              {`$${currentRoundSold.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} / $${totalTargetForRoundInDollars.toLocaleString()}`}
            </span>
          </div>
        </div>
        <div className="ant-space-item">
          <div
            className="sc-jMbVJB lnHNEZ"
            style={{
              backgroundColor: "rgb(128, 128, 128)",
            }}
          >
            <div
              role="progressbar progress-striped"
              className="sc-dQmiwx ccBJdJ"
              style={{
                width: `${progressPercentage}%`,
                backgroundColor: "rgb(252, 100, 50)",
              }}
            />
            <div
              className="_coin_1gl9j_48 _coin_anim_full_1gl9j_54"
              style={{
                left: `${progressPercentage}%`,
              }}
            >
              <img src={Coin} alt="Coin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
