import React from "react";
import "./LaunchSale.css";
import rocketIcon from "../../../assets/img/light-snova-3d.webp";
import goApply from "../../../assets/img/go-apply.svg";
import { useTranslation } from "react-i18next";
const LaunchSale = () => {
  const { t } = useTranslation();
  return (
    <div className="services">
      <div className="launch_section centerText borderTop borderBottom">
        <div className="middleEl max-width">
          {" "}
          <img src={rocketIcon} alt="rocket IDO" />
          <h2 className="titleBox">
            <span className="orange">{t("homePage.launch.title")}</span>
            <br />{" "}
            <span className="apply-subheading">
              {t("homePage.launch.subtitle")}
            </span>
          </h2>
          <p>
            {" "}
            <span className="no-mbl">{t("homePage.launch.description")}</span>
          </p>{" "}
          {/* <a
            href="https://x8mxdnqdnu4.typeform.com/to/GFvsq3kr"
            target="_blank"
            className="flat-toggle box-text bx-2 box-btn corner-box "
            bis_skin_checked={1}
          >
            <span className=" toggle-title  flex items-center">
            </span>
            <div>
              <img src={goApply} className="go-apply" alt="Apply for IDO" />
              {t("homePage.launch.button")}
            </div>
          </a> */}
          <a
            className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] btn-go-apply bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
            href="https://x8mxdnqdnu4.typeform.com/to/GFvsq3kr"
            target="_blank"
            bis_skin_checked={1}
            style={{ background: "#3a161e5a" }}
          >
            <div
              className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
              bis_skin_checked={1}
            />
            <div
              className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
              bis_skin_checked={1}
            />
            <div>
              <img src={goApply} className="go-apply" alt="" />
              {t("homePage.launch.button")}
            </div>
            <div
              className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
              bis_skin_checked={1}
            />
            <div
              className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
              bis_skin_checked={1}
            />
          </a>
        </div>
        <div className="bck_back" />
      </div>
    </div>
  );
};

export default LaunchSale;
