import React from "react";
import Background from "../components/Referral/Background/Background";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import Banner from "../components/Referral/Banner/Banner";
import Rank from "../components/Referral/Rank/Rank.js";
import Top from "../components/top";
import usePageTitle from "../hooks/usePageTitle";

const Referral = () => {
  usePageTitle("Referral | Stacknova AI - Earn 20% Commission on Referrals");

  return (
    <div className=" min-ref">
      <Header />
      <Top />
      <div className="min-h-screen relative bg-[#000] referral">
        <Background />
        <div className="max-width">
          <div className="pt-[96px] mbl-smalle laptop:pt-[132px]">
            <Banner />
            <Rank />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Referral;
