// src/reducers/totalSnovaValueSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalSnovaValue: null,
};

const totalSnovaValueSlice = createSlice({
  name: "totalSnovaValue",
  initialState,
  reducers: {
    setTotalSnovaValue(state, action) {
      state.totalSnovaValue = action.payload;
    },
  },
});

export const { setTotalSnovaValue } = totalSnovaValueSlice.actions;
export default totalSnovaValueSlice.reducer;
