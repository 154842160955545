import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  network: 0,
  totalNetworkReward: 0,
  amounts: {
    main: { amount: 0, usdValue: 0 },
    usdt: { amount: 0, usdValue: 0 },
    usdc: { amount: 0, usdValue: 0 },
    dai: { amount: 0, usdValue: 0 },
  },
};

const modal3Slice = createSlice({
  name: "modal3",
  initialState,
  reducers: {
    setDataForModal3: (state, action) => {
      const { network, totalNetworkReward, amounts } = action.payload;
      state.network = network;
      state.totalNetworkReward = totalNetworkReward;
      state.amounts = amounts;
    },
  },
});

export const { setDataForModal3 } = modal3Slice.actions;

export default modal3Slice.reducer;
