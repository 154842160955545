export const EURIcon = () => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2062_50215)">
      <path
        d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20Z"
        fill="#0052B4"
      />
      <path
        d="M10.5 3.91412L10.8242 4.91022H11.8672L11.0195 5.5235L11.3437 6.5235L10.4961 5.90631L9.64844 6.5235L9.97265 5.5235L9.125 4.91022H10.1719L10.5 3.91412ZM6.19531 5.69537L7.13281 6.17194L7.87109 5.42975L7.70703 6.4649L8.64062 6.94147L7.60547 7.10553L7.44141 8.14068L6.96484 7.20318L5.92969 7.37115L6.67187 6.62897L6.19531 5.69537ZM4.41406 10.0001L5.41016 9.67584V8.63287L6.02344 9.48053L7.02344 9.15631L6.40625 10.004L7.02344 10.8516L6.02344 10.5274L5.41016 11.3751V10.3282L4.41406 10.0001ZM6.19531 14.3047L6.67187 13.3672L5.92969 12.629L6.96484 12.793L7.44141 11.8594L7.60547 12.8946L8.64062 13.0587L7.70312 13.5352L7.87109 14.5704L7.12891 13.8282L6.19531 14.3047ZM10.5 16.086L10.1758 15.0899H9.13281L9.98047 14.4766L9.65625 13.4766L10.5039 14.0938L11.3516 13.4766L11.0273 14.4766L11.875 15.0899H10.8281L10.5 16.086ZM14.8047 14.3047L13.8672 13.8282L13.1289 14.5704L13.293 13.5352L12.3594 13.0587L13.3945 12.8946L13.5586 11.8594L14.0352 12.7969L15.0703 12.629L14.3281 13.3712L14.8047 14.3047ZM16.5859 10.0001L15.5898 10.3243V11.3672L14.9766 10.5196L13.9766 10.8438L14.5937 9.99615L13.9766 9.1485L14.9766 9.47272L15.5898 8.62506V9.67193L16.5859 10.0001ZM14.8047 5.69537L14.3281 6.63287L15.0703 7.37115L14.0352 7.20709L13.5586 8.14068L13.3945 7.10553L12.3594 6.94147L13.2969 6.4649L13.1289 5.42975L13.8711 6.17194L14.8047 5.69537Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_2062_50215">
        <rect
          width={20}
          height={20}
          fill="white"
          transform="translate(0.5 0)"
        />
      </clipPath>
    </defs>
  </svg>
);
