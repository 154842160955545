// balanceSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balance: null,
  balanceDollar: null,
  CurrencyAmount: null,
  balanceRefreshTrigger: 0,
  version: 0,
};

const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    updateBalance: (state, action) => {
      state.balance = action.payload;
    },
    updateBalanceInDollar: (state, action) => {
      state.balanceDollar = action.payload;
    },
    copyBalanceToInput: (state) => {
      state.CurrencyAmount = state.balance;
      state.version += 1;
    },
    setCurrencyAmount: (state, action) => {
      state.CurrencyAmount = action.payload;
    },
    triggerBalanceRefresh: (state) => {
      state.balanceRefreshTrigger += 1;
    },
  },
});

export const {
  updateBalance,
  updateBalanceInDollar,
  copyBalanceToInput,
  setCurrencyAmount,
  triggerBalanceRefresh,
} = balanceSlice.actions;

export default balanceSlice.reducer;
