import done from "../../../assets/img/done.svg";
import bnb_referral from "../../../assets/img/bnb-referral.png";
import copy from "../../../assets/img/copy.svg";
import Exclamation from "../../../assets/img/Exclamation.svg";
import star from "../../../assets/img/star.svg";
import EthDiamond from "../../../assets/img/ethereum-nirzYqqu.webp";
import CircleInfo from "../../../assets/img/circle-info.svg";

import Dll from "../../../assets/img/dll.png";
import snovaCoin from "../../../assets/img/snova-coin-white.svg";
import nPoints from "../../../assets/img/nova-points.svg";
import LoaderIcon from "../../../assets/img/icon.webp";
import Plg from "../../../assets/img/plg-icon.svg";
import Avax from "../../../assets/img/avax-icon.svg";
import Bsc from "../../../assets/img/bsc-icon.svg";
import Arbi from "../../../assets/img/arbitrum-icon.svg";
import Opt from "../../../assets/img/optimism-icon.svg";
import Blast from "../../../assets/img/blast-2.svg";
import zkSync from "../../../assets/img/zksync-2.svg";
import Base from "../../../assets/img/base-icon.svg";
import UsdC from "../../../assets/img/usdc-symbol.webp";
import Dai from "../../../assets/img/Badge_Dai-Bg2iSjNO (1).webp";

import Usd from "../../../assets/img/usd-icon-flag.svg";
import transactionLink from "../../../assets/img/transaction-link.svg";
import { NavLink } from "react-router-dom";
import claimSuccess from "../../../assets/img/claim-successful.png";
import purchaseError from "../../../assets/img/caution.webp";
import purchaseSummary from "../../../assets/img/purchase-summary-icon.svg";
import transactionError from "../../../assets/img/crossed-circle.svg";

import Tether from "../../../assets/img/tether-icon.webp";
import React, { useEffect, useRef, useState } from "react";

import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
// import useReferrer from "../../../hooks/useReferrer";
import useReferralLink from "../../../hooks/useReferralLink";
// import ReferralRewardsComponent from "./ReferralRewardsComponent";
// import AllReferralRewardsComponent from "./AllReferralRewardsComponent";
// import useFetchReferralRewards from "../../../hooks/useFetchReferralRewards";
import useFetchAllReferralRewards from "../../../hooks/useFetchAllReferralRewards";
// import useFetchReferralCount from "../../../hooks/useFetchReferralCount";
// import useFetchNovaPoints from "../../../hooks/useFetchNovaPoints";
import useClaimReferralRewards from "../../../hooks/useClaimReferralRewards";
import useFetchTopUsersByRewards from "../../../hooks/useFetchTopUsersByRewards";
import {
  formatValue,
  getChainIdFromSelectedCoin,
  formatRanking,
} from "../../../utils/helpers";
import { currencyMap, networkMap } from "../../../utils/modalsHelpers";
import networkMapping from "../../../utils/networkMapping";
import { useSelector, useDispatch } from "react-redux";
import { ethers } from "ethers";
import { setBlur2 } from "../../../reducers/blurSlice";
import {
  setModal2,
  setModal3,
  setModal5,
  setModal6,
} from "../../../reducers/modalSlice";
import { setDataForModal3 } from "../../../reducers/modal3Slice";

import { useTranslation } from "react-i18next";
import "./Rank.css";

import EthSymbol from "../../../assets/img/eth-symbol.webp";
import BnbSymbol from "../../../assets/img/bnb-symbol.webp";
import MaticSymbol from "../../../assets/img/matic-symbol.webp";
import AvaxSymbol from "../../../assets/img/avax-symbol.webp";
import ArbSymbol from "../../../assets/img/arbitrum-symbol.svg";
import UsdtSymbol from "../../../assets/img/tether-icon.webp";
import UsdcSymbol from "../../../assets/img/usdc-symbol.webp";
import DaiSymbol from "../../../assets/img/dai-symbol.webp";

import Eth from "../../../assets/img/eth-icon.svg";
import Bnb from "../../../assets/img/bnb-symbol.webp";
import Matic from "../../../assets/img/matic-symbol.webp";

import Op from "../../../assets/img/optimism-symbol.svg";
import Zk from "../../../assets/img/zkSync-updated-logo.svg";
import trackButtonClick from "../../../index";
import UserListShimmerPlaceholder from "./UserListShimmerPlaceholder";
import RewardsShimmer from "./RewardsShimmer";

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t, i18n } = useTranslation();

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={showTooltip ? "true" : "false"}
        aria-label={text}
      >
        {children}
      </div>
      {showTooltip && (
        <div className="main-tp ab-to ">
          <div />
          {text}
        </div>
      )}
    </div>
  );
};
const Rank = () => {
  const isBlurred2 = useSelector((state) => state.blur.isBlurred2);
  const { modal2, modal3, modal5, modal6 } = useSelector(
    (state) => state.modals
  );
  const userRankingByRewards = useSelector(
    (state) => state.userRankingByRewards.ranking
  );

  const [scrollPosition, setScrollPosition] = useState(967);

  const togglePopup = () => {
    if (!modal6.isOpen) {
      const currentScrollPos = window.scrollY;
      setScrollPosition(currentScrollPos + window.innerHeight / 2);
    }
  };

  const [copiedLink, setCopiedLink] = useState(false);

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // Simulate data fetching
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);

  // const handleCopyLink = (text) => {
  //   navigator.clipboard
  //     .writeText(text)
  //     .then(() => {
  //       setCopiedLink(true);
  //       setTimeout(() => setCopiedLink(false), 2000);
  //     })
  //     .catch((error) => console.error("Error copying text: ", error));
  // };

  const handleBlur = () => {
    dispatch(setBlur2(true));
    setTimeout(() => {
      dispatch(setBlur2(false));
    }, 10000); // 10000 milliseconds = 10 seconds
  };

  const { t } = useTranslation();
  const [drop, setdrop] = useState(false);
  const [isTab, setisTab] = useState(false);
  const [selectedCoin, setselectedCoin] = useState("");
  const [selectedCoinImg, setselectedCoinImg] = useState("");
  const [selectedCoinTabName, setselectedCoinTabName] = useState("");
  const { isConnected, chainId, address } = useWeb3ModalAccount();
  // const { rewards, totalRewards } = useFetchReferralRewards(address, chainId);
  const { rewards, totalRewards } = useSelector(
    (state) => state.referralRewards
  );
  const { allRewards } = useFetchAllReferralRewards(address);
  const { claimRewards } = useClaimReferralRewards();
  const referralCount = useSelector((state) => state.referralCount.count);
  const novaPoints = useSelector((state) => state.amounts.novaPoints);
  const totalReferralRewardsInDollars = useSelector(
    (state) => state.totalReferralRewards.totalReferralRewardsInDollars
  );

  const pendingReferralRewardsInDollars = useSelector(
    (state) => state.totalReferralRewards.pendingReferralRewardsInDollars
  );

  // const { referrer, isReferrerLoaded } = useReferrer();
  const {
    referralLink,
    copied,
    error,
    handleCopy,
    loading: referralLoading,
  } = useReferralLink(address, isConnected);
  const totalNetworkReward = useSelector(
    (state) => state.modal3.totalNetworkReward
  );

  const networkOfCurrency = selectedCoin
    ? getChainIdFromSelectedCoin(selectedCoin)
    : null;
  const networkRewards =
    networkOfCurrency && allRewards ? allRewards[networkOfCurrency] : null;
  const hasNoRewards =
    !networkRewards ||
    Object.values(networkRewards).every(
      (currency) => Number(currency.amount) === 0
    );

  // useEffect(() => {
  //   if (referralLink) {
  //     setLoading(false);
  //   }
  // }, [referralLink]);

  const amounts = useSelector((state) => state.modal3.amounts);
  const { topUsers } = useFetchTopUsersByRewards();
  // useFetchReferralCount(address);
  // useFetchNovaPoints(address);
  const dispatch = useDispatch();
  const formatReferralLinkShort = (url) => {
    try {
      const newUrl = new URL(url);
      const refParam = new URLSearchParams(newUrl.search).get("ref");
      if (!refParam) return newUrl.hostname;

      const uniqueRefPart = refParam.split("-")[1];
      return `${newUrl.hostname}...${uniqueRefPart}`;
    } catch (e) {
      console.error("Error formatting URL:", e);
      return "";
    }
  };

  const formatReferralLinkMedium = (url) => {
    try {
      const newUrl = new URL(url);
      const refParam = new URLSearchParams(newUrl.search).get("ref");
      if (!refParam) return newUrl.hostname;

      return `${newUrl.hostname}/?ref=${refParam}`;
    } catch (e) {
      console.error("Error formatting URL:", e);
      return "";
    }
  };

  const displayShortLink = referralLink
    ? formatReferralLinkShort(referralLink)
    : "";
  const displayMediumLink = referralLink
    ? formatReferralLinkMedium(referralLink)
    : "";

  let toggleDrop = () => {
    setdrop(!drop);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdrop(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const formatRewardData = (chainId, rewards, coinName) => {
    const coinData = allRewards[chainId][coinName];

    return {
      amount: formatValue(coinData.amount, 4),
      usdValue: parseFloat(coinData.usdValue).toFixed(2),
    };
  };

  const coinsMapping = {
    eth: { chainId: 1, coinName: "ETH" },
    bnb: { chainId: 56, coinName: "BNB" },
    plg: { chainId: 137, coinName: "POL" },
    avax: { chainId: 43114, coinName: "AVAX" },
    arbi: { chainId: 42161, coinName: "ARB" },
    opt: { chainId: 10, coinName: "OP" },
    base: { chainId: 8453, coinName: "ETH" },
    zksync: { chainId: 324, coinName: "ZK" },
  };

  useEffect(() => {
    if (!selectedCoin) return;

    const { chainId, coinName } = coinsMapping[selectedCoin] || {};
    if (!chainId || !coinName) return;

    const totalNetworkReward = formatTotalNetworkRewardInUSD(chainId);
    const coinReward = formatRewardData(chainId, allRewards, coinName);
    const usdtReward = formatRewardData(chainId, allRewards, "USDT");
    const usdcReward = formatRewardData(chainId, allRewards, "USDC");
    const daiReward = formatRewardData(chainId, allRewards, "DAI");

    const amounts = {
      main: coinReward,
      usdt: usdtReward,
      usdc: usdcReward,
      dai: daiReward,
    };

    dispatch(
      setDataForModal3({ network: chainId, totalNetworkReward, amounts })
    );
  }, [selectedCoin, allRewards, dispatch]);

  let selectTab = (e) => {
    setdrop(false);

    setisTab(true);
    setselectedCoin(e);
    switch (e) {
      case "eth":
        setselectedCoinTabName("Ethereum");
        setselectedCoinImg(Eth);
        break;
      case "bnb":
        setselectedCoinTabName("BNB Chain");
        setselectedCoinImg(Bsc);
        break;
      case "plg":
        setselectedCoinTabName("Polygon");
        setselectedCoinImg(Plg);
        break;
      case "arbi":
        setselectedCoinTabName("Arbitrum One");
        setselectedCoinImg(Arbi);
        break;
      case "avax":
        setselectedCoinTabName("Avalanche");
        setselectedCoinImg(Avax);
        break;
      case "base":
        setselectedCoinTabName("Base");
        setselectedCoinImg(Base);
        break;
      case "opt":
        setselectedCoinTabName("Optimism");
        setselectedCoinImg(Opt);
        break;
      case "zksync":
        setselectedCoinTabName("ZKsync Era");
        setselectedCoinImg(zkSync);
        break;
      case "blast":
        setselectedCoinTabName("Blast");
        setselectedCoinImg(Blast);
        break;
      default:
        setselectedCoinImg("");
        break;
    }
  };

  const formatTotalNetworkRewardInUSD = (chainId) => {
    if (allRewards && allRewards[chainId]) {
      const rewardInUsd = Object.values(allRewards[chainId]).reduce(
        (acc, { usdValue }) => acc + parseFloat(usdValue),
        0
      );
      return `$${rewardInUsd.toFixed(2)}`;
    }
    return "$0";
  };


  const handleClaim = async () => {
    // trackButtonClick("Claim Rewards Button Clicked");
    try {
      // await claimRewards(selectedTokens);
      await claimRewards(getChainIdFromSelectedCoin(selectedCoin), {
        totalNetworkReward,
        amounts,
      });
    } catch (error) {
      // Check error code and message to handle various wallet rejections
      if (
        error.code === 4001 || // MetaMask, WalletConnect standard rejection code
        (error.code === undefined && error.message.includes("User rejected")) || // Some WalletConnect wallets do not use error code
        error.message.includes("rejected") || // General "rejected" keyword in the message
        error.message.includes("declined") || // General "declined" keyword in the message
        error.message.includes("cancelled") // Some wallets use "cancelled" as rejection
      ) {
        dispatch(
          setModal5({
            isOpen: true,
            data: { error: "Transaction was declined by the user." },
          })
        );
        console.log("REJECTED IN THE WALLET, IT WWOOOORRRRKKKSS");
      } else if (
        (error.code === -32000 &&
          (error.message.includes("intrinsic gas too low") ||
            error.message.includes("gas required exceeds allowance") ||
            error.message.includes("out of gas") ||
            error.message.includes("exceeds gas limit"))) ||
        error.message.includes("gas too low") // Additional safety check for specific error messages
      ) {
        dispatch(setModal2({ isOpen: true, data: { error: error.message } }));
      } else {
        dispatch(setModal6({ isOpen: true, data: { error: error.message } }));
      }
    }
  };

  const toggleError = () => {
    dispatch(setModal2({ ...modal2, isOpen: !modal2.isOpen }));
  };
  const toggleUniversalError = () => {
    dispatch(setModal6({ ...modal6, isOpen: !modal6.isOpen }));
  };
  const toggleDeclined = () => {
    dispatch(setModal5({ ...modal5, isOpen: !modal5.isOpen }));
  };

  const toggleSuccess = () => {
    dispatch(setModal3({ ...modal3, isOpen: !modal3.isOpen }));
  };

  let explorerUrl, txnHash;
  if (modal3.data) {
    const networkExplorer = networkMapping[modal3.data.network];

    if (networkExplorer) {
      explorerUrl = networkExplorer.explorer;
    }

    txnHash = modal3.data.transactionHash;
  }

  if (!isConnected) {
    return (
      <div className="topmara now-flex grid grid-cols-1 desktop:grid-cols-2 gap-[48px] tablet:gap-[80px] mt-[20px] laptop:mt-[64px] text-white pb-[48px] laptop:pb-[180px]"></div>
    );
  }
  return (
    <>
      <div className="topmara now-flex grid grid-cols-1 desktop:grid-cols-2 gap-[48px] tablet:gap-[80px] mt-[20px] laptop:mt-[64px] text-white pb-[48px] laptop:pb-[180px]">
        <div>
          <div className="relative">
            <h1 className="text-[32px] laptop:text-[42px] mb-[12px] laptop:mb-[16px] leading-[120%] font-[500] relative z-[2]">
              {t("referralPage.rank.claimReward")}
            </h1>
          </div>
          <p className="text-14px laptop:text-[16px] text-[#D6D6D6] mb-[20px] laptop:mb-[32px] leading-[150%]">
            {t("referralPage.rank.rewardDescription")}
          </p>
          {false ? (
            <RewardsShimmer />
          ) : (
            <div
              className="laptop:p-[24px] tablet:backdrop-blur-[12px] mt-[20px] laptop:mt-[32px]"
              style={{
                background: "hsla(0,0%,100%,.1)",
                borderRadius: "10px",
              }}
            >
              <div
                style={{ marginBottom: "8px" }}
                className="grid grid-cols-1 tablet:grid-cols-[1fr_1fr] gap-[8px] laptop:gap-[12px] mb-[20px] laptop:mb-[24px]"
              >
                {totalReferralRewardsInDollars !== null ? (
                  <div
                    className="flex flex-col py-[12px] px-[16px] laptop:pb-[16px] overflow-hidden"
                    style={{
                      border: "1px solid hsla(0,0%,100%,.1)",
                      borderRadius: "20px",
                    }}
                  >
                    <span className="text-[#9AA4B2] tablet:text-white text-[16px] laptop:text-[14px] leading-[145%] font-weight-bold">
                      {t("referralPage.rank.totalEarnings")}
                    </span>
                    <span className="text-defaultOrange mt-[8px] laptop:mt-[16px]">
                      {totalReferralRewardsInDollars
                        ? "$" + totalReferralRewardsInDollars.toFixed(2)
                        : "$0"}
                    </span>
                  </div>
                ) : (
                  <div className="shimmer-item">
                    <span className="shimmer shimmer-title" />
                    <span className="shimmer shimmer-text" />
                  </div>
                )}
                {pendingReferralRewardsInDollars !== null ? (
                  <div
                    className="flex flex-col py-[12px] laptop:pb-[16px] px-[16px] overflow-hidden"
                    style={{
                      border: "1px solid hsla(0,0%,100%,.1)",
                      borderRadius: "20px",
                    }}
                  >
                    <span className="text-[#9AA4B2] tablet:text-white text-[16px] laptop:text-[14px] leading-[145%] font-weight-bold">
                      {t("referralPage.rank.pendingRewards")}
                    </span>
                    <div className="flex items-center gap-[8px] laptop:gap-[10px] mt-[8px] laptop:mt-[16px]">
                      <span className="text-defaultOrange">
                        {pendingReferralRewardsInDollars
                          ? "$" + pendingReferralRewardsInDollars.toFixed(2)
                          : "$0"}{" "}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="shimmer-item">
                    <span className="shimmer shimmer-title" />
                    <span className="shimmer shimmer-text" />
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 tablet:grid-cols-[1fr_1fr] gap-[8px] laptop:gap-[12px] mb-[20px] laptop:mb-[24px]">
                {rewards?.SNOVA !== null ? (
                  <div
                    className="flex flex-col py-[12px] px-[16px] laptop:pb-[16px] overflow-hidden"
                    style={{
                      border: "1px solid hsla(0,0%,100%,.1)",
                      borderRadius: "20px",
                    }}
                  >
                    <span className="text-[#9AA4B2] tablet:text-white text-[16px] laptop:text-[14px] leading-[145%] font-weight-bold">
                      {t("referralPage.rank.snovaEarned")}
                    </span>
                    <div className="flex items-center gap-[8px] laptop:gap-[10px] mt-[8px] laptop:mt-[16px]">
                      <span className="flex items-center  text-defaultOrange">
                        {" "}
                        <img src={snovaCoin} className="nova-points" />
                        {rewards.SNOVA
                          ? "+" + formatValue(rewards.SNOVA, 2) + " SNOVA"
                          : "+0 SNOVA"}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="shimmer-item">
                    <span className="shimmer shimmer-title" />
                    <div className="flex items-center gap-[8px] mt-[16px]">
                      <span className="shimmer shimmer-icon" />
                      <span className="shimmer shimmer-text" />
                    </div>
                  </div>
                )}
                {novaPoints !== null ? (
                  <div
                    className="flex flex-col py-[12px] laptop:pb-[16px] px-[16px] overflow-hidden"
                    style={{
                      border: "1px solid hsla(0,0%,100%,.1)",
                      borderRadius: "20px",
                    }}
                  >
                    <span className="text-[#9AA4B2] tablet:text-white text-[16px] laptop:text-[14px] leading-[145%] font-weight-bold">
                      {t("referralPage.rank.pointsEarned")}
                    </span>
                    <div className="flex items-center gap-[8px] laptop:gap-[10px] mt-[8px] laptop:mt-[16px]">
                      <span className="text-defaultOrange flex items-center">
                        <img src={nPoints} className="nova-points" />{" "}
                        {novaPoints
                          ? "+" + formatValue(novaPoints, 2) + " "
                          : "+0 "}
                        {t("homePage.novaBox.novaPoints")}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="shimmer-item">
                    <span className="shimmer shimmer-title" />
                    <div className="flex items-center gap-[8px] mt-[16px]">
                      <span className="shimmer shimmer-icon" />
                      <span className="shimmer shimmer-text" />
                    </div>
                  </div>
                )}
              </div>

              {referralLink && referralCount !== null ? (
                <div className="confo gap-[8px] laptop:gap-[0px] flex-col mobile:flex-row flex mb-[20px] tablet:mb-[42px]">
                  <div
                    className="flex-[0_1_50%] py-[11px] px-[17px] relative"
                    style={{ background: "#0000008c" }}
                  >
                    <div className="inline-flex items-center mb-[4px]">
                      <span className="mr-[6px] text-[#79716B] text-[14px] font-[500] leading-[170%]">
                        {t("referralPage.rank.yourReferralLink")}
                      </span>
                      {copiedLink ? (
                        <div
                          style={{ marginLeft: "0" }}
                          className="_copyBtn_1fjrj_201   "
                        >
                          <img src={done} className="don-ing" alt="Done" />
                        </div>
                      ) : (
                        <div
                          className="cursor-pointer"
                          onClick={() => handleCopy(referralLink)}
                        >
                          <Tooltip text={displayMediumLink}>
                            {" "}
                            <img
                              src={CircleInfo}
                              style={{
                                height: "16px",
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div
                      className="_copyWrapper_1fjrj_1"
                      aria-label={t("referralPage.banner.copiedToClipboard")}
                    >
                      {isConnected && referralLink ? (
                        <>
                          <span className="_copyText_1fjrj_14">
                            {displayShortLink || "Loading..."}
                          </span>
                          {copied ? (
                            <div
                              className="_copyBtn_1fjrj_20"
                              onClick={() => handleCopy(referralLink)}
                            >
                              <img src={done} alt="Copied" />
                            </div>
                          ) : (
                            <div
                              className="_copyBtn_1fjrj_20"
                              onClick={() => handleCopy(referralLink)}
                            >
                              <img src={copy} alt="Copy" />
                            </div>
                          )}
                        </>
                      ) : (
                        <span className="_copyText_1fjrj_14">
                          {error || "No referral link available"}
                        </span>
                      )}
                    </div>
                    <div className="w-[1px] h-[75%] border border-solid border-[#110f1652] absolute right-[0] top-[12px] bottom-[12px]" />
                  </div>
                  <div
                    className="flex flex-[1_0_40%] items-center justify-center flex-col gap-[4px] p-[4px]"
                    style={{ background: "#0000008c" }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span className="text-[#79716B] text-[14px] font-[500] leading-[170%]">
                        {t("referralPage.rank.yourReferees")}
                      </span>
                      <div className="cursor-pointer">
                        <Tooltip text={t("toolTips.investorReferral")}>
                          {" "}
                          <img
                            src={CircleInfo}
                            style={{
                              height: "16px",
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <span className="text-white text-[20px] font-[500] leading-[140%]">
                      {referralCount ? referralCount : "0"}
                    </span>
                  </div>
                </div>
              ) : (
                <div className=" my-shimmer-effect-container shimmer confo gap-[8px] laptop:gap-[0px] flex-col mobile:flex-row flex mb-[20px] tablet:mb-[42px]">
                  <div
                    className="flex-[0_1_50%] py-[11px] px-[17px] relative"
                    style={{ background: "#0000008c" }}
                  >
                    <div className="inline-flex items-center mb-[4px]"></div>
                    <div
                      className="_copyWrapper_1fjrj_1"
                      style={{ opacity: 0 }}
                    ></div>
                    <div className="shimmer-divider" />
                  </div>
                  <div
                    className="flex flex-[1_0_40%] items-center justify-center flex-col gap-[4px] p-[4px]"
                    style={{ background: "#0000008c" }}
                  >
                    <div className="inline-flex items-center"></div>
                  </div>
                </div>
              )}

              <div className="" style={{ position: "relative" }}>
                <div
                  className={`${
                    isBlurred2 ? "blur-none relative" : "relative"
                  }`}
                >
                  {allRewards === undefined ? (
                    <div
                      className={`my-shimmer-effect-container blur-area ${
                        isBlurred2 ? "blur-comp" : ""
                      }`}
                    >
                      <div
                        ref={dropdownRef}
                        className="shimmer relative MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth stswap-select css-xa1gac"
                      >
                        <div className="css-qiwgdb"></div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`blur-area ${isBlurred2 ? "blur-comp" : ""}`}
                    >
                      <div
                        ref={dropdownRef}
                        className="relative MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth stswap-select css-xa1gac"
                      >
                        <div
                          tabIndex={0}
                          role="combobox"
                          onClick={toggleDrop}
                          aria-controls=":r2:"
                          aria-expanded="false"
                          aria-haspopup="listbox"
                          aria-label="Without label"
                          className="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input css-qiwgdb"
                        >
                          {isTab && allRewards ? (
                            <div className="flex items-center">
                              <div className="w-[28px] h-[28px] relative mr-[28px]">
                                <img src={selectedCoinImg} alt="selectedCoin" />
                              </div>
                              <div className="text-[#EEF2F6] mr-[40px]">
                                {selectedCoinTabName}
                              </div>
                              <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                {allRewards
                                  ? formatTotalNetworkRewardInUSD(
                                      getChainIdFromSelectedCoin(selectedCoin)
                                    )
                                  : "$0"}
                              </div>
                            </div>
                          ) : (
                            <em>{t("referralPage.rank.selectNetwork")}</em>
                          )}
                        </div>

                        <input
                          aria-invalid="false"
                          aria-hidden="true"
                          tabIndex={-1}
                          className="MuiSelect-nativeInput css-1k3x8v3"
                        />
                        <svg
                          style={
                            drop
                              ? {
                                  transform: "rotate(180deg)",
                                  transition: "all 0.3s ease",
                                }
                              : {
                                  transform: "rotate(0deg)",
                                  transition: "all 0.3s ease",
                                }
                          }
                          className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-1636szt"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="ArrowDropDownIcon"
                        >
                          <path d="M7 10l5 5 5-5z" />
                        </svg>
                        <fieldset
                          aria-hidden="true"
                          className="MuiOutlinedInput-notchedOutline css-igs3ac"
                        >
                          <legend className="css-ihdtdm">
                            <span className="notranslate">​</span>
                          </legend>
                        </fieldset>
                        {/* mew */}
                        {drop && (
                          <div
                            className="MuiPaper-root absas MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper MuiMenu-paper MuiMenu-paper css-pwxzbm"
                            tabIndex={-1}
                            style={{ boxShadow: "none" }}
                          >
                            <ul
                              className="MuiList-root MuiList-padding MuiMenu-list css-r8u8y9"
                              role="listbox"
                              tabIndex={-1}
                              id=":r2:"
                              style={{ borderTop: "1px solid #79716b" }}
                            >
                              <li
                                className="MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters Mui-selected MuiMenuItem-root MuiMenuItem-gutters Mui-selected stswap-select_placeholder css-1km1ehz"
                                tabIndex={0}
                                role="option"
                                aria-selected="true"
                                data-value
                              >
                                <em>Select Chain</em>
                                <span className="MuiTouchRipple-root css-w0pj6f" />
                              </li>
                              <li
                                className={`hover-tab MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-1km1ehz ${
                                  selectedCoin === "eth" ? "selectedli" : ""
                                }`}
                                tabIndex={-1}
                                role="option"
                                aria-selected="false"
                                data-value={1}
                                onClick={() => selectTab("eth")}
                              >
                                <div className="flex items-center">
                                  <div className="w-[28px] h-[28px] relative mr-[28px]">
                                    <img src={Eth} alt="eth" />
                                  </div>
                                  <div className="text-[#EEF2F6] mr-[40px]">
                                    Ethereum
                                  </div>
                                  <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                    {allRewards
                                      ? formatTotalNetworkRewardInUSD(1)
                                      : "$0"}
                                  </div>
                                </div>
                                <span className="MuiTouchRipple-root css-w0pj6f" />
                              </li>
                              <li
                                className={`hover-tab MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-1km1ehz ${
                                  selectedCoin === "bnb" ? "selectedli" : ""
                                }`}
                                tabIndex={-1}
                                role="option"
                                onClick={() => selectTab("bnb")}
                                aria-selected="false"
                                data-value={56}
                              >
                                <div className="flex items-center">
                                  <div className="w-[28px] h-[28px] relative mr-[28px]">
                                    <img src={Bsc} alt="bnb" />
                                  </div>
                                  <div className="text-[#EEF2F6] mr-[40px]">
                                    BNB Chain
                                  </div>
                                  <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                    {allRewards
                                      ? formatTotalNetworkRewardInUSD(56)
                                      : "$0"}
                                  </div>
                                </div>
                                <span className="MuiTouchRipple-root css-w0pj6f" />
                              </li>
                              <li
                                className={`hover-tab MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-1km1ehz ${
                                  selectedCoin === "plg" ? "selectedli" : ""
                                }`}
                                tabIndex={-1}
                                role="option"
                                onClick={() => selectTab("plg")}
                                aria-selected="false"
                                data-value={137}
                              >
                                <div className="flex items-center">
                                  <div className="w-[28px] h-[28px] relative mr-[28px]">
                                    <img src={Plg} alt="plg" />
                                  </div>
                                  <div className="text-[#EEF2F6] mr-[40px]">
                                    Polygon
                                  </div>
                                  <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                    {allRewards
                                      ? formatTotalNetworkRewardInUSD(137)
                                      : "$0"}
                                  </div>
                                </div>
                                <span className="MuiTouchRipple-root css-w0pj6f" />
                              </li>
                              <li
                                className={`hover-tab MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-1km1ehz ${
                                  selectedCoin === "arbi" ? "selectedli" : ""
                                }`}
                                tabIndex={-1}
                                role="option"
                                aria-selected="false"
                                onClick={() => selectTab("arbi")}
                                data-value={42161}
                              >
                                <div className="flex items-center">
                                  <div className="w-[28px] h-[28px] relative mr-[28px]">
                                    <img src={Arbi} alt="arbi" />
                                  </div>
                                  <div className="text-[#EEF2F6] mr-[40px]">
                                    Arbitrum One
                                  </div>
                                  <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                    {allRewards
                                      ? formatTotalNetworkRewardInUSD(42161)
                                      : "$0"}
                                  </div>
                                </div>
                                <span className="MuiTouchRipple-root css-w0pj6f" />
                              </li>
                              <li
                                className={`hover-tab MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-1km1ehz ${
                                  selectedCoin === "avax" ? "selectedli" : ""
                                }`}
                                tabIndex={-1}
                                role="option"
                                onClick={() => selectTab("avax")}
                                aria-selected="false"
                                data-value={43114}
                              >
                                <div className="flex items-center">
                                  <div className="w-[28px] h-[28px] relative mr-[28px]">
                                    <img src={Avax} alt="avax" />
                                  </div>
                                  <div className="text-[#EEF2F6] mr-[40px]">
                                    Avalanche
                                  </div>
                                  <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                    {allRewards
                                      ? formatTotalNetworkRewardInUSD(43114)
                                      : "$0"}
                                  </div>
                                </div>
                                <span className="MuiTouchRipple-root css-w0pj6f" />
                              </li>
                              <li
                                className={`hover-tab MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-1km1ehz ${
                                  selectedCoin === "base" ? "selectedli" : ""
                                }`}
                                tabIndex={-1}
                                onClick={() => selectTab("base")}
                                role="option"
                                aria-selected="false"
                                data-value={8453}
                              >
                                <div className="flex items-center">
                                  <div className="w-[28px] h-[28px] relative mr-[28px]">
                                    <img src={Base} alt="base" />
                                  </div>
                                  <div className="text-[#EEF2F6] mr-[40px]">
                                    Base
                                  </div>
                                  <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                    {allRewards
                                      ? formatTotalNetworkRewardInUSD(8453)
                                      : "$0"}
                                  </div>
                                </div>
                                <span className="MuiTouchRipple-root css-w0pj6f" />
                              </li>
                              <li
                                className={`hover-tab MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-1km1ehz ${
                                  selectedCoin === "opt" ? "selectedli" : ""
                                }`}
                                tabIndex={-1}
                                role="option"
                                onClick={() => selectTab("opt")}
                                aria-selected="false"
                                data-value={10}
                              >
                                <div className="flex items-center">
                                  <div className="w-[28px] h-[28px] relative mr-[28px]">
                                    <img src={Opt} alt="opt" />
                                  </div>
                                  <div className="text-[#EEF2F6] mr-[40px]">
                                    Optimism
                                  </div>
                                  <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                    {allRewards
                                      ? formatTotalNetworkRewardInUSD(10)
                                      : "$0"}
                                  </div>
                                </div>
                                <span className="MuiTouchRipple-root css-w0pj6f" />
                              </li>
                              <li
                                className={`hover-tab MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-1km1ehz ${
                                  selectedCoin === "zksync" ? "selectedli" : ""
                                }`}
                                tabIndex={-1}
                                role="option"
                                onClick={() => selectTab("zksync")}
                                aria-selected="false"
                                data-value={10}
                              >
                                <div className="flex items-center">
                                  <div className="w-[28px] h-[28px] relative mr-[28px]">
                                    <img src={zkSync} alt="zksync" />
                                  </div>
                                  <div className="text-[#EEF2F6] mr-[40px]">
                                    ZKsync Era
                                  </div>
                                  <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                    {allRewards
                                      ? formatTotalNetworkRewardInUSD(324)
                                      : "$0"}
                                  </div>
                                </div>
                                <span className="MuiTouchRipple-root css-w0pj6f" />
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      {isTab && selectedCoin === "eth" ? (
                        <div
                          className={`${
                            isBlurred2 ? "blur-none relative" : "relative"
                          }`}
                        >
                          <div className="responsive-grid mt-[12px] laptop:mt-[24px]">
                            <div
                              className="flex flex-col py-[8px] px-[16px] w-full"
                              style={{ background: "rgba(0, 0, 0, 0.55)" }}
                            >
                              <div
                                className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                                style={{ background: "rgba(17, 15, 22, 0.42)" }}
                              >
                                <div className="w-[20px] h-[20px]">
                                  <img
                                    src={selectedCoinImg}
                                    alt="selectedCoin"
                                  />
                                </div>
                                <p className="text-[14px] uppercase">
                                  {selectedCoin}
                                </p>
                              </div>
                              <div className="flex items-center w-full">
                                <div className="flex items-center gap-[16px]">
                                  <div className="w-[28px] h-[28px]">
                                    <img src={EthDiamond} alt="eth" />
                                  </div>
                                  <div className="text-[16px]">
                                    <p>ETH</p>
                                  </div>
                                </div>
                                <div className="ml-auto">
                                  <p className="text-right text-[14px]">
                                    {" "}
                                    {allRewards
                                      ? formatValue(
                                          allRewards[
                                            getChainIdFromSelectedCoin(
                                              selectedCoin
                                            )
                                          ].ETH.amount,
                                          4
                                        )
                                      : "0"}
                                  </p>
                                  <p className="text-right text-[#79716B] text-[12px]">
                                    {allRewards
                                      ? "$" +
                                        parseFloat(
                                          allRewards[
                                            getChainIdFromSelectedCoin(
                                              selectedCoin
                                            )
                                          ].ETH.usdValue
                                        ).toFixed(2)
                                      : "$0"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="flex flex-col py-[8px] px-[16px] w-full"
                              style={{ background: "rgba(0, 0, 0, 0.55)" }}
                            >
                              <div
                                className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                                style={{ background: "rgba(17, 15, 22, 0.42)" }}
                              >
                                <div className="w-[20px] h-[20px]">
                                  <img
                                    src={selectedCoinImg}
                                    alt="selectedCoin"
                                  />
                                </div>
                                <p className="text-[14px] uppercase">
                                  {selectedCoin}
                                </p>
                              </div>
                              <div className="flex items-center w-full">
                                <div className="flex items-center gap-[16px]">
                                  <div className="w-[28px] h-[28px]">
                                    <img src={Tether} alt="USDT" />
                                  </div>
                                  <div className="text-[16px]">
                                    <p>USDT</p>
                                  </div>
                                </div>
                                <div className="ml-auto">
                                  <p className="text-right text-[14px]">
                                    {" "}
                                    {allRewards
                                      ? formatValue(
                                          allRewards[
                                            getChainIdFromSelectedCoin(
                                              selectedCoin
                                            )
                                          ].USDT.amount,
                                          4
                                        )
                                      : "0"}
                                  </p>
                                  <p className="text-right text-[#79716B] text-[12px]">
                                    {allRewards
                                      ? "$" +
                                        parseFloat(
                                          allRewards[
                                            getChainIdFromSelectedCoin(
                                              selectedCoin
                                            )
                                          ].USDT.usdValue
                                        ).toFixed(2)
                                      : "$0"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="flex flex-col py-[8px] px-[16px] w-full"
                              style={{ background: "rgba(0, 0, 0, 0.55)" }}
                            >
                              <div
                                className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                                style={{ background: "rgba(17, 15, 22, 0.42)" }}
                              >
                                <div className="w-[20px] h-[20px]">
                                  <img
                                    src={selectedCoinImg}
                                    alt="selectedCOin"
                                  />
                                </div>
                                <p className="text-[14px] uppercase">
                                  {selectedCoin}
                                </p>
                              </div>
                              <div className="flex items-center w-full">
                                <div className="flex items-center gap-[16px]">
                                  <div className="w-[28px] h-[28px]">
                                    <img src={UsdC} alt="USDC" />
                                  </div>
                                  <div className="text-[16px]">
                                    <p>USDC</p>
                                  </div>
                                </div>
                                <div className="ml-auto">
                                  <p className="text-right text-[14px]">
                                    {" "}
                                    {allRewards
                                      ? formatValue(
                                          allRewards[
                                            getChainIdFromSelectedCoin(
                                              selectedCoin
                                            )
                                          ].USDC.amount,
                                          4
                                        )
                                      : "0"}
                                  </p>
                                  <p className="text-right text-[#79716B] text-[12px]">
                                    {allRewards
                                      ? "$" +
                                        parseFloat(
                                          allRewards[
                                            getChainIdFromSelectedCoin(
                                              selectedCoin
                                            )
                                          ].USDC.usdValue
                                        ).toFixed(2)
                                      : "$0"}
                                  </p>
                                </div>
                              </div>
                            </div>{" "}
                            <div
                              className="flex flex-col py-[8px] px-[16px] w-full"
                              style={{ background: "rgba(0, 0, 0, 0.55)" }}
                            >
                              <div
                                className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                                style={{ background: "rgba(17, 15, 22, 0.42)" }}
                              >
                                <div className="w-[20px] h-[20px]">
                                  <img src={selectedCoinImg} alt="eth" />
                                </div>
                                <p className="text-[14px] uppercase">ETH</p>
                              </div>
                              <div className="flex items-center w-full">
                                <div className="flex items-center gap-[16px]">
                                  <div className="w-[28px] h-[28px]">
                                    <img src={Dai} alt="dai" />
                                  </div>
                                  <div className="text-[16px]">
                                    <p>DAI</p>
                                  </div>
                                </div>
                                <div className="ml-auto">
                                  <p className="text-right text-[14px]">
                                    {" "}
                                    {allRewards
                                      ? formatValue(
                                          allRewards[
                                            getChainIdFromSelectedCoin(
                                              selectedCoin
                                            )
                                          ].DAI.amount,
                                          4
                                        )
                                      : "0"}
                                  </p>
                                  <p className="text-right text-[#79716B] text-[12px]">
                                    {allRewards
                                      ? "$" +
                                        parseFloat(
                                          allRewards[
                                            getChainIdFromSelectedCoin(
                                              selectedCoin
                                            )
                                          ].DAI.usdValue
                                        ).toFixed(2)
                                      : "$0"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedCoin === "bnb" ? (
                        <div className="responsive-grid mt-[12px] laptop:mt-[24px]">
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={selectedCoinImg} alt="bnb" />
                              </div>
                              <p className="text-[14px] uppercase">BNB</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAFMklEQVR4XtVbvW4TQRhMSWwipYYngCeACsp0pEsJFZTp8gapaXgD6F0gETsIWaRIARIopAAJJAcJCgq4s5M4/zE3l6y1nv253fXe2R5plOR2787f7PfN7u05c3MVIGksLiYbNx92W/XnaavWSFv1TrpZT7Kfg5xXv3fQhj7oi3P4OjOD7ub8ahZUexjguGzW271W/THfZ+qAkYsauMJaI3mzsMz3nThG0rky1jr8OSpHPuJ57fKHq4q1zkRKI9moP5nMqJtYoRBwZ/UDTAdLnzmma9RNLMkb8rRXbjadjF4OfINZIccRhFkaeebYmZCWNMV1390enOy9GAgc7j5T+kRkwnE5oUy3vzxLh8ELXPR/Kv1i0Xt2KCPte1t3OWYtyhLCqxzSyKl//GNdO+omQIQSysKtFGKO/tm/LY5tBEff1wenf17z4REcZ17B1w0llu4cr4I0wuizyelw9ndr2P+iv8fNI4iXDQWLpDRC8KApoMuzZND/uqb0Fzz59ZJPGSJiJphLQdM5mP3dpxxDlhm3lH7M3vs7fNpg/8OS0m8cctw5ktbCMnd0YT9LT8Dk3giIj4EHH5eGAeqEwXm64ygvAcws3O5CrRdc79UpnW1kkzvv7Th9KPYHV9c/+ramzCZBpdGst0eC93V+Dpyxv31POQfEqNrq3OYPmDFsOMxKjs+xsducXx0K4LuPZxKgyOQEdSbpMpI28XwFGMkCpbGALMB5d0c76vAHpCwWQrp6xqgeflpRju9v38/vgTWCrqRgsFwK3gK0JDPkhiKyAEdZgNxHNjnAZJJM2eQEdCJwFgULkLT9H3psAmCki6ALiAXTQe4fQ4D8Iel6Z1dptPEoq3MZsgAuDz3IBlxDnMOCmiB/BlkA/G6abm3Mp8Or11VqowsPPq/kN/cVIBTyvXFfmKLOW1yJ2IPmfyZMSfxelQCnWfD8ObzZrDWC1v+oVz4mWJUAMmGcOl8pZvZwFLLVjZrFqo+Pgy4mGAq+lyCmxBATTPFgpDlYSNm0TNOb7qEmBFhc8bVBni4DBRiMLQCgWweAwiRDgdWhyeSQgTLCBQgsARkmAQSLngEYLsGwsC7naJiXQEfTYKVNAJiR6ckOu0BFwPKYzxM7TPKxOALABAOmwSIBBE5+vxpxZ90mCUO+D2Yb+V5yWxQBMA2GLIRcBQBkk/QVgCG3xRAgXwiFLIV9BABE27QJsL9540H0h6FZEmD4xogbimgTAMTUJe/giOOhAug2RWMIEG0/AGbHfUAx9Ym/fQXAIkg3KyDL4m6IRNoSY9dn+grAxKwAU9XBWwB5Syz/YiN3sNAkgIBp9TaOANgms8FXAOVlqW8WgEUwZQPXLyC/KpOJrXAbcC0+p5CY/xkh06HLEtfkD7JJ6kwOPO9+ka6kQucPLuy9rT3i+HNwRx/a0pv7utIEk2Cu5LiHCHkwkmnKBtGOOjaVBcgbLQyku2+tq6zgDTGyQa5z+fhVIKOvwuQvUMjXEYA/hGx6qiwIHgjxAhMFxN9cJsgG8XJVgM8PMjkDFec3YdxSMJEF0IHPiUeH0RfwfVnqykkK4Dz6AiEPSS607RWaFlDj0vtrcgJlloJskjA53cvVOPRIfR3KKgfQ9C2QWPROexP4wrNCjmMslJkJsRlt5BlphEVS6dy0fA0uBsqaHWIw2O19cV0OHf4AE2M26qWlvQ2TF6LWyXd2J410EiKUXeshwEZDyJsmZzbr7akY8SJc/2NlWwkglFng2Lfk+8wM4M4YuW7T4d/nsz7oW5Wj/wdXGukMTSgLegAAAABJRU5ErkJggg=="
                                    alt="bnb"
                                  />
                                </div>
                                <div className="text-[16px]">
                                  <p>BNB</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].BNB.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].BNB.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={selectedCoinImg} alt="bnb" />
                              </div>
                              <p className="text-[14px] uppercase">BNB</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Tether} alt="USDT" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDT</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={selectedCoinImg} alt="bnb" />
                              </div>
                              <p className="text-[14px] uppercase">BNB</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={UsdC} alt="USDC" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDC</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={selectedCoinImg} alt="bnb" />
                              </div>
                              <p className="text-[14px] uppercase">BNB</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Dai} alt="dai" />
                                </div>
                                <div className="text-[16px]">
                                  <p>DAI</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedCoin === "plg" ? (
                        <div className="responsive-grid mt-[12px] laptop:mt-[24px]">
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Plg} alt="plg" />
                              </div>
                              <p className="text-[14px] uppercase">PLG</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Matic} alt="pol" />
                                </div>
                                <div className="text-[16px]">
                                  <p>POL</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].POL.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].POL.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Plg} alt="plg" />
                              </div>
                              <p className="text-[14px] uppercase">PLG</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Tether} alt="USDT" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDT</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Plg} alt="plg" />
                              </div>
                              <p className="text-[14px] uppercase">PLG</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={UsdC} alt="USDC" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDC</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Plg} alt="plg" />
                              </div>
                              <p className="text-[14px] uppercase">PLG</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Dai} alt="dai" />
                                </div>
                                <div className="text-[16px]">
                                  <p>DAI</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedCoin === "arbi" ? (
                        <div className="responsive-grid mt-[12px] laptop:mt-[24px]">
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Arbi} alt="arbi" />
                              </div>
                              <p className="text-[14px] uppercase">ARB</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={ArbSymbol} alt="arb" />
                                </div>
                                <div className="text-[16px]">
                                  <p>ARB</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].ARB.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].ARB.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Arbi} alt="arbi" />
                              </div>
                              <p className="text-[14px] uppercase">ARB</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Tether} alt="USDT" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDT</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Arbi} alt="arbi" />
                              </div>
                              <p className="text-[14px] uppercase">ARB</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={UsdC} alt="USDC" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDC</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Arbi} alt="arbi" />
                              </div>
                              <p className="text-[14px] uppercase">ARB</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Dai} alt="dai" />
                                </div>
                                <div className="text-[16px]">
                                  <p>DAI</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedCoin === "avax" ? (
                        <div className="responsive-grid mt-[12px] laptop:mt-[24px]">
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Avax} alt="avax" />
                              </div>
                              <p className="text-[14px] uppercase">AVAX</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACdElEQVR4Xu2bPU7DQBBGuRlrcwTugejgLCBElxZBAQeABiREDzX5awEJIpSAI2Elz9jZ3Zm1dw1Peo298+3MVImibG21xNTkB+MsX9hYnGV9cnAoqcyPFjauLe+LgonJT9locE1+xj46odJYy7Kf1mAjXcv+gsLLY5F9qsMLY5V9q8BLYpf9i2B4KnIOLxiampzHCYalKueyhkHa/sDn2nIuKxiiLeF7bTlfIywOIeH7EHLOWliobR08py3n/BUWhbAJntWW864xzrIHFmi7CZ7XdmTMJecu4WFtbWGdtpy7hAe1tYV12nLuJTykrSus15bz/+0F8KW2vjBH2/8FtLEAX6a7u5UsbYMvYPb0xLmsYVYIl8MPTb7PF1r6Mnt8rGSFcFT8AsWHWkpgVkijWwBzQhtkARKYFdqoFsCcNlRfgARmrfp+e7eYf3xUnktVXcDrYMCZrGHWqi9Hx9ZnXVVdgARmNeXyvUS1BUhglk0uz/na+QKYQ+vgOV9VFiCBWS65PO9j8VH4kA9d9YU5dBOfz8NKjZvZnvjLkARm+eSyzkXxt8G38wv2Yw2zVnWF9baKFyCBWZJc1tsqWoAEZmnkMsfGcgE+S/CFOdQX5mxybfg+LKCAWU1y/iU81OT79Q3vb4T1dfrCnCY5dwkP9lXOXTIx2RUP983Rdn7PuddgQd/kvL/Cor7IOWthYV/knI2wOHU5nxUMSVXOZQ2DUpVzOcGw1OQ8XjA0FTmHCIbHLvtXgZfEKvtWhxfGIvsMCi/vWvbXGmykbdlPJxR/YmRjwd3eOWEfUVBpVFneFy3fzc7ZvMA585NjZIz13+eLs6wPxRfcM7S6mfjOrgAAAABJRU5ErkJggg=="
                                    alt="avax"
                                  />
                                </div>
                                <div className="text-[16px]">
                                  <p>AVAX</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].AVAX.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].AVAX.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Avax} alt="avax" />
                              </div>
                              <p className="text-[14px] uppercase">AVAX</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Tether} alt="USDT" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDT</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Avax} alt="avax" />
                              </div>
                              <p className="text-[14px] uppercase">AVAX</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={UsdC} alt="USDC" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDC</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={selectedCoinImg} alt="avax" />
                              </div>
                              <p className="text-[14px] uppercase">AVAX</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Dai} alt="dai" />
                                </div>
                                <div className="text-[16px]">
                                  <p>DAI</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedCoin === "base" ? (
                        <div className="responsive-grid mt-[12px] laptop:mt-[24px]">
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Base} alt="base" />
                              </div>
                              <p className="text-[14px] uppercase">BASE</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={EthDiamond} alt="eth" />
                                </div>
                                <div className="text-[16px]">
                                  <p>ETH</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].ETH.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].ETH.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={selectedCoinImg} alt="base" />
                              </div>
                              <p className="text-[14px] uppercase">BASE</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Tether} alt="USDT" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDT</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Base} alt="base" />
                              </div>
                              <p className="text-[14px] uppercase">BASE</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={UsdC} alt="USDC" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDC</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Base} alt="plg" />
                              </div>
                              <p className="text-[14px] uppercase">BASE</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Dai} alt="dai" />
                                </div>
                                <div className="text-[16px]">
                                  <p>DAI</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedCoin === "opt" ? (
                        <div className="responsive-grid mt-[12px] laptop:mt-[24px]">
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Opt} alt="opt" />
                              </div>
                              <p className="text-[14px] uppercase">OP</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Op} alt="optimism" />
                                </div>
                                <div className="text-[16px]">
                                  <p>OP</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].OP.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].OP.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Opt} alt="opt" />
                              </div>
                              <p className="text-[14px] uppercase">OP</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Tether} alt="USDT" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDT</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={Opt} alt="opt" />
                              </div>
                              <p className="text-[14px] uppercase">OP</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={UsdC} alt="USDC" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDC</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={selectedCoinImg} alt="opt" />
                              </div>
                              <p className="text-[14px] uppercase">OP</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Dai} alt="dai" />
                                </div>
                                <div className="text-[16px]">
                                  <p>DAI</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedCoin === "zksync" ? (
                        <div className="responsive-grid mt-[12px] laptop:mt-[24px]">
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={zkSync} alt="zksync" />
                              </div>
                              <p className="text-[14px] uppercase">ZKSYNC</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Zk} alt="zk" />
                                </div>
                                <div className="text-[16px]">
                                  <p>ZK</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].ZK.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].ZK.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={zkSync} alt="zksync" />
                              </div>
                              <p className="text-[14px] uppercase">ZKSYNC</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Tether} alt="USDT" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDT</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDT.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={zkSync} alt="arbi" />
                              </div>
                              <p className="text-[14px] uppercase">ZKSYNC</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={UsdC} alt="USDC" />
                                </div>
                                <div className="text-[16px]">
                                  <p>USDC</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].USDC.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex flex-col py-[8px] px-[16px] w-full"
                            style={{ background: "rgba(0, 0, 0, 0.55)" }}
                          >
                            <div
                              className="flex gap-[16px] items-center mb-[12px] py-[6px] px-[8px] self-baseline hidden tablet:flex tabIndex referral-heading"
                              style={{ background: "rgba(17, 15, 22, 0.42)" }}
                            >
                              <div className="w-[20px] h-[20px]">
                                <img src={selectedCoinImg} alt="zksync" />
                              </div>
                              <p className="text-[14px] uppercase">ZKSYNC</p>
                            </div>
                            <div className="flex items-center w-full">
                              <div className="flex items-center gap-[16px]">
                                <div className="w-[28px] h-[28px]">
                                  <img src={Dai} alt="dai" />
                                </div>
                                <div className="text-[16px]">
                                  <p>DAI</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-right text-[14px]">
                                  {" "}
                                  {allRewards
                                    ? formatValue(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.amount,
                                        4
                                      )
                                    : "0"}
                                </p>
                                <p className="text-right text-[#79716B] text-[12px]">
                                  {allRewards
                                    ? "$" +
                                      parseFloat(
                                        allRewards[
                                          getChainIdFromSelectedCoin(
                                            selectedCoin
                                          )
                                        ].DAI.usdValue
                                      ).toFixed(2)
                                    : "$0"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  {isBlurred2 && (
                    <img
                      src={LoaderIcon}
                      alt="Overlay"
                      className="overlay-icon"
                    />
                  )}
                </div>
                <div
                  className="grid mt-[12px] laptop:mt-[24px]"
                  style={{ gridTemplateColumns: "1fr 1fr", gap: 12 }}
                />
                {allRewards === undefined ? (
                  <div className="my-shimmer-effect-container shimmer no-dis">
                    <button
                      type="button"
                      id="claimRewardsButton"
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                      style={{
                        width: "100%",
                        cursor:
                          isBlurred2 ||
                          !(
                            selectedCoin &&
                            getChainIdFromSelectedCoin(selectedCoin)
                          )
                            ? "not-allowed"
                            : "pointer",
                        backgroundColor: "#fc6432",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "6px 6px #C04000",
                        opacity:
                          isBlurred2 ||
                          !(
                            selectedCoin &&
                            getChainIdFromSelectedCoin(selectedCoin)
                          )
                            ? 0.5
                            : 1,
                      }}
                      disabled={true}
                    >
                      <span className="btn-buy-token"> </span>
                    </button>
                  </div>
                ) : (
                  <div className="no-dis">
                    <button
                      type="button"
                      id="claimRewardsButton"
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                      style={{
                        width: "100%",
                        cursor:
                          isBlurred2 ||
                          !(
                            selectedCoin &&
                            getChainIdFromSelectedCoin(selectedCoin)
                          ) ||
                          hasNoRewards // Disable if no rewards
                            ? "not-allowed"
                            : "pointer",
                        backgroundColor: "#fc6432",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "6px 6px #C04000",
                        opacity:
                          isBlurred2 ||
                          !(
                            selectedCoin &&
                            getChainIdFromSelectedCoin(selectedCoin)
                          ) ||
                          hasNoRewards // Reduce opacity if no rewards
                            ? 0.5
                            : 1,
                      }}
                      onClick={handleClaim}
                      disabled={
                        isBlurred2 ||
                        !(
                          selectedCoin &&
                          getChainIdFromSelectedCoin(selectedCoin)
                        ) ||
                        hasNoRewards // Disable if no rewards
                      }
                    >
                      <span className="btn-buy-token">
                        {isBlurred2
                          ? t("referralPage.rank.claiming")
                          : hasNoRewards
                          ? t("referralPage.rank.noRewards")
                          : t("referralPage.rank.claimReward")}{" "}
                      </span>
                    </button>
                  </div>
                )}
              </div>

              <br />
              {/* <ReferralRewardsComponent /> */}
              {/* <button onClick={handleBlur}>Blur for 10 seconds</button> */}
              {/* <AllReferralRewardsComponent /> */}
            </div>
          )}
        </div>
        <div>
          <h2 className="text-[28px] laptop:text-[32px] mt-[28px] mb-[16px] laptop:mb-[24px] leading-[120%] font-[500]">
            {t("referralPage.rank.topEarnersTitle")}
          </h2>{" "}
          <div
            className="flex-col tablet:flex-column mt-[42px] tablet:mt-[0] flex justify-center gap-[20px] items-center px-[12px] py-[6px]	 border border-solid border-[#57534E]"
            style={{ color: "rgb(21 22 26/ 0.7)" }}
          >
            <div className="flex items-center gap-[10px] justify-between w-full tablet:w-auto">
              <span className="uppercase text-[#CDD5DF] text-[14px] laptop:text-[14px] leading-[120%] font-[500]">
                {t("referralPage.rank.yourRank")}
              </span>
              <div className="flex items-center gap-[8px]">
                <span
                  className="text-[32px] leading-[120%] font-[500]"
                  style={{ color: "white" }}
                >
                  {userRankingByRewards
                    ? formatRanking(userRankingByRewards)
                    : "#"}
                </span>
              </div>
            </div>
          </div>
          <ul className="border border-solid border-[#57534E] bg-[#171412]">
            {topUsers === undefined ? (
              <UserListShimmerPlaceholder />
            ) : (
              topUsers.map((user, index) => (
                <li
                  key={user.wallet_address}
                  className="flex flex-wrap gap-[24px] items-center p-[20px] border-solid border-b border-[#57534E]"
                >
                  <span
                    className="flex flex-[0_0_34px] text-[#D6D6D6] text-[18px] font-[500] leading-[120%]"
                    style={{ color: "rgb(214, 214, 214)" }}
                  >
                    {user.rewards_ranking}
                  </span>
                  <div className="flex items-center gap-[8px]">
                    <div className="w-[32px] h-[32px] rounded-full bg-defaultOrange overflow-hidden">
                      <canvas
                        className="identicon"
                        width={30}
                        height={30}
                        style={{ width: 30, height: 30 }}
                      />
                    </div>
                    <span
                      className="text-[16px] font-[500] leading-[120%] uppercase text-[#A9A29D]"
                      style={{ color: "rgb(214, 214, 214)" }}
                    >
                      {user.wallet_address.slice(0, 6)}...
                      {user.wallet_address.slice(-4)}
                    </span>
                  </div>
                  <span
                    className="ml-auto text-[#A9A29D] font-[500] leading-[120%]"
                    style={{ color: "rgb(214, 214, 214)" }}
                  >
                    ${parseFloat(user.amount_in_dollars).toFixed(2)}
                  </span>
                </li>
              ))
            )}
          </ul>
        </div>
      </div>{" "}
      {modal2.isOpen ? (
        <div onClick={toggleError} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal2.isOpen ? (
        <>
          <div
            className="heo"
            style={{
              position: "absolute",
              top: `${scrollPosition}px`,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
              <div className="flex space-between">
                <h2 className="scale-head">
                  <img src={transactionError} className="purchase-summary" />

                  {t("popups.errorModal.transactionError")}
                </h2>
                <div onClick={toggleError} className="cross-ic">
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                  </svg>
                </div>
              </div>
              <h2 id="radix-:R1r6mH1:" className="orange mibj">
                {t("popups.errorModal.transactionErrorDetail")}
              </h2>
              <div className="confirmation-block">
                <img src={purchaseError} className="purchase-success" />
              </div>
              <h3 className="text-center bjpar your-tokens-bg">
                <span className="your-tokens">
                  {t("popups.errorModal.insuficientGas.title")}
                </span>
              </h3>
              <div className="card-stam">
                <div className="card-item-stam">
                  <ol className="list-stam">
                    <li className="list-item-stam">
                      <b>
                        {t(
                          "popups.errorModal.insuficientGas.errorTable.heading1"
                        )}
                      </b>{" "}
                      {t(
                        "popups.errorModal.insuficientGas.errorTable.description1"
                      )}
                    </li>
                    <li className="list-item-stam">
                      <b>
                        {t(
                          "popups.errorModal.insuficientGas.errorTable.heading2"
                        )}
                      </b>{" "}
                      {t(
                        "popups.errorModal.insuficientGas.errorTable.description2"
                      )}
                    </li>
                    <li className="list-item-stam">
                      <b>
                        {t(
                          "popups.errorModal.insuficientGas.errorTable.heading3"
                        )}
                      </b>{" "}
                      {t(
                        "popups.errorModal.insuficientGas.errorTable.description3"
                      )}
                    </li>
                  </ol>
                </div>
              </div>
              <div className="flex main-gb">
                <button
                  onClick={toggleError}
                  className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                  type="button"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(252, 100, 50)",
                    color: "rgb(255, 255, 255)",
                    boxShadow: "rgb(192, 64, 0) 6px 6px",
                  }}
                >
                  {t("popups.errorModal.tryAgain")}
                </button>

                <a
                  href="https://t.me/StacknovaBTC"
                  className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                  target="_blank"
                  bis_skin_checked={1}
                  style={{ background: "transparent" }}
                >
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                    bis_skin_checked={1}
                  />{" "}
                  {t("popups.errorModal.getSupport")}
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                    bis_skin_checked={1}
                  />
                </a>
              </div>
              <div className="text-text-tip text-2xs text-center">
                <i>{t("popups.errorModal.footerNote")}</i>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {modal3.isOpen ? (
        <div onClick={toggleError} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal3.isOpen ? (
        <>
          <div
            className="heo"
            style={{
              position: "absolute",
              top: `${scrollPosition}px`,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
              <div className="flex space-between">
                <h2 className="scale-head">
                  <img src={purchaseSummary} className="purchase-summary" />
                  {t("popups.summaryModal.rewardsSummary")}
                </h2>
                <div onClick={toggleSuccess} className="cross-ic">
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                  </svg>
                </div>
              </div>
              <h2 id="radix-:R1r6mH1:" className="orange mibj">
                {t("popups.summaryModal.wellDone")}
              </h2>
              <div className="confirmation-block">
                <img src={claimSuccess} className="claim-success" />
              </div>
              <h3 className="text-center double-blk bjpar your-tokens-bg">
                <div>
                  <img src={Usd} className="purchased-snova" />
                  <span className="your-tokens">
                    {modal3.data
                      ? modal3.data.totalNetworkReward + " USD"
                      : "0 USD"}
                  </span>
                </div>
              </h3>
              <table className="table-cl">
                <tbody>
                  <tr style={{ borderBottom: "2px solid #fc6432" }}>
                    <td
                      style={{ borderBottom: "none" }}
                      className="table-header no-tp nobot column-dark"
                    >
                      Network
                    </td>
                    <td
                      className="table-cell no-tp column-light"
                      style={{ display: "flex", borderBottom: "none" }}
                    >
                      <img
                        src={
                          modal3.data
                            ? networkMap[modal3.data.network].icon
                            : Eth
                        }
                        className="network-icon"
                      />
                      {modal3.data
                        ? networkMap[modal3.data.network].name
                        : "Ethereum"}
                      <Tooltip
                        text={t("homePage.popup.viewTransactionDetails")}
                      >
                        <a target="_blank" href={`${explorerUrl}${txnHash}`}>
                          <img
                            src={transactionLink}
                            className="transaction-link add-space"
                          />
                        </a>
                      </Tooltip>
                    </td>
                  </tr>
                  {(() => {
                    let rowCounter = 0;
                    const rows = [];

                    if (modal3.data.amounts.main.amount > 0) {
                      rows.push(
                        <tr key="main">
                          <td
                            className={`table-header no-tp nmop ${
                              rowCounter % 2 === 0
                                ? "column-light"
                                : "column-dark"
                            }`}
                          >
                            {modal3.data
                              ? networkMap[modal3.data.network].mainCurrency +
                                " Claimed"
                              : "ETH Claimed"}
                          </td>
                          <td
                            className={`table-cell no-tp flex items-centers ${
                              rowCounter % 2 === 0
                                ? "column-dark"
                                : "column-light"
                            }`}
                          >
                            <img
                              src={
                                modal3.data
                                  ? currencyMap[
                                      networkMap[modal3.data.network]
                                        .mainCurrency
                                    ].symbol
                                  : EthSymbol
                              }
                              className="network-icon"
                            />{" "}
                            {modal3.data
                              ? modal3.data.amounts.main.amount +
                                " " +
                                networkMap[modal3.data.network].mainCurrency +
                                " ($" +
                                modal3.data.amounts.main.usdValue +
                                ")"
                              : "0.21 ETH ($800.00)"}{" "}
                          </td>
                        </tr>
                      );
                      rowCounter++;
                    }

                    if (modal3.data.amounts.usdt.amount > 0) {
                      rows.push(
                        <tr key="usdt">
                          <td
                            className={`table-header ${
                              rowCounter % 2 === 0
                                ? "column-light"
                                : "column-dark"
                            }`}
                          >
                            {modal3.data ? "USDT Claimed" : "USDT Claimed"}
                          </td>
                          <td
                            className={`table-cell nb ${
                              rowCounter % 2 === 0
                                ? "column-dark"
                                : "column-light"
                            }`}
                            style={{ display: "flex", borderTop: "none" }}
                          >
                            <img src={Tether} className="payment-icon" />
                            {modal3.data
                              ? modal3.data.amounts.usdt.amount +
                                " USDT" +
                                " ($" +
                                modal3.data.amounts.usdt.usdValue +
                                ")"
                              : "100 USDT ($100.00)"}{" "}
                          </td>
                        </tr>
                      );
                      rowCounter++;
                    }

                    if (modal3.data.amounts.usdc.amount > 0) {
                      rows.push(
                        <tr key="usdc">
                          <td
                            className={`table-header ${
                              rowCounter % 2 === 0
                                ? "column-light"
                                : "column-dark"
                            }`}
                          >
                            USDC Claimed
                          </td>
                          <td
                            className={`table-cell nb ${
                              rowCounter % 2 === 0
                                ? "column-dark"
                                : "column-light"
                            }`}
                            style={{ display: "flex", borderTop: "none" }}
                          >
                            <img src={UsdC} className="network-icon" />
                            {modal3.data
                              ? modal3.data.amounts.usdc.amount +
                                " USDC" +
                                " ($" +
                                modal3.data.amounts.usdc.usdValue +
                                ")"
                              : "100 USDC ($100.00)"}{" "}
                          </td>
                        </tr>
                      );
                      rowCounter++;
                    }

                    if (modal3.data.amounts.dai.amount > 0) {
                      rows.push(
                        <tr key="dai">
                          <td
                            className={`table-header ${
                              rowCounter % 2 === 0
                                ? "column-light"
                                : "column-dark"
                            }`}
                          >
                            DAI Claimed
                          </td>
                          <td
                            className={`table-cell nb ${
                              rowCounter % 2 === 0
                                ? "column-dark"
                                : "column-light"
                            }`}
                            style={{ display: "flex", borderTop: "none" }}
                          >
                            <img src={Dai} className="payment-icon" />
                            {modal3.data
                              ? modal3.data.amounts.dai.amount +
                                " DAI" +
                                " ($" +
                                modal3.data.amounts.dai.usdValue +
                                ")"
                              : "100 DAI ($100.00)"}{" "}
                          </td>
                        </tr>
                      );
                      rowCounter++;
                    }

                    return rows;
                  })()}
                </tbody>
              </table>
              <div className="flex main-gb">
                <button
                  onClick={toggleSuccess}
                  className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                  type="button"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(252, 100, 50)",
                    color: "rgb(255, 255, 255)",
                    boxShadow: "rgb(192, 64, 0) 6px 6px",
                  }}
                >
                  {t("popups.summaryModal.referMore")}
                </button>
                <NavLink
                  className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                  to="/dashboard"
                  bis_skin_checked={1}
                  style={{ background: "transparent" }}
                >
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                    bis_skin_checked={1}
                  />
                  {t("popups.summaryModal.accessDashboard")}
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                    bis_skin_checked={1}
                  />
                </NavLink>
              </div>
              <div className="text-text-tip text-2xs text-center">
                <i>{t("popups.summaryModal.footerNote")}</i>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}{" "}
      {modal5.isOpen ? (
        <div onClick={toggleError} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal5.isOpen ? (
        <>
          <div
            className="heo"
            style={{
              position: "absolute",
              top: `${scrollPosition}px`,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
              <div className="flex space-between">
                <h2 className="scale-head">
                  <img src={transactionError} className="purchase-summary" />
                  {t("popups.errorModal.transactionError")}
                </h2>
                <div onClick={toggleDeclined} className="cross-ic">
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                  </svg>
                </div>
              </div>
              <h2 id="radix-:R1r6mH1:" className="orange mibj">
                {t("popups.errorModal.transactionErrorDetail")}
              </h2>
              <div className="confirmation-block">
                <img src={purchaseError} className="purchase-success" />
              </div>
              <h3 className="text-center bjpar your-tokens-bg">
                <span className="your-tokens">
                  {t("popups.errorModal.rejection.title")}
                </span>
              </h3>
              <div className="card-stam">
                <div className="card-item-stam">
                  <ol className="list-stam">
                    <li className="list-item-stam">
                      <b>
                        {t("popups.errorModal.rejection.errorTable.heading1")}
                      </b>{" "}
                      {t("popups.errorModal.rejection.errorTable.description1")}
                    </li>
                    <li className="list-item-stam">
                      <b>
                        {t("popups.errorModal.rejection.errorTable.heading2")}
                      </b>{" "}
                      {t("popups.errorModal.rejection.errorTable.description2")}
                    </li>
                    <li className="list-item-stam">
                      <b>
                        {t("popups.errorModal.rejection.errorTable.heading3")}
                      </b>{" "}
                      {t("popups.errorModal.rejection.errorTable.description3")}
                    </li>
                  </ol>
                </div>
              </div>
              <div className="flex main-gb">
                <button
                  onClick={toggleDeclined}
                  className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                  type="button"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(252, 100, 50)",
                    color: "rgb(255, 255, 255)",
                    boxShadow: "rgb(192, 64, 0) 6px 6px",
                  }}
                >
                  {t("popups.errorModal.tryAgain")}
                </button>

                <a
                  href="http://t.me/StacknovaBTC"
                  className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                  target="_blank"
                  bis_skin_checked={1}
                  style={{ background: "transparent" }}
                >
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                    bis_skin_checked={1}
                  />{" "}
                  {t("popups.errorModal.getSupport")}
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                    bis_skin_checked={1}
                  />
                </a>
              </div>
              <div className="text-text-tip text-2xs text-center">
                <i>{t("popups.errorModal.footerNote")}</i>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}{" "}
      {modal6.isOpen ? (
        <div onClick={toggleUniversalError} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal6.isOpen ? (
        <>
          <div
            className="heo"
            style={{
              position: "absolute",
              top: `${scrollPosition}px`,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
              <div className="flex space-between">
                <h2 className="scale-head">
                  <img src={transactionError} className="purchase-summary" />
                  {t("popups.errorModal.transactionError")}
                </h2>
                <div onClick={toggleUniversalError} className="cross-ic">
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                  </svg>
                </div>
              </div>
              <h2 id="radix-:R1r6mH1:" className="orange mibj">
                {t("popups.errorModal.transactionErrorDetail")}
              </h2>
              <div className="confirmation-block">
                <img src={purchaseError} className="purchase-success" />
              </div>
              <h3 className="text-center bjpar your-tokens-bg">
                <span className="your-tokens">
                  {t("popups.errorModal.universalFailure.title")}
                </span>
              </h3>
              <div className="card-stam">
                <div className="card-item-stam">
                  <ol className="list-stam">
                    <li className="list-item-stam">
                      <b>
                        {t(
                          "popups.errorModal.universalFailure.errorTable.heading1"
                        )}
                      </b>{" "}
                      {t(
                        "popups.errorModal.universalFailure.errorTable.description1"
                      )}
                    </li>
                    <li className="list-item-stam">
                      <b>
                        {t(
                          "popups.errorModal.universalFailure.errorTable.heading2"
                        )}
                      </b>{" "}
                      {t(
                        "popups.errorModal.universalFailure.errorTable.description2"
                      )}
                    </li>
                    <li className="list-item-stam">
                      <b>
                        {t(
                          "popups.errorModal.universalFailure.errorTable.heading3"
                        )}
                      </b>{" "}
                      {t(
                        "popups.errorModal.universalFailure.errorTable.description3"
                      )}
                    </li>
                  </ol>
                </div>
              </div>
              <div className="flex main-gb">
                <button
                  onClick={toggleUniversalError}
                  className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                  type="button"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(252, 100, 50)",
                    color: "rgb(255, 255, 255)",
                    boxShadow: "rgb(192, 64, 0) 6px 6px",
                  }}
                >
                  {t("popups.errorModal.tryAgain")}
                </button>

                <a
                  href="https://t.me/StacknovaBTC"
                  className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                  target="_blank"
                  bis_skin_checked={1}
                  style={{ background: "transparent" }}
                >
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                    bis_skin_checked={1}
                  />{" "}
                  {t("popups.errorModal.getSupport")}
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                    bis_skin_checked={1}
                  />
                </a>
              </div>
              <div className="text-text-tip text-2xs text-center">
                <i>{t("popups.errorModal.footerNote")}</i>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/* Model popup */}
    </>
  );
};

export default Rank;
