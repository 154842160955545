import React from "react";
import "./shimmer.css";

const ReferralLinkShimmer = () => (
  <div className="_copyWrapper_1fjrj_1">
    <span className="shimmer shimmer-text _copyText_1fjrj_12" />
    <div className="shimmer shimmer-icon-ref _copyBtn_1fjrj_20" />
  </div>
);

export default ReferralLinkShimmer;
