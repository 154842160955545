import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { currencyIcons } from "../../../../utils/currenciesUtils";
import logoIcon from "../../../../assets/img/logo-icon-dark.png";
import novaPointsIcon from "../../../../assets/img/nova-points.svg";
import { useDispatch, useSelector } from "react-redux";
import { initializeBuyWithCard } from "../../../../reducers/buyWithCardSlice";
import { setDollarAmount } from "../../../../reducers/dollarCardSlice";
import {
  setSelectedFiat,
  initializeCurrency,
} from "../../../../reducers/currencySlice";
import {
  setFiatAmount,
  setCardSnovaAmount,
  setCardNovaPoints,
} from "../../../../reducers/amountsSlice";
import useNovaPoints from "../../../../hooks/useNovaPoints";
import { useTranslation } from "react-i18next";
import {
  useDebouncedValue,
  formatValue,
  validateAndFormatInput,
} from "../../../../utils/helpers";
import { useBlockchain } from "../../../../hooks/useBlockchain";
import "../banner.css";

const actualPricePerOneSNOVA = 0.045;
const maximumRoundSupplyInDollars = 288000;

export default function RatioCalculatorCard() {
  const { isConnected, chainId } = useWeb3ModalAccount();
  const [inputSource, setInputSource] = useState(null);
  const { walletProvider } = useWeb3ModalProvider();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  const fiatAmount = useSelector((state) => state.amounts.fiatAmount);
  const snovaAmount = useSelector((state) => state.amounts.cardSnovaAmount);
  const novaPoints = useSelector((state) => state.amounts.cardNovaPoints);
  const dollarAmount = useSelector((state) => state.dollarCard.dollarAmount);
  const selectedCurrency = useSelector(
    (state) => state.currency.selectedCurrency
  );
  const selectedFiat = useSelector((state) => state.currency.selectedFiat);
  const buyWithCard = useSelector((state) => state.buyWithCard.buyWithCard);

  const [dropInner, setDropInner] = useState(false);

  const { normalizedPrice, fetchNormalizedPrice } = useBlockchain({
    isConnected,
    chainId,
    walletProvider,
    selectedCurrency,
    selectedFiat,
    buyWithCard,
  });

  const debouncedFiatAmount = useDebouncedValue(fiatAmount, 300);
  const debouncedSnovaAmount = useDebouncedValue(snovaAmount, 300);
  const calculatedNovaPoints = useNovaPoints(parseFloat(dollarAmount));

  useEffect(() => {
    const interval = setInterval(async () => {
      fetchNormalizedPrice();
    }, 5000);

    return () => clearInterval(interval);
  }, [isConnected, selectedFiat, chainId, dollarAmount]);

  useEffect(() => {
    fetchNormalizedPrice();
  }, [selectedFiat, fetchNormalizedPrice]);

  useEffect(() => {
    if (debouncedFiatAmount && inputSource === "fiatCard") {
      calculateSnova(parseFloat(debouncedFiatAmount));
    } else if (debouncedSnovaAmount && inputSource === "snovaCard") {
      calculateFiat(parseFloat(debouncedSnovaAmount));
    }
  }, [debouncedFiatAmount, debouncedSnovaAmount, normalizedPrice, inputSource]);

  useEffect(() => {
    if (isConnected) {
      handleConnectedState(chainId);
    } else {
      handleDisconnectedState();
    }
  }, [chainId, isConnected]);

  useEffect(() => {
    restorePreviousAmounts();
  }, []);

  useEffect(() => {
    saveAmountsToLocalStorage();
  }, [fiatAmount, snovaAmount, novaPoints]);

  useEffect(() => {
    dispatch(initializeBuyWithCard());
    dispatch(initializeCurrency({ chainId }));
  }, [dispatch, chainId]);

  useEffect(() => {
    dispatch(setCardNovaPoints(calculatedNovaPoints));
  }, [calculatedNovaPoints, dispatch]);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropInner(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const toggleInner = (event) => {
    event.stopPropagation();
    setDropInner((prev) => !prev);
  };

  const handleFiatCurrencyChange = (currency, event) => {
    event.stopPropagation();
    dispatch(setSelectedFiat(currency));
    setDropInner(false);
  };

  const getFiatCurrencyOptions = () => {
    const currencyNames = {
      USD: t("currency.usd"),
      GBP: t("currency.gbp"),
      EUR: t("currency.eur"),
    };
    return ["USD", "GBP", "EUR"].map((currency) => (
      <div
        key={currency}
        onClick={(e) => handleFiatCurrencyChange(currency, e)}
        className={`send__asset-select css-f6epcl dropdown ${
          selectedFiat === currency ? "active" : ""
        }`}
      >
        <CurrencyOption
          currency={currency}
          currencyName={currencyNames[currency]}
        />
      </div>
    ));
  };

  const CurrencyOption = ({ currency, currencyName }) => (
    <div className="w-full flex items-center">
      <div className="flex items-center mr-2">
        {typeof currencyIcons[currency] === "string" ? (
          <img
            src={currencyIcons[currency]}
            alt={currency}
            className="w-5 h-5 object-cover mr-2"
          />
        ) : (
          React.createElement(currencyIcons[currency], {
            className: "w-5 h-5 mr-2",
            "aria-label": currency,
          })
        )}
      </div>
      <div className="currency-text">
        <p className="text-[#F5F5F4] text-sm sm:text-base font-bold">
          {currencyName}
        </p>
      </div>
    </div>
  );

  const resetAmounts = () => {
    dispatch(setFiatAmount(""));
    dispatch(setCardSnovaAmount(""));
    dispatch(setDollarAmount(0));
    dispatch(setCardNovaPoints(0));
  };

  const handleConnectedState = (chainId) => {
    if (!chainId) return;
    localStorage.setItem("lastConnectedChainId", chainId);
  };

  const handleDisconnectedState = () => {
    const storedChainId = localStorage.getItem("lastConnectedChainId");
  };

  const restorePreviousAmounts = () => {
    const savedFiatAmount = localStorage.getItem("fiatAmountCard");
    const savedSnovaAmount = localStorage.getItem("snovaAmountCard");
    const savedNovaPoints = localStorage.getItem("novaPointsCard");
    const savedInputSource = localStorage.getItem("inputSourceCard");
    if (savedFiatAmount !== null && savedInputSource === "fiatCard") {
      dispatch(setFiatAmount(savedFiatAmount));
      setInputSource("fiatCard");
    }
    if (savedSnovaAmount !== null && savedInputSource === "snovaCard") {
      dispatch(setCardSnovaAmount(savedSnovaAmount));
      setInputSource("snovaCard");
    }
    if (savedNovaPoints !== null) {
      dispatch(setCardNovaPoints(savedNovaPoints));
    }
  };

  const saveAmountsToLocalStorage = () => {
    if (fiatAmount) {
      localStorage.setItem("fiatAmountCard", fiatAmount);
    } else {
      localStorage.removeItem("fiatAmountCard");
    }
    if (snovaAmount) {
      localStorage.setItem("snovaAmountCard", snovaAmount);
    } else {
      localStorage.removeItem("snovaAmountCard");
    }
    if (novaPoints) {
      localStorage.setItem("novaPointsCard", novaPoints);
    }
    if (inputSource) {
      localStorage.setItem("inputSourceCard", inputSource);
    }
  };

  const calculateSnova = useCallback(
    async (fiatValue) => {
      if (inputSource !== "fiatCard") return;
      try {
        dispatch(setDollarAmount(formatValue(fiatValue * normalizedPrice, 2)));
        const snovaValue = formatValue(
          fiatValue * (normalizedPrice / actualPricePerOneSNOVA),
          3
        );
        if (snovaValue * actualPricePerOneSNOVA > maximumRoundSupplyInDollars) {
          dispatch(
            setFiatAmount(
              formatValue(maximumRoundSupplyInDollars / normalizedPrice, 2)
            )
          );
          dispatch(
            setCardSnovaAmount(
              formatValue(
                maximumRoundSupplyInDollars / actualPricePerOneSNOVA,
                0
              )
            )
          );
        } else {
          dispatch(setCardSnovaAmount(snovaValue));
        }
      } catch (error) {
        console.error("Error calculating SNOVA:", error);
      }
    },
    [normalizedPrice, inputSource, dispatch]
  );

  const calculateFiat = useCallback(
    async (snovaValue) => {
      if (inputSource !== "snovaCard") return;
      try {
        dispatch(
          setDollarAmount(
            (snovaValue * 100 * actualPricePerOneSNOVA * 100) / 10000
          )
        );
        const finalValue = normalizedPrice / actualPricePerOneSNOVA;
        const fiatValue = snovaValue / finalValue;
        const truncatedFiatValue = formatValue(fiatValue, 4);
        let truncatedFiatAmountValue;
        if (
          truncatedFiatValue * normalizedPrice >
          maximumRoundSupplyInDollars
        ) {
          truncatedFiatAmountValue = formatValue(
            maximumRoundSupplyInDollars / normalizedPrice,
            2
          );
          dispatch(setFiatAmount(truncatedFiatAmountValue));
          dispatch(
            setCardSnovaAmount(
              formatValue(
                maximumRoundSupplyInDollars / actualPricePerOneSNOVA,
                0
              )
            )
          );
        } else {
          truncatedFiatAmountValue = formatValue(fiatValue, 2);
          dispatch(setFiatAmount(truncatedFiatAmountValue));
        }
      } catch (error) {
        console.error("Error calculating fiat:", error);
      }
    },
    [normalizedPrice, inputSource, dispatch]
  );

  const handleFiatAmountChange = (e) => {
    const rawInput = e.target.value;
    const formattedValue = validateAndFormatInput(rawInput, fiatAmount, 4);
    dispatch(setFiatAmount(formattedValue));
    setInputSource("fiatCard");
    localStorage.setItem("inputSourceCard", "fiatCard");
    if (formattedValue) {
      calculateSnova(parseFloat(formattedValue));
    } else {
      dispatch(setCardSnovaAmount(""));
      dispatch(setDollarAmount(0));
      dispatch(setCardNovaPoints(0));
    }
  };

  const handleSnovaAmountChange = (e) => {
    const rawInput = e.target.value;
    const formattedValue = validateAndFormatInput(rawInput, snovaAmount, 3);
    dispatch(setCardSnovaAmount(formattedValue));
    setInputSource("snovaCard");
    localStorage.setItem("inputSourceCard", "snovaCard");
    if (formattedValue) {
      calculateFiat(parseFloat(formattedValue));
    } else {
      dispatch(setFiatAmount(""));
      dispatch(setDollarAmount(0));
      dispatch(setCardNovaPoints(0));
    }
  };

  return (
    <div className="flex flex-col laptop:flex-row justify-between gap-[4px] relative z-index-11">
      <style>
        {`
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield; /* Firefox */
          }
        `}
      </style>
      <div className="css-1c5id9a">
        <div
          ref={dropdownRef}
          onClick={toggleInner}
          className="css-70qvj9"
          style={{ cursor: "pointer" }}
        >
          {React.createElement(currencyIcons[selectedFiat], {
            style: { width: "24px", height: "24px" },
            "aria-label": selectedFiat,
          })}
          <p className="text-[14px] text-[#F5F5F4] mx-[8px] font-[500]">
            {selectedFiat}
          </p>
          <div
            className="_arrow_1oajo_1"
            style={{
              transform: dropInner ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 0.3s ease",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 10L12 5.73613L4 5.73613L8 10Z"
                fill="#A9A29D"
              />
            </svg>
          </div>
          {dropInner && (
            <div className="css-1r69ko0">{getFiatCurrencyOptions()}</div>
          )}
        </div>
        <input
          type="text"
          placeholder="0"
          value={fiatAmount}
          onChange={handleFiatAmountChange}
          className="css-kxgh99"
        />
        <div className="dollar-text">${formatValue(dollarAmount, 2)}</div>
      </div>
      <div className="css-1c5id9a">
        <div className="css-70qvj9">
          <img src={logoIcon} className="buy-snova-icon" alt="Snova icon" />
          <p className="MuiTypography-root MuiTypography-body1 css-eikm1t">
            SNOVA
          </p>
        </div>
        <input
          type="text"
          placeholder="0"
          value={snovaAmount}
          onChange={handleSnovaAmountChange}
          className="css-kxgh99"
        />
        <span
          className="dollar-text orange text-accent text-[20px] font-medium flex items-center gap-1 leading-[25px] mb-[-2px] sm:text-[16px]"
          translate="no"
        >
          <img
            src={novaPointsIcon}
            style={{ height: "25px", width: "20px", marginRight: "4px" }}
            alt="Nova points icon"
          />
          +{formatValue(novaPoints, 0)} {t("homePage.novaBox.novaPoints")}
        </span>
      </div>
      <div className="css-im6gv3 for-mblonly">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={20}
          viewBox="0 0 20 20"
          fill="none"
          alt="Swap icon"
        >
          <path
            d="M10 4.1665L10 14.9998M15 10.8332L10.5893 15.2439C10.2638 15.5694 9.73618 15.5694 9.41074 15.2439L5 10.8332"
            stroke="#79716B"
            strokeWidth={2}
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
}
