import React, { useState, useEffect } from "react";
import "./Eclipse.css";
import Circle from "../../../assets/video/circles-rotation.webm";
import communityIcon from "../../../assets/img/community-power.webp";
import securityIcon from "../../../assets/img/btc-security.webp";
import innovationIcon from "../../../assets/img/ai-innovation.webp";
import { useTranslation } from "react-i18next";
const Eclipse = () => {
  const { t } = useTranslation();
  const [isSafari, setIsSafari] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  useEffect(() => {
    if (typeof navigator !== "undefined") {
      const userAgent = navigator.userAgent;
      const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(userAgent);
      setIsSafari(isSafariBrowser);
    }
    const handleResize = () => {
      if (typeof window !== "undefined") {
        setIsMobileOrTablet(window.innerWidth <= 1024);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const shouldRenderMobileVersion = isSafari || isMobileOrTablet;
  return (
    <div className={`eclipse-main ${isSafari ? "safari" : ""}`}>
      {/* Desktop Version */}
      {!shouldRenderMobileVersion && (
        <div className="desk-only section-3_container__Rbmzm ">
          <div className="panel-1">
            <div className="ellipses">
              {isSafari ? (
                // Render alternative content for Safari
                <img
                  src="fallback-image.png"
                  alt="Eclipse Animation"
                  loading="lazy"
                  decoding="async"
                />
              ) : (
                <video autoPlay muted loop playsInline>
                  <source src={Circle} type="video/webm" />
                </video>
              )}
            </div>
            <div>
              <div className="section-3_acceleratorIcon___t779">
                <img
                  src={innovationIcon}
                  className="ai-icon"
                  alt="AI icon"
                  loading="lazy"
                  decoding="async"
                />
                <span className="section-3_acceleratorText__6QKzJ">
                  {t("homePage.eclipse.acceleratorText")}
                </span>
                <div className="section-3_popup__q_f81">
                  <h6 className="section-3_h6__eii5y">
                    {t("homePage.eclipse.aiInnovation")}
                  </h6>
                  <div className="section-3_body2__NoQ5Q">
                    {t("homePage.eclipse.aiInnovationDescription")}
                  </div>
                </div>
              </div>
              <div className="section-3_launchpadIcon__RsDk7">
                <img
                  src={securityIcon}
                  className="security-icon"
                  alt="Security icon"
                  loading="lazy"
                  decoding="async"
                />
                <span className="section-3_launchpadText__GU3Zb">
                  {t("homePage.eclipse.bitcoin")}
                </span>
                <div className="section-3_popup__q_f81">
                  <h6 className="section-3_h6__eii5y">
                    {t("homePage.eclipse.bitcoinSecurity")}
                  </h6>
                  <div className="section-3_body2__NoQ5Q">
                    {t("homePage.eclipse.bitcoinSecurityDescription")}
                  </div>
                </div>
              </div>
              <div className="section-3_growthAgencyIcon__baZ47">
                <img
                  src={communityIcon}
                  className="community-icon"
                  alt="Community icon"
                  loading="lazy"
                  decoding="async"
                />
                <span className="section-3_growthAgencyText__7_0T0">
                  {t("homePage.eclipse.community")}
                </span>
                <div className="section-3_popup__q_f81">
                  <h6 className="section-3_h6__eii5y">
                    {t("homePage.eclipse.communityPower")}
                  </h6>
                  <div className="section-3_body2__NoQ5Q">
                    {t("homePage.eclipse.communityPowerDescription")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Mobile version */}
      {shouldRenderMobileVersion && (
        <div className="mt-5as">
          <div className="section-3-mobile_container__vXiWF">
            <div className="panel-1">
              {/* <div>
                <div className="section-3_acceleratorIcon___t779">
                  <img
                    src={innovationIcon}
                    className="ai-icon ai-icon-2"
                    alt="AI icon"
                    loading="lazy"
                    decoding="async"
                  />
                  <span className="section-3_acceleratorText__6QKzJ">
                    {t("homePage.eclipse.acceleratorText")}
                  </span>
                </div>
                <div className="section-3_launchpadIcon__RsDk7">
                  <img
                    src={securityIcon}
                    className="security-icon security-icon-2"
                    alt="Security icon"
                    loading="lazy"
                    decoding="async"
                  />
                  <span className="section-3_launchpadText__GU3Zb">
                    {t("homePage.eclipse.bitcoin")}
                  </span>
                </div>
                <div className="section-3_growthAgencyIcon__baZ47">
                  <img
                    src={communityIcon}
                    className="community-icon community-icon-2"
                    alt="Community icon"
                    loading="lazy"
                    decoding="async"
                  />
                  <span className="section-3_growthAgencyText__7_0T0">
                    {t("homePage.eclipse.community")}
                  </span>
                </div>
              </div>{" "} */}
            </div>
            <div>
              {/* <div className="section-3_acceleratorIcon___t779">
                <img src={innovationIcon} className="ai-icon" />
                <span className="section-3_acceleratorText__6QKzJ">
                  {t("homePage.eclipse.acceleratorText")}
                </span>
                <div className="section-3_popup__q_f81">
                  <h6 className="section-3_h6__eii5y">
                    {t("homePage.eclipse.aiInnovation")}
                  </h6>
                  <div className="section-3_body2__NoQ5Q">
                    {t("homePage.eclipse.aiInnovationDescription")}
                  </div>
                </div>
              </div> */}
              {/* <div className="section-3_launchpadIcon__RsDk7">
                <img src={securityIcon} className="security-icon" />
                <span className="section-3_launchpadText__GU3Zb">
                  {t("homePage.eclipse.bitcoin")}
                </span>
                <div className="section-3_popup__q_f81">
                  <h6 className="section-3_h6__eii5y">
                    {t("homePage.eclipse.bitcoinSecurity")}
                  </h6>
                  <div className="section-3_body2__NoQ5Q">
                    {t("homePage.eclipse.bitcoinSecurityDescription")}
                  </div>
                </div>
              </div> */}
              {/* <div className="section-3_growthAgencyIcon__baZ47">
                <img src={communityIcon} className="community-icon" />
                <span className="section-3_growthAgencyText__7_0T0">
                  {t("homePage.eclipse.community")}
                </span>
                <div className="section-3_popup__q_f81">
                  <h6 className="section-3_h6__eii5y">
                    {t("homePage.eclipse.communityPower")}
                  </h6>
                  <div className="section-3_body2__NoQ5Q">
                    {t("homePage.eclipse.communityPowerDescription")}
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className=" mbl-hide flex flex-col items-center absolute w-full text-center z-10">
              <h1>{t("homePage.eclipse.mobileTitle")}</h1>
              <h1>
                <span className="text-[#E2CD6C]">
                  {t("homePage.eclipse.mobileSubtitle")}
                </span>
              </h1>
            </div> */}
            <div className="flex flex-col items-center panel-2 mtless mt-96 relative">
              <div className="section-3-mobile_popup__YpZUj">
                <div className="w-full flex flex-col items-center eclipsetar">
                  <img
                    src={innovationIcon}
                    className="ai-icon"
                    alt="AI icon"
                    loading="lazy"
                    decoding="async"
                  />
                  <div className="section-3-mobile_launchpadIcon__5MIFC" />
                </div>
                <h6 className="section-3-mobile_h6__tvakd">
                  {t("homePage.eclipse.aiInnovation")}
                </h6>
                <div className="section-3-mobile_body2__BWU13">
                  {t("homePage.eclipse.aiInnovationDescription")}
                </div>
              </div>
              <div className="section-3-mobile_popup__YpZUj">
                <div className="w-full flex flex-col items-center eclipsetar">
                  <img
                    src={securityIcon}
                    className="security-icon"
                    alt="Security icon"
                    loading="lazy"
                    decoding="async"
                  />
                  <div className="section-3-mobile_growthAgencyIcon__NLI0d" />
                </div>
                <h6 className="section-3-mobile_h6__tvakd">
                  {t("homePage.eclipse.bitcoinSecurity")}
                </h6>
                <div className="section-3-mobile_body2__BWU13">
                  {t("homePage.eclipse.bitcoinSecurityDescription")}
                </div>
              </div>
              <div className="section-3-mobile_popup__YpZUj">
                <div className="w-full flex flex-col items-center eclipsetar">
                  <img
                    src={communityIcon}
                    className="community-icon"
                    alt="Community icon"
                    loading="lazy"
                    decoding="async"
                  />
                  <div className="section-3-mobile_acceleratorIcon__DHFAg" />
                </div>
                <h6 className="section-3-mobile_h6__tvakd">
                  {t("homePage.eclipse.communityPower")}
                </h6>
                <div className="section-3-mobile_body2__BWU13">
                  {t("homePage.eclipse.communityPowerDescription")}
                </div>
              </div>
              {/*
            <div className="section-3-mobile_line__Em3Ui" />
            <div className="section-3-mobile_acceleratorIcon2__NvFUR" />
            */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Eclipse;
