export const GBPIcon = () => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2062_50214)">
      <path
        d="M10.5003 20.0001C16.0231 20.0001 20.5003 15.5229 20.5003 10.0001C20.5003 4.47721 16.0231 6.10352e-05 10.5003 6.10352e-05C4.97743 6.10352e-05 0.500275 4.47721 0.500275 10.0001C0.500275 15.5229 4.97743 20.0001 10.5003 20.0001Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.56721 3.91193C1.7817 4.93392 1.1894 6.11185 0.844513 7.3915H6.04678L2.56721 3.91193Z"
        fill="#0052B4"
      />
      <path
        d="M20.1556 7.39152C19.8107 6.11191 19.2183 4.93398 18.4329 3.91199L14.9534 7.39152H20.1556Z"
        fill="#0052B4"
      />
      <path
        d="M0.844513 12.6089C1.18943 13.8885 1.78174 15.0664 2.56721 16.0884L6.04666 12.6089H0.844513Z"
        fill="#0052B4"
      />
      <path
        d="M16.5882 2.06734C15.5662 1.28183 14.3883 0.689526 13.1087 0.344604V5.54683L16.5882 2.06734Z"
        fill="#0052B4"
      />
      <path
        d="M4.41183 17.9329C5.43383 18.7184 6.61176 19.3107 7.89137 19.6556V14.4534L4.41183 17.9329Z"
        fill="#0052B4"
      />
      <path
        d="M7.89133 0.344604C6.61172 0.689526 5.43379 1.28183 4.41183 2.0673L7.89133 5.54679V0.344604Z"
        fill="#0052B4"
      />
      <path
        d="M13.1087 19.6556C14.3883 19.3107 15.5662 18.7184 16.5882 17.9329L13.1087 14.4534V19.6556Z"
        fill="#0052B4"
      />
      <path
        d="M14.9534 12.6089L18.4329 16.0884C19.2183 15.0665 19.8107 13.8885 20.1556 12.6089H14.9534Z"
        fill="#0052B4"
      />
      <path
        d="M20.4156 8.69572H11.8047H11.8047V0.0847095C11.3777 0.0291235 10.9423 6.10352e-05 10.5003 6.10352e-05C10.0581 6.10352e-05 9.62289 0.0291235 9.19594 0.0847095V8.69565V8.69569H0.584923C0.529337 9.12268 0.500275 9.55799 0.500275 10.0001C0.500275 10.4422 0.529337 10.8774 0.584923 11.3044H9.19586H9.1959V19.9154C9.62289 19.971 10.0581 20.0001 10.5003 20.0001C10.9423 20.0001 11.3777 19.971 11.8046 19.9154V11.3045V11.3044H20.4156C20.4712 10.8774 20.5003 10.4422 20.5003 10.0001C20.5003 9.55799 20.4712 9.12268 20.4156 8.69572Z"
        fill="#D80027"
      />
      <path
        d="M13.1087 12.6088L17.571 17.0711C17.7763 16.866 17.9721 16.6515 18.1589 16.4292L14.3385 12.6088H13.1087V12.6088Z"
        fill="#D80027"
      />
      <path
        d="M7.89134 12.6088H7.89126L3.42896 17.0711C3.63411 17.2763 3.8486 17.4721 4.07095 17.6589L7.89134 13.8384V12.6088Z"
        fill="#D80027"
      />
      <path
        d="M7.89134 7.39142V7.39134L3.429 2.92896C3.22376 3.13411 3.02798 3.3486 2.84119 3.57095L6.66161 7.39138H7.89134V7.39142Z"
        fill="#D80027"
      />
      <path
        d="M13.1087 7.39144L17.5711 2.92902C17.3659 2.72379 17.1514 2.52801 16.9291 2.34125L13.1087 6.16168V7.39144Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_2062_50214">
        <rect
          width={20}
          height={20}
          fill="white"
          transform="translate(0.5 6.10352e-05)"
        />
      </clipPath>
    </defs>
  </svg>
);
