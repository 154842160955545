// src/reducers/buyWithCardSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  buyWithCard: false,
};

const buyWithCardSlice = createSlice({
  name: "buyWithCard",
  initialState,
  reducers: {
    setBuyWithCard: (state, action) => {
      state.buyWithCard = action.payload;
      localStorage.setItem("buyWithCard", action.payload.toString());
    },
    initializeBuyWithCard: (state) => {
      const stored = localStorage.getItem("buyWithCard");
      state.buyWithCard = stored ? stored === "true" : false;
    },
  },
});

export const { setBuyWithCard, initializeBuyWithCard } =
  buyWithCardSlice.actions;

export default buyWithCardSlice.reducer;
