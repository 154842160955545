import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Check if the clicked link is not the FAQ or Tokenomics link
    if (!hash.includes("#faq") && !hash.includes("#tokenomics")) {
      const timeout = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100); // Adjust the delay as needed

      return () => clearTimeout(timeout);
    }
  }, [hash]);

  return null;
};

export default ScrollToTop;
