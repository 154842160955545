import chevron from "../../../assets/img/chevron.svg";
import React, { useState, useRef, useEffect } from "react";
import "./Faq.css";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  useEffect(() => {
    // Function to scroll to FAQ section
    const scrollToFAQ = () => {
      const faqSection = document.getElementById("faq");
      if (faqSection) {
        faqSection.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (window.location.hash === "#faq") {
      scrollToFAQ();
    }
  }, []);
  // Define your array of FAQs
  const faqs = [
    {
      question: t("homePage.faq.faqs.question1"),
      answer: t("homePage.faq.faqs.answer1"),
    },
    {
      question: t("homePage.faq.faqs.question2"),
      answer: t("homePage.faq.faqs.answer2"),
    },
    {
      question: t("homePage.faq.faqs.question3"),
      answer: t("homePage.faq.faqs.answer3"),
    },
    {
      question: t("homePage.faq.faqs.question4"),
      answer: t("homePage.faq.faqs.answer4"),
    },
    // Add more FAQs as needed
  ];

  return (
    <div className="bottomContainer section-padding" id="faq">
      <div className="questionWeHaveContainer max-width ">
        <div className="questionsLeft">
          <p className="faqTag">{t("homePage.faq.tag")}</p>
          <p className="faqHEading">
            {/* Got Questions? <br className="for-mbl" /> Get Answers */}
            {t("homePage.faq.heading1")} <br className="for-mbl" />
            {t("homePage.faq.heading2")}
          </p>
          <hr />
          <div className="contactUsFaq">
            {" "}
            {t("homePage.faq.description")}
            {/*Our team is dedicated to ensuring your seamless experience and safety.*/}
            <br />
            <br />
            {/* <a
              className="faqBtn"
              href="mailto:info@stacknova.ai?subject=Stacknova%20-%20Question%20from%20the%20website"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a> */}
            <a
              href="https://docs.stacknova.ai/stacknova-ai/getting-started/general-faqs#how-can-i-contact-the-stacknova-ai-team-for-different-inquiries"
              target="_blank"
              className="bl-inline-flex  t-version bl-items-center cornet bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[267px] bl-text-[26px]/none bl-text-primary secondary-transform"
              style={{ fontSize: 16, background: "#3a161e5a" }}
            >
              <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
              <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
              <span>{t("homePage.faq.btnText")} </span>
              <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
              <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
            </a>
          </div>
          <br />
        </div>
        <div className="questionsRight">
          <div
            className="flat-accordion aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`flat-toggle box-text corner-box ${
                  openIndex === index ? " active" : ""
                }`}
                onClick={() => toggleFAQ(index)}
              >
                <div
                  className={`h7 toggle-title ${
                    openIndex === index ? " active" : ""
                  }`}
                >
                  {faq.question}
                  <img src={chevron} alt="" />
                </div>
                <p className="toggle-content">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
