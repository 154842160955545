// novaPointsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const novaPointsSlice = createSlice({
  name: "novaPoints",
  initialState: {
    points: null,
  },
  reducers: {
    setNovaPoints: (state, action) => {
      state.points = action.payload;
    },
  },
});

export const { setNovaPoints } = novaPointsSlice.actions;

export default novaPointsSlice.reducer;
