import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ethAmount: "",
  snovaAmount: "",
  novaPoints: 0,
  fiatAmount: "",
  cardSnovaAmount: "",
  cardNovaPoints: 0,
};

const amountsSlice = createSlice({
  name: "amounts",
  initialState,
  reducers: {
    setEthAmount: (state, action) => {
      state.ethAmount = action.payload;
    },
    setSnovaAmount: (state, action) => {
      state.snovaAmount = action.payload;
    },
    setNovaPoints: (state, action) => {
      state.novaPoints = action.payload;
    },
    setFiatAmount: (state, action) => {
      state.fiatAmount = action.payload;
    },
    setCardSnovaAmount: (state, action) => {
      state.cardSnovaAmount = action.payload;
    },
    setCardNovaPoints: (state, action) => {
      state.cardNovaPoints = action.payload;
    },
    setLastConnectedChainId: (state, action) => {
      state.lastConnectedChainId = action.payload;
    },
  },
});

export const {
  setEthAmount,
  setSnovaAmount,
  setNovaPoints,
  setFiatAmount,
  setCardSnovaAmount,
  setCardNovaPoints,
  setLastConnectedChainId,
} = amountsSlice.actions;

export default amountsSlice.reducer;
