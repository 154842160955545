import { createSlice } from "@reduxjs/toolkit";

const referrerSlice = createSlice({
  name: "referrer",
  initialState: {
    referrer: undefined,
    referrerAddress: undefined,
    referrerUpdateCount: 0,
  },
  reducers: {
    setReferrer(state, action) {
      state.referrer = action.payload;
    },
    setReferrerAddress(state, action) {
      state.referrerAddress = action.payload;
    },
    incrementReferrerUpdate: (state) => {
      state.referrerUpdateCount += 1;
    },
  },
});

export const { setReferrer, setReferrerAddress, incrementReferrerUpdate } =
  referrerSlice.actions;
export default referrerSlice.reducer;
