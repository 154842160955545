// referralCountSlice.js
import { createSlice } from "@reduxjs/toolkit";

const referralCountSlice = createSlice({
  name: "referralCount",
  initialState: {
    count: null,
  },
  reducers: {
    setReferralCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { setReferralCount } = referralCountSlice.actions;

export default referralCountSlice.reducer;
