// src/reducers/transactionsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: null,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setAllTransactions(state, action) {
      state.transactions = action.payload;
    },
  },
});

export const { setAllTransactions } = transactionsSlice.actions;
export default transactionsSlice.reducer;
