// import kyberswap from "../../../assets/img/kyberswap.png";
// import mantle from "../../../assets/img/mantle.png";
// import woo from "../../../assets/img/woo.png";
// import linea from "../../../assets/img/linea.png";
// import gameswift from "../../../assets/img/gameswift.png";
// import dodo from "../../../assets/img/dodo.png";
// import eesee from "../../../assets/img/eesee.png";
// import insomnia from "../../../assets/img/insomnia.png";
// import openAi from "../../../assets/img/openai-logo.svg";
// import bitcoin from "../../../assets/img/bitcoin-logo.svg";
// import stacks from "../../../assets/img/full-stacks-logo.png";
// import aws from "../../../assets/img/aws-logo.svg";
// import Marquee from "react-fast-marquee";
// import metamask from "../../../assets/img/MetaMask.svg";
// import trustwallet from "../../../assets/img/trustwallet-logo.png";
// import walletconnect from "../../../assets/img/walletconnect-logo.png";
// import unisat from "../../../assets/img/unisat.png";
// import okx from "../../../assets/img/okx-wallet.svg";
// import leather from "../../../assets/img/leather-logo.svg";
// import xverse from "../../../assets/img/xverse.png";
import React, { useRef, useEffect, useState } from "react";
import bloomberg from "../../../assets/img/bloomberg.webp";
import coindesk from "../../../assets/img/coindesk.webp";
import coinmarketcap from "../../../assets/img/coinmarketcap.webp";
import cointelegraph from "../../../assets/img/cointelegraph.webp";
import decrypt from "../../../assets/img/decrypt.webp";
import nasdaq from "../../../assets/img/nasdaq.webp";
import { useTranslation } from "react-i18next";
import "./Ecosystem.css";

const Ecosystem = () => {
  const { t } = useTranslation();
  const mediaImages = [
    { src: bloomberg, alt: "Bloomberg" },
    { src: cointelegraph, alt: "Cointelegraph" },
    { src: coindesk, alt: "Coindesk" },
    { src: decrypt, alt: "Decrypt" },
    { src: nasdaq, alt: "Nasdaq" },
    { src: coinmarketcap, alt: "CoinMarketCap" },
  ];

  return (
    <>
      {/* <div className="AppContainer_AppContainer__zMOpX max-width  PartnerSection_PartnerSection__QX3xC">
        <div className="SectionHeader_SectionHeader__mzGAY PartnerSection_sectionHeader__QYsEV">
          <div className="SectionHeaderV2_sectionHeaderSubtitle__eUmZ_">
            Powered By
          </div>
        </div>

        <Marquee direction="right">
          <div className="icon-container-alt">
            <a href="#" target="_blank" className="logos__link w-inline-block">
              <img
                src={openAi}
                loading="lazy"
                alt="Binance Labs Logo"
                className="logotype"
              />
            </a>
          </div>

          <div className="icon-container-alt">
            <a href="#" target="_blank" className="logos__link w-inline-block">
              <img
                src={stacks}
                loading="lazy"
                alt="web3 foundation"
                className="logotype"
              />
            </a>
          </div>

          <div className="icon-container-alt">
            <a href="#" target="_blank" className="logos__link w-inline-block">
              <img src={bitcoin} loading="lazy" alt className="logotype" />
            </a>
          </div>

          <div className="icon-container-alt">
            <a target="_blank" className="logos__link w-inline-block">
              <img src={aws} loading="lazy" alt className="logotype aws-logo" />
            </a>
          </div>

          {/*
          <div className="icon-container-alt">
            <a href="#" target="_blank" className="logos__link w-inline-block">
              <img src={metamask} loading="lazy" alt className="logotype" />
            </a>
          </div>

          <div className="icon-container-alt">
            <a href="#" target="_blank" className="logos__link w-inline-block">
              <img src={trustwallet} loading="lazy" className="logotype" />
            </a>
          </div>

          <div className="icon-container-alt">
            <a href="#" target="_blank" className="logos__link w-inline-block">
              <img
                src={walletconnect}
                loading="lazy"
                alt
                className="logotype"
              />
            </a>
          </div>
          */}
      {/* <div className="icon-container-alt">
        <a href="#" target="_blank" className="logos__link w-inline-block">
          <img
            src={unisat}
            loading="lazy"
            alt="Binance Labs Logo"
            className="logotype"
          />
        </a>
      </div> */}
      {/* <div className="icon-container-alt">
        <a href="#" target="_blank" className="logos__link w-inline-block">
          <img
            src={okx}
            loading="lazy"
            alt="web3 foundation"
            className="logotype okx-wallet"
          />
        </a>
      </div>
      <div className="icon-container-alt">
        <a href="#" target="_blank" className="logos__link w-inline-block">
          <img
            src={leather}
            loading="lazy"
            alt
            className="logotype leather-wallet"
          />
        </a>
      </div>
      <div className="icon-container-alt">
        <a href="#" target="_blank" className="logos__link w-inline-block">
          <img src={xverse} loading="lazy" alt className="logotype" />
        </a>
      </div> */}
      {/* </Marquee> */}
      {/* </div> */}
      <section
        id="ecosystem"
        className="relative z-50 overflow-hidden bg-black pt-8 text-center text-white"
      >
        <div className="home_HomePage__uPApL">
          <div className="home_articleListSection__JvNO_">
            <div className="AppContainer_AppContainer__zMOpX max-width">
              <div className="SectionHeaderV2_SectionHeaderV2__UELE4 home_sectionHeader__suL52">
                <div className="SectionHeaderV2_sectionHeaderSubtitle__eUmZ_">
                  {t("homePage.ecoSystem.featuredIn")}
                </div>
              </div>
              <div className="home_articleListGrid__7oGz6">
                {mediaImages.map((media, index) => (
                  <div
                    key={index}
                    className="ArticleListCard_MediaListCard__T98sa"
                  >
                    <a /*href=""*/ target="_blank" rel="noopener noreferrer">
                      <div className="ArticleListCard_coverImageContainer__MOJ43">
                        <div
                          className="ArticleListCard_coverImage__5_cum"
                          style={{
                            backgroundImage: `url(${media.src})`,
                          }}
                          aria-hidden="true"
                          // aria-label={media.alt}
                        />
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ecosystem;
