import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllRewards } from "../reducers/referralRewardsSlice";
import { setReferralCount } from "../reducers/referralCountSlice";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { debounce } from "../utils/debounce";
import tokensByChainId from "../utils/tokensByChainId";

const useFetchAllReferralRewards = () => {
  const dispatch = useDispatch();
  const { address } = useWeb3ModalAccount();
  const allRewards = useSelector((state) => state.referralRewards.allRewards);

  const fetchPrices = async () => {
    const tokenMap = {
      ETH: "ethereum",
      BNB: "binancecoin",
      MATIC: "polygon",
      AVAX: "avalanche-2",
      ARB: "arbitrum",
      OP: "optimism",
      ZK: "zk-rollups",
    };

    let prices = {};
    let fetchSuccess = false;

    try {
      const response = await fetch(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=ETH,BNB,MATIC,AVAX,ARB,OP,ZK&tsyms=USD"
      );
      if (response.ok) {
        const cryptoComparePrices = await response.json();

        prices = Object.keys(tokenMap).reduce((acc, key) => {
          acc[tokenMap[key]] = { usd: cryptoComparePrices[key].USD };
          return acc;
        }, {});

        fetchSuccess = true;
      } else {
        console.error(
          "CryptoCompare API call failed with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error fetching from CryptoCompare:", error.message);
    }
    // }

    if (!fetchSuccess) {
      throw new Error("Failed to fetch prices from both APIs");
    }

    return prices;
  };

  const fetchReferralRewards = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getReferralRewards`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ walletAddress: address }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const { pending_rewards, active_referrals } = data;
        // console.log("Referral rewards fetched:", pending_rewards);

        const prices = await fetchPrices();

        // if (prices["MATIC"]) {
        //   prices["POL"] = prices["MATIC"];
        //   delete prices["MATIC"];
        // }

        const tokenMap = {
          ETH: "ethereum",
          BNB: "binancecoin",
          POL: "polygon",
          AVAX: "avalanche-2",
          ARB: "arbitrum",
          OP: "optimism",
          ZK: "zk-rollups",
        };

        let formattedRewards = {};
        Object.keys(tokensByChainId).forEach((chainId) => {
          formattedRewards[chainId] = {};
          Object.keys(tokensByChainId[chainId]).forEach((currency) => {
            const token = tokensByChainId[chainId][currency];
            const price = ["USDT", "USDC", "DAI"].includes(currency)
              ? 1
              : prices[tokenMap[currency.toUpperCase()]]?.usd || 0; // Static price of 1 for stablecoins

            const reward = pending_rewards
              .find((reward) => reward.network === chainId)
              ?.currencies.find((curr) => curr.currency === currency);

            if (reward) {
              const usdValue = reward.amount * price;
              formattedRewards[chainId][currency] = {
                amount: reward.amount.toString(), // Store amount as string
                usdValue: usdValue.toString(), // Store USD value as string
              };
            } else {
              formattedRewards[chainId][currency] = {
                amount: "0",
                usdValue: "0",
              };
            }
          });
        });

        dispatch(setAllRewards(formattedRewards));

        const activeReferralCount = active_referrals.length;
        dispatch(setReferralCount(activeReferralCount));
      } else {
        const errorData = await response.json();
        console.error("Error fetching referral rewards:", errorData.error);
      }
    } catch (error) {
      console.error("Error fetching referral rewards:", error.message);
    }
  };

  const debouncedFetchReferralRewards = useCallback(
    debounce(fetchReferralRewards, 300),
    [address, dispatch]
  );

  useEffect(() => {
    if (!address) return;
    debouncedFetchReferralRewards();
  }, [address, debouncedFetchReferralRewards]);

  return { allRewards, fetchReferralRewards };
};

export default useFetchAllReferralRewards;
