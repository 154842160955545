import { createSlice } from "@reduxjs/toolkit";
import blockchainPriceFeeds from "../utils/priceFeedsByChainId";
import {
  specialCurrencyLabels,
  currencyIcons,
  getDefaultCurrencies,
} from "../utils/currenciesUtils";

// Compute the initial currencies
const defaultSymbols = getDefaultCurrencies();
const initialCurrencies = defaultSymbols.map((symbol) => ({
  symbol: symbol,
  image: currencyIcons[symbol],
  label: specialCurrencyLabels[symbol] || symbol,
}));

// Define the initial state, including currencies
const initialState = {
  selectedCurrency: localStorage.getItem("selectedCurrency") || "ETH",
  selectedFiat: localStorage.getItem("selectedFiat") || "USD",
  currencies: initialCurrencies,
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setSelectedCurrency(state, action) {
      state.selectedCurrency = action.payload;
      localStorage.setItem("selectedCurrency", action.payload);
    },
    setSelectedFiat(state, action) {
      state.selectedFiat = action.payload;
      localStorage.setItem("selectedFiat", action.payload);
    },
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
    initializeCurrency(state, action) {
      const storedCurrency = localStorage.getItem("selectedCurrency");
      if (storedCurrency) {
        state.selectedCurrency = storedCurrency;
      } else if (
        action.payload.chainId &&
        blockchainPriceFeeds[action.payload.chainId]
      ) {
        const currencies = Object.keys(
          blockchainPriceFeeds[action.payload.chainId]
        );
        if (currencies.length > 0) {
          state.selectedCurrency = currencies[0];
        }
      }
    },
  },
});

export const {
  setSelectedCurrency,
  setSelectedFiat,
  setCurrencies,
  initializeCurrency,
} = currencySlice.actions;
export default currencySlice.reducer;
