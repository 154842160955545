import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {};

// Slice
const currencyBalancesSlice = createSlice({
  name: "currencyBalances",
  initialState,
  reducers: {
    setCurrencyBalances: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

// Actions
export const { setCurrencyBalances } = currencyBalancesSlice.actions;

// Reducer
export default currencyBalancesSlice.reducer;
