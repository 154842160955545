import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import enTranslation from "./locales/en/translation.json";
import frTranslation from "./locales/fr/translation.json";
import cnTranslation from "./locales/zh/translation.json";
import jpTranslation from "./locales/ja/translation.json";
import ruTranslation from "./locales/ru/translation.json";
import krTranslation from "./locales/ko/translation.json";
import deTranslation from "./locales/de/translation.json";
import ptTranslation from "./locales/pt/translation.json";
import trTranslation from "./locales/tr/translation.json";
import esTranslation from "./locales/es/translation.json";
import hiTranslation from "./locales/hi/translation.json";
import viTranslation from "./locales/vi/translation.json";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    lng: "en",
    resources: {
      en: { translation: enTranslation },
      fr: { translation: frTranslation },
      zh: { translation: cnTranslation },
      ja: { translation: jpTranslation },
      ru: { translation: ruTranslation },
      ko: { translation: krTranslation },
      de: { translation: deTranslation },
      pt: { translation: ptTranslation },
      tr: { translation: trTranslation },
      es: { translation: esTranslation },
      hi: { translation: hiTranslation },
      vi: { translation: viTranslation },
    },

    fallbackLng: "en",
    returnObjects: true,
  });

export default i18n;
