const registryAddressesByChainId = {
  1: "0xYourContractAddressOnEthereum",
  10: "0x39B4D7A8C0A2cddD2e96eCdc68fcf82FDF4094a0", // Example on Optimism
  56: "0x2a42F807EB6396b9bBd9C803bCDb4e531caBB784", // Example on BSC
  137: "0x6BF0064D2e4E84a85f5A023040783A183Ee62f66", // Example for Polygon
  324: "0x761c336B785ab18fBB004fF606a790fFD24cabA2", // Example on ZKsync
  8453: "0x2ECC9F312FAf4a1b87b1FEB07d3c635d5792C69b", // Example on Base
  42161: "0x6f9a4C79Fe41AB9E3251920E0319380B53327bc1", // Example on Arbitrum
  43114: "0x5769528D33b59211abdd5D6Cbbe87c533A30C746", // Example on Avalanche
};

const presaleAddressesByChainId = {
  1: "0xYourContractAddressOnEthereum",
  10: "0x7d6CDEED26d9c688339614738D24544aDB8317CA", // Example on Optimism
  56: "0x0dfB064025d074ed0fE5C15810D96B4bf2f66641", // Example on BSC
  137: "0xFA125Ad17d6E103130EEBA06ACDC33ee1419a3e3", // Example for Polygon
  324: "0x116885d22983593051b912dc6165e3fc42D8E363", // Example for ZKsync
  8453: "0xf416e60436Fb971d1F4051885f8fB5822dA1A495", //Example on Base
  42161: "0xE579cA58bd572c37c5196125a5c2720108E2078c", // Example on Arbitrum
  43114: "0xFFB42586EcEB70d963dAD403E022c51aEcc3E7B7", //Example on Avalanche
};

export { registryAddressesByChainId, presaleAddressesByChainId };
