import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPrice: 0,
  nextStagePrice: 0,
  currentStage: 0,
  totalRaised: 0,
  targetTotalRaised: 0,
  tokensSold: 0,
  targetTokensSold: 0,
  currentRoundSold: 0,
  totalTargetForRoundInDollars: 0,
};

const snovaDataSlice = createSlice({
  name: "snovaData",
  initialState,
  reducers: {
    setSnovaData: (state, action) => {
      state.currentPrice = action.payload.currentPrice;
      state.nextStagePrice = action.payload.nextStagePrice;
      state.currentStage = action.payload.currentStage;
      state.totalRaised = action.payload.totalRaised;
      state.targetTotalRaised = action.payload.targetTotalRaised;
      state.tokensSold = action.payload.tokensSold;
      state.targetTokensSold = action.payload.targetTokensSold;
      state.currentRoundSold = action.payload.currentRoundSold;
      state.totalTargetForRoundInDollars =
        action.payload.totalTargetForRoundInDollars;
    },
  },
});

export const { setSnovaData } = snovaDataSlice.actions;

export default snovaDataSlice.reducer;
