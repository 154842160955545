import React from "react";
import Header from "../layout/Header/Header";

import Footer from "../layout/Footer/Footer";
import Banner from "../components/Home/Banner/Banner.js";
import Ecosystem from "../components/Home/Ecosystem/Ecosystem.js";
import Platforms from "../components/Home/Platforms/Platforms.js";
import Tiers from "../components/Home/Tiers/Tiers.js";
import Tokenomics from "../components/Home/Tokenomics/Tokenomics.js";
import OurThesis from "../components/Home/OurThesis/OurThesis.js";
import Roadmap from "../components/Home/Roadmap/Roadmap.js";
import Eclipse from "../components/Home/Eclipse/Eclipse.js";

import Faq from "../components/Home/Faq/Faq.js";
import LaunchSale from "../components/Home/LaunchSale/LaunchSale.js";
import AuditSection from "../components/Home/AuditSecton/AuditSecton.js";
import WhyBtcoin from "../components/Home/WhyBtcoin/WhyBtcoin.js";
import Top from "../components/top";
import LaunchApp from "../components/Home/LaunchApp/LaunchApp.js";
import NovaBox from "../components/Home/NovaBox/NovaBox.js";
import usePageTitle from "../hooks/usePageTitle";

const Home = () => {
  usePageTitle(
    "Presale | Stacknova AI - The World's First AI-Powered Bitcoin Launchpad"
  );
  return (
    <div>
      <Top />
      <Header />

      <div>
        <div className="top a pt- 5">
          <Banner />
          <Ecosystem />
          <LaunchApp />
          <Eclipse />
          <OurThesis />
          <AuditSection />
          <WhyBtcoin />
          <Platforms />
          <Tiers />
          <Tokenomics />
          <Roadmap />
          <LaunchSale />
          <Faq />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
// import React, { Suspense, lazy } from "react";
// import Header from "../layout/Header/Header";
// import Footer from "../layout/Footer/Footer";
// import Top from "../components/top";
// import Banner from "../components/Home/Banner/Banner.js";
// import usePageTitle from "../hooks/usePageTitle";
// import Loader from "./optimisations/Loader";

// const Ecosystem = lazy(() => import("../components/Home/Ecosystem/Ecosystem.js"));
// const LaunchApp = lazy(() => import("../components/Home/LaunchApp/LaunchApp.js"));
// const Eclipse = lazy(() => import("../components/Home/Eclipse/Eclipse.js"));
// const OurThesis = lazy(() => import("../components/Home/OurThesis/OurThesis.js"));
// const AuditSection = lazy(() => import("../components/Home/AuditSecton/AuditSecton.js"));
// const WhyBtcoin = lazy(() => import("../components/Home/WhyBtcoin/WhyBtcoin.js"));
// const Platforms = lazy(() => import("../components/Home/Platforms/Platforms.js"));
// const Tiers = lazy(() => import("../components/Home/Tiers/Tiers.js"));
// const Tokenomics = lazy(() => import("../components/Home/Tokenomics/Tokenomics.js"));
// const Roadmap = lazy(() => import("../components/Home/Roadmap/Roadmap.js"));
// const LaunchSale = lazy(() => import("../components/Home/LaunchSale/LaunchSale.js"));
// const Faq = lazy(() => import("../components/Home/Faq/Faq.js"));

// const Home = () => {
//   usePageTitle(
//     "Presale | Stacknova AI - The World's First AI-Powered Bitcoin Launchpad"
//   );

//   return (
//     <div>
//       <Top />
//       <Header />

//       <div>
//         <div className="top a pt-5">
//           <Banner />

//           {/* Each lazy-loaded component has its own ErrorBoundary and Suspense */}

//             <Suspense fallback={<Loader />}>
//               <Ecosystem />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <LaunchApp />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <Eclipse />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <OurThesis />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <AuditSection />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <WhyBtcoin />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <Platforms />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <Tiers />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <Tokenomics />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <Roadmap />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <LaunchSale />
//             </Suspense>

//             <Suspense fallback={<Loader />}>
//               <Faq />
//             </Suspense>

//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Home;
