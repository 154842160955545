export const USDIcon = () => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2062_50213)">
      <path
        d="M10.4998 19.9999C16.0227 19.9999 20.4998 15.5228 20.4998 9.99994C20.4998 4.47709 16.0227 -6.10352e-05 10.4998 -6.10352e-05C4.97697 -6.10352e-05 0.499817 4.47709 0.499817 9.99994C0.499817 15.5228 4.97697 19.9999 10.4998 19.9999Z"
        fill="#F0F0F0"
      />
      <path
        d="M10.0652 10.0001H20.5C20.5 9.09749 20.3797 8.22312 20.1555 7.39136H10.0652V10.0001Z"
        fill="#D80027"
      />
      <path
        d="M10.0652 4.78266H19.0323C18.4201 3.78375 17.6374 2.90083 16.7249 2.17395H10.0652V4.78266Z"
        fill="#D80027"
      />
      <path
        d="M10.5 20.0001C12.8534 20.0001 15.0166 19.1866 16.7248 17.8262H4.27512C5.98332 19.1866 8.14648 20.0001 10.5 20.0001Z"
        fill="#D80027"
      />
      <path
        d="M1.96772 15.2174H19.0323C19.5237 14.4155 19.9048 13.5389 20.1555 12.6087H0.844482C1.09515 13.5389 1.47628 14.4155 1.96772 15.2174Z"
        fill="#D80027"
      />
      <path
        d="M5.132 1.56158H6.04329L5.19564 2.1774L5.51943 3.17385L4.67181 2.55802L3.82419 3.17385L4.10388 2.31302C3.35755 2.9347 2.70341 3.66306 2.16435 4.47463H2.45634L1.91677 4.86662C1.83271 5.00685 1.75208 5.14931 1.67482 5.29388L1.93247 6.08689L1.45177 5.73763C1.33228 5.9908 1.22298 6.24967 1.12474 6.51392L1.40861 7.38767H2.45634L1.60868 8.00349L1.93247 8.99994L1.08486 8.38412L0.577122 8.75303C0.526301 9.16154 0.499817 9.57763 0.499817 9.99994H10.4998C10.4998 4.47713 10.4998 3.82603 10.4998 -6.10352e-05C8.52435 -6.10352e-05 6.68282 0.572986 5.132 1.56158ZM5.51943 8.99994L4.67181 8.38412L3.82419 8.99994L4.14798 8.00349L3.30032 7.38767H4.34806L4.67181 6.39123L4.99556 7.38767H6.04329L5.19564 8.00349L5.51943 8.99994ZM5.19564 5.09045L5.51943 6.08689L4.67181 5.47107L3.82419 6.08689L4.14798 5.09045L3.30032 4.47463H4.34806L4.67181 3.47818L4.99556 4.47463H6.04329L5.19564 5.09045ZM9.10638 8.99994L8.25876 8.38412L7.41114 8.99994L7.73493 8.00349L6.88728 7.38767H7.93501L8.25876 6.39123L8.58251 7.38767H9.63025L8.78259 8.00349L9.10638 8.99994ZM8.78259 5.09045L9.10638 6.08689L8.25876 5.47107L7.41114 6.08689L7.73493 5.09045L6.88728 4.47463H7.93501L8.25876 3.47818L8.58251 4.47463H9.63025L8.78259 5.09045ZM8.78259 2.1774L9.10638 3.17385L8.25876 2.55802L7.41114 3.17385L7.73493 2.1774L6.88728 1.56158H7.93501L8.25876 0.565134L8.58251 1.56158H9.63025L8.78259 2.1774Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2062_50213">
        <rect
          width={20}
          height={20}
          fill="white"
          transform="translate(0.5 6.10352e-05)"
        />
      </clipPath>
    </defs>
  </svg>
);
