import Arbi from "../assets/img/arbitrum-symbol.svg";
import Opt from "../assets/img/optimism-symbol.svg";
import ZK from "../assets/img/zkSync-updated-logo.svg";
import Base from "../assets/img/base-icon.svg";

import EthSymbol from "../assets/img/eth-symbol.webp";
import BnbSymbol from "../assets/img/bnb-symbol.webp";
import MaticSymbol from "../assets/img/matic-symbol.webp";
import AvaxSymbol from "../assets/img/avax-symbol.webp";
import UsdtSymbol from "../assets/img/tether-icon.webp";
import UsdcSymbol from "../assets/img/usdc-symbol.webp";
import DaiSymbol from "../assets/img/dai-symbol.webp";

import Eth from "../assets/img/eth-icon.svg";
import Bnb from "../assets/img/bsc-icon.svg";
import Matic from "../assets/img/plg-icon.svg";
import Arb from "../assets/img/arbitrum-icon.svg";
import Avax from "../assets/img/avax-icon.svg";
import Op from "../assets/img/optimism-icon.svg";
import zkSync from "../assets/img/zksync-2.svg";

const currencyMap = {
  ETH: { symbol: EthSymbol, name: "ETH" },
  BNB: { symbol: BnbSymbol, name: "BNB" },
  POL: { symbol: MaticSymbol, name: "POL" },
  AVAX: { symbol: AvaxSymbol, name: "AVAX" },
  ARB: { symbol: Arbi, name: "ARB" },
  OP: { symbol: Opt, name: "OP" },
  ZK: { symbol: ZK, name: "ZK" },
  USDT: { symbol: UsdtSymbol, name: "USDT" },
  USDC: { symbol: UsdcSymbol, name: "USDC" },
  DAI: { symbol: DaiSymbol, name: "DAI" },
};

const networkMap = {
  1: { icon: Eth, name: "Ethereum", mainCurrency: "ETH" },
  56: { icon: Bnb, name: "BNB Smart Chain", mainCurrency: "BNB" },
  137: { icon: Matic, name: "Polygon", mainCurrency: "POL" },
  42161: { icon: Arb, name: "Arbitrum", mainCurrency: "ARB" },
  43114: { icon: Avax, name: "Avalanche", mainCurrency: "AVAX" },
  10: { icon: Op, name: "OP Mainnet", mainCurrency: "OP" },
  8453: { icon: Base, name: "Base", mainCurrency: "ETH" },
  324: { icon: zkSync, name: "zkSync Mainnet", mainCurrency: "ZK" },
};

export { currencyMap, networkMap };
